import { AllowedCollectionType, LogicalOperators } from "@amenda-types";
import { FC, useState } from "react";
import {
  enrichSearchFilters,
  generateCollectionShare,
  transformCollection,
} from "./common";
import { isEmpty, isString, uniq } from "lodash";
import { useFormStore, useUpdateCollection } from "@amenda-domains/mutations";

import { Button } from "@amenda-components/App";
import { CollectionsModalWrapper } from "./CollectionsModalWrapper";
import { sanitizeData } from "@amenda-utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  goBackRoute: string;
  collectionType: AllowedCollectionType;
  handleClose: () => void;
  getResourceIds: (args: any) => Promise<string[]>;
}

export const EditCollectionModal: FC<Props> = ({
  isOpen,
  goBackRoute,
  collectionType,
  handleClose,
  getResourceIds,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { updateCollection } = useUpdateCollection();
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );

  const selectedCollection = selectedCollectionByType[collectionType];

  const getKeywordIds = (keyword: Record<string, any> | string) => {
    return isString(keyword) ? keyword : keyword.value;
  };

  const getResources = async (keywords: any[]) => {
    let args: Record<string, string[]> = {};
    keywords.forEach((keyword) => {
      const { value, componentId } = keyword;

      if (args[componentId]) {
        args[componentId].push(value);
      } else {
        args[componentId] = [value];
      }
    });

    let searchArgs: Record<string, any> = {};
    Object.keys(args).forEach((key) => {
      searchArgs[key] = enrichSearchFilters(
        { operation: LogicalOperators.OR },
        args[key],
      );
    });

    if (!isEmpty(args)) {
      return (await getResourceIds(searchArgs)) ?? [];
    }
    return [];
  };

  const handleSubmit = async (
    {
      name,
      description,
      systemRoles,
      addResourcesFromKeywords,
      users = [],
      matchingUsers = [],
      keywords = [],
      resourceIds = [],
    }: any,
    reset: () => void,
  ) => {
    setUpdateLoading(true);

    let selectedResourceIds: string[] = [];
    if (addResourcesFromKeywords && !isEmpty(keywords)) {
      selectedResourceIds = await getResources(keywords);
    }

    const share = generateCollectionShare({
      users,
      matchingUsers,
      systemRoles,
    });
    let input = {
      name,
      description,
      collectionType,
      share,
      _id: selectedCollection.id,
      resourceIds: uniq([...resourceIds, ...selectedResourceIds]),
      keywords: keywords.map(getKeywordIds),
    };
    input = sanitizeData(input);

    await updateCollection({
      input,
    });
    reset();
    setUpdateLoading(false);
    handleClose();
  };

  const handleDeleteCollection = async () => {
    setDeleteLoading(true);
    await updateCollection({
      input: {
        isDeleted: true,
        _id: selectedCollection.id,
      },
    });
    setDeleteLoading(false);
    navigate(goBackRoute);
  };

  return (
    <CollectionsModalWrapper
      title="Edit collection"
      isOpen={isOpen}
      ownerId={selectedCollection.ownerId}
      values={transformCollection(selectedCollection)}
      collectionType={collectionType}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      footerChildren={(formId) => {
        return (
          <div className="grid w-full grid-cols-2 gap-4">
            <Button
              className="flex w-full justify-center"
              type="button"
              variant="danger"
              loading={deleteLoading}
              onClick={handleDeleteCollection}
            >
              {t("Delete collection")}
            </Button>
            <Button
              form={formId}
              className="flex w-full justify-center"
              type="submit"
              variant="primary"
              loading={updateLoading}
            >
              {t("Save changes")}
            </Button>
          </div>
        );
      }}
    />
  );
};
