import { Control, useWatch } from "react-hook-form";
import { FC, useEffect, useState } from "react";
import {
  ResourceSharingPermissionTypes,
  sharePermissionsOptions,
} from "./common";

import { OperationsMenu } from "@amenda-components/Dashboard";
import { Option } from "@amenda-types";
import { SystemRolesSelect } from "./SystemRolesSelect";
import { isEmpty } from "lodash";
import { useGetSystemRole } from "@amenda-domains/queries";

interface RoleProfileCardProps {
  roleId: string;
  permission?: string;
  handleChangePermission: (option: Option) => void;
}

const RoleProfileCard: FC<RoleProfileCardProps> = ({
  roleId,
  permission,
  handleChangePermission,
}) => {
  const [systemRole, setSystemRole] = useState<any>({});
  const { getSystemRole, loading } = useGetSystemRole();

  useEffect(() => {
    if (roleId) {
      getSystemRole({
        id: roleId,
        callback: setSystemRole,
      });
    }
  }, [getSystemRole, roleId]);

  if (loading || isEmpty(systemRole)) {
    return null;
  }
  return (
    <div className="flex w-full items-center justify-between border border-gray-300 p-2">
      <div className="flex flex-row items-center gap-4">
        <div className="flex flex-col items-start space-y-1">
          <div className="text-sm text-gray-800">{systemRole.name}</div>
          <div className="max-w-64 text-xs text-gray-400">
            {systemRole.description}
          </div>
        </div>
      </div>
      <OperationsMenu
        hasMenuOverflow={true}
        menuItemsClassName="z-[80]"
        labelClassName="text-xs text-gray-500"
        options={sharePermissionsOptions}
        selectedOption={permission}
        onChange={handleChangePermission}
      />
    </div>
  );
};

interface Props {
  id: string;
  control: Control<any>;
}

export const CollectionRoles: FC<Props> = ({ id, control }) => {
  const isShared = useWatch({
    control,
    exact: true,
    name: "shareable",
  });

  if (!isShared) {
    return null;
  }
  return (
    <div className="w-100 mt-2 block">
      <SystemRolesSelect
        id={id}
        control={control}
        isMulti={true}
        label="Share with Roles"
        controlShouldRenderValue={false}
        transformValues={(values: any[]) =>
          values.map((value) => {
            return {
              id: value.id,
              role: value.role ?? ResourceSharingPermissionTypes.View,
            };
          })
        }
      >
        {({ fields, update }) => (
          <>
            {!isEmpty(fields) && (
              <ul className="flex flex-col space-y-2">
                {fields.map((field, i) => {
                  return (
                    <li key={field.id}>
                      <RoleProfileCard
                        roleId={field.id}
                        permission={field.role}
                        handleChangePermission={(o) => {
                          update(i, {
                            id: field.id,
                            role: o.value,
                          });
                        }}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        )}
      </SystemRolesSelect>
    </div>
  );
};
