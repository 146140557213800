import { FC, memo } from "react";
import {
  FormAutoSaveSubmitProps,
  PerfFormAutoSaveWrapper,
} from "@amenda-components/PageBuilder";
import { FormComponentProps, PageComponentProps } from "@amenda-types";
import {
  GeneralPermissionKeys,
  PermissionComponents,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { transformFormToProject, transformProjectToForm } from "./common";
import {
  useCreateProject,
  useProjectStore,
  useSettingsStore,
  useUpdateProject,
} from "@amenda-domains/mutations";

import { PermissionComponentKey } from "@amenda-constants";
import { getValidationWithPermissions } from "@amenda-utils";
import isEmpty from "lodash/isEmpty";
import { processFormPermissions } from "@amenda-components/Shared/common";

interface Props {
  customFormComponents?: Record<string, FC<FormComponentProps>>;
  components: PageComponentProps[];
}

export const ProjectForm: FC<Props> = memo(
  ({ components, customFormComponents = {} }) => {
    const { createProject } = useCreateProject();
    const { updateProject } = useUpdateProject();
    const selectedProject = useProjectStore((state) => state.selectedProject);
    const permissions = useSettingsStore(
      (state) => state.currentUserSystemRole?.permissions || {},
    );
    const setIsSavingProject = useProjectStore(
      (state) => state.setIsSavingProject,
    );

    const values = transformProjectToForm(selectedProject);
    const validationSchema = getValidationWithPermissions(
      components,
      processFormPermissions(permissions),
    );
    const projectsGeneralPermissions = getFromGeneralPermissions(
      permissions,
      GeneralPermissionKeys.Projects,
    );
    const defaultShareType =
      projectsGeneralPermissions?.[PermissionComponentKey]?.[
        PermissionComponents.GeneralAccess
      ];

    const onSubmit = async ({
      dirtyData,
      resourceId,
    }: FormAutoSaveSubmitProps) => {
      const handleSave = resourceId ? updateProject : createProject;
      const input = transformFormToProject({
        resourceId,
        defaultShareType,
        form: dirtyData,
      });

      if (isEmpty(dirtyData)) {
        return;
      }

      setIsSavingProject(true);
      await handleSave({
        input,
      });
      setIsSavingProject(false);
    };

    return (
      <PerfFormAutoSaveWrapper
        key={selectedProject?.id ?? "new-project"}
        values={values}
        components={components}
        resourceId={selectedProject?.id}
        inputSchema={validationSchema}
        customFormComponents={customFormComponents}
        processComponents={processFormPermissions(permissions)}
        onSubmit={onSubmit}
      />
    );
  },
);
