import { AllowedCollectionType, Option } from "@amenda-types";
import { Control, useController } from "react-hook-form";
import { FC, useEffect, useState } from "react";
import { MiniSwitch, ReactSelect } from "@amenda-components/FormComponents";
import { isEmpty, isString } from "lodash";

import { useGetKeywords } from "@amenda-domains/queries";

interface Props {
  id: string;
  error?: string;
  placeholder?: string;
  values: Option[];
  disabled?: boolean;
  hasMenuOverflow?: boolean;
  collectionType: AllowedCollectionType;
  componentIds?: string[];
  control: Control<any>;
  onChange: (values: Option[]) => void;
}

const MiniSwitchWrapper: FC<Pick<Props, "control">> = ({ control }) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name: "addResourcesFromKeywords",
  });
  return (
    <MiniSwitch label="Auto create tags" value={value} onChange={onChange} />
  );
};

export const CollectionTags: FC<Props> = ({
  values,
  collectionType,
  control,
  componentIds = [],
  onChange,
  ...rest
}) => {
  const [keywords, setKeywords] = useState<any[]>([]);
  const { getKeywords, loading } = useGetKeywords();

  const options = keywords.map((k) => ({
    value: k.id,
    label: k.name,
    componentId: k.componentId,
  }));
  const selectedValues = values?.map((value) => {
    if (isString(value)) {
      return options.find((o) => o.value === value);
    }
    return value;
  }) as Option[];

  const handleChange = (option: Option[]) => {
    onChange(option);
  };

  useEffect(() => {
    if (!isEmpty(componentIds)) {
      getKeywords({
        componentIds,
        setKeywords,
      });
    }
  }, [getKeywords, componentIds]);

  if (isEmpty(componentIds)) {
    return null;
  }
  return (
    <ReactSelect
      isMulti
      placeholder="Add a tag"
      label="Auto create from tags"
      optional={<MiniSwitchWrapper control={control} />}
      isLoading={loading}
      options={options}
      hasMenuOverflow={true}
      value={selectedValues}
      onChange={handleChange}
      {...rest}
    />
  );
};
