import { AvailableForms, FormComponentTypes } from "@amenda-constants";
import {
  Button,
  HelperMessage,
  IconButtonBase,
  LoaderWrapper,
} from "@amenda-components/App";
import { FC, useEffect, useRef, useState } from "react";
import {
  FormAutoSaveSubmitProps,
  FormWrapper,
} from "@amenda-components/PageBuilder";
import {
  getComponentsFromForms,
  getComponentsWithCommonValues,
  getComponentsWithProperty,
  getValidationWithPermissions,
  groupComponentsByParent,
  runAllAsync,
  transformCommonComponentValues,
} from "@amenda-utils";
import {
  transformAttachmentToForm,
  transformBulkEditorAttachmentsForm,
  transformFormToAttachmentBase,
} from "./common";
import {
  useAttachmentStore,
  useProjectStore,
  useSettingsStore,
  useUpdateMultipleAttachments,
} from "@amenda-domains/mutations";

import { BulkEditorComponentSearch } from "./BulkEditorComponentSearch";
import { XIcon } from "lucide-react";
import { isEmpty } from "lodash";
import { processFormPermissions } from "@amenda-components/Shared/common";
import { useGetCommonObjectsValues } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

export const BulkEditorAttachmentsForm: FC = () => {
  const { t } = useTranslation();
  const [saveId, setSaveId] = useState<"complete" | "individualProcessing">(
    "complete",
  );
  const hasRunOnce = useRef(false);
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const forms = useProjectStore((state) => state.forms);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const attachmentsForm = useAttachmentStore(
    (state) => state.bulkEditorState.attachmentsForm,
  );
  const setBulkEditorState = useAttachmentStore(
    (state) => state.setBulkEditorState,
  );
  const [availableComponents, setAvailableComponents] = useState<any[]>([]);
  const toggleBulkEditorModal = useAttachmentStore(
    (state) => state.toggleBulkEditorModal,
  );
  const setBulkEditorAttachmentsForm = useAttachmentStore(
    (state) => state.setBulkEditorAttachmentsForm,
  );
  const cleanUpBulkEditorState = useAttachmentStore(
    (state) => state.cleanUpBulkEditorState,
  );
  const attachments = useAttachmentStore(
    (state) => state.bulkEditorState.attachments,
  );
  const { updateMultipleAttachments, loading } = useUpdateMultipleAttachments();
  const setBulkEditorAttachmentView = useAttachmentStore(
    (state) => state.setBulkEditorAttachmentView,
  );
  const { getCommonObjectsValues, loading: isFetchingSharedValues } =
    useGetCommonObjectsValues();

  const availableForms = forms?.[AvailableForms.Gallery];
  const components = getComponentsFromForms(availableForms);
  const formComponentTree = groupComponentsByParent(
    availableComponents,
    processFormPermissions(permissions),
  );
  const inputSchema = getValidationWithPermissions(
    availableComponents,
    processFormPermissions(permissions),
  );
  const searchComponents = components.filter(
    (c) =>
      Boolean(c?.component) &&
      ![FormComponentTypes.Save, FormComponentTypes.Hidden].includes(
        c.component,
      ),
  );
  const values = transformAttachmentToForm(attachmentsForm);

  const handleClose = () => {
    toggleBulkEditorModal(false);
  };

  const toggleAvailableComponents = (component: any) => {
    setAvailableComponents((prev) => {
      const hasComponent = prev.some((c) => c.id === component.id);
      if (hasComponent) {
        return prev.filter((c) => c.id !== component.id);
      }
      return [...prev, component];
    });
  };

  const onSubmit = async ({ dirtyData }: FormAutoSaveSubmitProps) => {
    const attachmentIds = attachments.map((a) => a?.id);

    if (!isEmpty(attachmentIds) && !isEmpty(dirtyData)) {
      const { $pull, ...rest } = transformBulkEditorAttachmentsForm({
        components,
        values,
        data: dirtyData,
      });

      let data = [rest];
      if (!!$pull) {
        data = [{ $pull }, rest];
      }
      data = data.map((d) => {
        return {
          ...transformFormToAttachmentBase(d),
          _ids: attachmentIds,
        };
      });

      await runAllAsync(data, (input) => updateMultipleAttachments({ input }));
    }
    setBulkEditorAttachmentView(true);
    cleanUpBulkEditorState();
  };

  const handleComplete = () => {
    setSaveId("complete");
    setBulkEditorState("cleanUpAfterSave", true);
    submitBtnRef?.current?.click();
  };

  const handleIndividualProcessing = () => {
    setSaveId("individualProcessing");
    submitBtnRef?.current?.click();
  };

  useEffect(() => {
    const initializeOnMount = async () => {
      if (!hasRunOnce.current && components && components.length > 0) {
        const commonValueComponents = getComponentsWithCommonValues(components);
        const availableComponents = getComponentsWithProperty(
          "isBulkEditable",
          components,
        );

        if (commonValueComponents.length > 0) {
          const values = await getCommonObjectsValues({
            collection: "attachments",
            ids: attachments.map((a) => a.id),
            componentIds: commonValueComponents.map((c) => c.id),
            context: {
              requestPolicy: "network-only",
            },
          });
          setBulkEditorAttachmentsForm(
            transformCommonComponentValues(commonValueComponents, values),
          );
        }
        setAvailableComponents(availableComponents);
        hasRunOnce.current = true;
      }
    };

    initializeOnMount();
  }, [
    components,
    attachments,
    getCommonObjectsValues,
    setBulkEditorAttachmentsForm,
  ]);

  return (
    <div className="relative h-full w-full">
      <div className="flex h-[calc(100%-4rem)] w-full flex-col space-y-1 overflow-y-auto overscroll-y-contain px-4 pt-4">
        <div className="flex w-full justify-end">
          <IconButtonBase variant="base">
            <XIcon className="h-6 w-6" onClick={handleClose} />
          </IconButtonBase>
        </div>
        {isFetchingSharedValues ? (
          <LoaderWrapper />
        ) : (
          <>
            <FormWrapper
              className="pb-1"
              inputSchema={inputSchema}
              formComponentTree={formComponentTree}
              values={values}
              onSubmit={onSubmit}
            >
              {() => {
                return (
                  <button ref={submitBtnRef} type="submit" className="hidden" />
                );
              }}
            </FormWrapper>
            {availableComponents.length <= 1 ? (
              <HelperMessage message="Please choose a field for bulk editing">
                <div className="w-full pt-4">
                  <BulkEditorComponentSearch
                    components={searchComponents}
                    selectedComponentsIds={availableComponents.map((c) => c.id)}
                    toggleComponents={toggleAvailableComponents}
                  />
                </div>
              </HelperMessage>
            ) : (
              <div className="flex w-full justify-center pt-4">
                <BulkEditorComponentSearch
                  components={searchComponents}
                  selectedComponentsIds={availableComponents.map((c) => c.id)}
                  toggleComponents={toggleAvailableComponents}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className="absolute bottom-0 z-10 flex w-full justify-end space-x-1 border-t border-gray-200 bg-white px-2 py-2">
        <Button
          size="lg"
          loading={saveId === "individualProcessing" && loading}
          onClick={handleIndividualProcessing}
        >
          {t("Individual processing")}
        </Button>
        <Button
          size="lg"
          variant="primary"
          loading={saveId === "complete" && loading}
          onClick={handleComplete}
        >
          {t("Complete")}
        </Button>
      </div>
    </div>
  );
};
