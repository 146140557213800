import { HelperMessage, LoaderWrapper } from "@amenda-components/App";
import React, { Fragment, useEffect, useState } from "react";
import {
  useAppStore,
  useUpdateTimelineActivity,
} from "@amenda-domains/mutations";

import { AllowedTimelineTypes } from "@amenda-types";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import { DeleteModal } from "@amenda-components/Shared";
import { EditTimelineTextArea } from "./EditTimelineTextArea";
import { EmptyTimelineTextArea } from "./EmptyTimelineTextArea";
import { PaginationLimit } from "@amenda-constants";
import { PrimaryTimelineTextArea } from "./PrimaryTimelineTextArea";
import { TimelineItem } from "./TimelineItem";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { useGetAllTimelineActivities } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

interface Props {
  resourceId?: string;
  className?: string;
  textAreaClassName?: string;
  type: AllowedTimelineTypes;
}

export const Timeline: React.FC<Props> = ({
  type,
  resourceId,
  textAreaClassName,
  className = "xl:w-1/2 lg:w-2/3 w-full",
}) => {
  const { t } = useTranslation();
  const timelineActivities = useAppStore((state) => state.timelineActivities);
  const { getAllTimelineActivities, loading } = useGetAllTimelineActivities();
  const [openDeleteActivityTimelineModal, setOpenDeleteActivityTimelineModal] =
    useState(false);
  const { updateTimelineActivity, loading: updateTimelineLoader } =
    useUpdateTimelineActivity();
  const selectedActivityTimeline = useAppStore(
    (state) => state.selectedActivityTimeline,
  );
  const clearSelectedActivityTimeline = useAppStore(
    (state) => state.clearSelectedActivityTimeline,
  );
  const [isEditing, setIsEditing] = useState(false);

  const handleOpenDeleteActivityTimelineModal = () =>
    setOpenDeleteActivityTimelineModal(true);
  const handleCloseDeleteActivityTimelineModal = () =>
    setOpenDeleteActivityTimelineModal(false);

  const handleDeleteTimeline = async () => {
    await updateTimelineActivity({
      input: {
        _id: selectedActivityTimeline.id,
        isDeleted: true,
      },
    });
    handleCloseDeleteActivityTimelineModal();
  };

  const handleCloseEdit = () => {
    setIsEditing(false);
    clearSelectedActivityTimeline();
  };

  useEffect(() => {
    if (resourceId && type) {
      getAllTimelineActivities({
        type,
        resourceId,
        isDeleted: false,
        limit: PaginationLimit.timeline,
        sort: { date: -1 },
        selectTimelineActivityFields: {
          _id: 1,
          title: 1,
          date: 1,
          createdById: 1,
          body: 1,
          activity: 1,
          resourceId: 1,
        },
      });
    }
  }, [type, resourceId, getAllTimelineActivities]);

  if (loading) {
    return <LoaderWrapper spinnerSize="sm" className="h-48" />;
  } else if (isEmpty(timelineActivities) || isEmpty(resourceId)) {
    return (
      <div className="flex w-full flex-col">
        <HelperMessage
          className="h-24 w-full"
          Icon={ChatBubbleOvalLeftEllipsisIcon}
          message="No comments or other activity yet"
        />
        {resourceId && (
          <EmptyTimelineTextArea
            type={type}
            resourceId={resourceId}
            textAreaClassName={textAreaClassName}
          />
        )}
      </div>
    );
  }
  return (
    <div className="py-5">
      <DeleteModal
        title="Delete Activity"
        description="Are you sure you want to delete this activity?"
        loading={updateTimelineLoader}
        openModal={openDeleteActivityTimelineModal}
        handleDelete={handleDeleteTimeline}
        handleCloseModal={handleCloseDeleteActivityTimelineModal}
      />
      <div className={clsx("space-y-4", className)}>
        <h3 className="amenda-form-heading">{t("Timeline")}</h3>
        {resourceId && (
          <PrimaryTimelineTextArea
            type={type}
            resourceId={resourceId}
            textAreaClassName={textAreaClassName}
          />
        )}
        {timelineActivities.map((timelineActivity, index) => (
          <Fragment key={index}>
            {timelineActivity.id === selectedActivityTimeline?.id &&
            isEditing &&
            resourceId ? (
              <EditTimelineTextArea
                type={type}
                resourceId={resourceId}
                textAreaClassName={textAreaClassName}
                isLastActivity={index + 1 === timelineActivities.length}
                handleCloseEdit={handleCloseEdit}
              />
            ) : (
              <TimelineItem
                timelineActivity={timelineActivity}
                isLastActivity={index + 1 === timelineActivities.length}
                setIsEditing={setIsEditing}
                handleOpenDeleteModal={handleOpenDeleteActivityTimelineModal}
              />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
