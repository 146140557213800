import {
  AvailableImageVariants,
  AvailableNotificationTypes,
  MediaDropzoneUploadStatus,
  Pagination,
} from "@amenda-types";
import { createJSONStorage, persist } from "zustand/middleware";
import { gql, useMutation } from "urql";

import { ATTACHMENT_FRAGMENT } from "@amenda-domains/fragments/attachments";
import { GalleryView } from "@amenda-types";
import { LayoutTypes } from "@amenda-components/Shared/common";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import uniq from "lodash/uniq";
import { useAppStore } from "@amenda-domains/mutations/app";

type MediaBulkEditorState = {
  isModalOpen: boolean;
  isEditing: boolean;
  activeAttachmentIndex: number;
  activeAttachment: any;
  attachments: any[];
  attachmentsForm: any;
  isAttachmentView: boolean;
  cleanUpAfterSave: boolean;
};

type MediaDropzoneFile = {
  id: string;
  progress: number;
  remove?: boolean;
  update?: boolean;
};

type AttachmentProject = {
  projectId?: string;
  goBackRoute?: string;
};

type State = {
  layoutView: LayoutTypes;
  shiftSelectedAttachments: number[];
  avatarFile?: File;
  pagination: Pagination;
  modals: Record<string, boolean>;
  attachments: any[];
  selectedGalleryView: GalleryView;
  selectedImageVariant: AvailableImageVariants;
  isSavingAttachment: boolean;
  isSearching: boolean;
  isFetching: boolean;
  selectedAttachment: Record<string, any>;
  selectedAttachments: string[];
  filesToUpload: any[];
  selectedImageFile?: any;
  mediaDropZoneFiles: {
    status: MediaDropzoneUploadStatus;
    files: MediaDropzoneFile[];
  };
  bulkEditorState: MediaBulkEditorState;
  attachmentProject: AttachmentProject;
};

type Actions = {
  setAttachmentProject: (attachmentProject: AttachmentProject) => void;
  clearAttachments: () => void;
  nextBulkEditorActiveAttachment: () => void;
  setShiftSelectedAttachment: (attachment: any) => void;
  setBulkEditorAttachmentsForm: (attachmentsForm: any) => void;
  updateMediaDropzoneFiles: (args: {
    files?: MediaDropzoneFile[];
    file?: MediaDropzoneFile;
    status?: MediaDropzoneUploadStatus;
  }) => void;
  setSelectedImageFile: (file: any) => void;
  setAvatarFile: (avatarFile: File) => void;
  setFilesToUpload: (files?: any[]) => void;
  setAttachments: (props: Pagination & { attachments: any[] }) => void;
  updateOrRemoveAttachment: (attachment: any) => void;
  updateAttachments: (attachment: any) => void;
  setIsSavingAttachment: (isSavingAttachment: boolean) => void;
  setSelectedAttachment: (selectedAttachment: Record<string, any>) => void;
  setSelectedImageVariant: (
    selectedImageVariant: AvailableImageVariants,
  ) => void;
  setSelectedAttachments: (attachmentIds: string[]) => void;
  toggleSelectedAttachment: (attachmentId: string) => void;
  clearSelectedAttachments: () => void;
  setSelectAllAttachments: () => void;
  setIsSearching: (isSearching: boolean) => void;
  setIsFetching: (isFetching: boolean) => void;
  setSelectedGalleryView: (selectedGalleryView: GalleryView) => void;
  toggleMoveAttachmentsModal: (openMoveAttachmentsModal: boolean) => void;
  removeAttachmentByIds: (attachmentIds: string[]) => void;
  toggleBulkEditorModal: (
    isOpen: boolean,
    withSelectedAttachments?: boolean,
  ) => void;
  setBulkEditorState: (key: keyof MediaBulkEditorState, value: any) => void;
  addAttachmentToBulkEditor: (
    attachment: any,
    attachmentAddedFromModal?: boolean,
  ) => void;
  updateSelectedAttachmentImages: (attachment: any) => void;
  cleanUpBulkEditorState: () => void;
  updateBulkEditorAttachment: (attachment: any) => void;
  setBulkEditorActiveAttachment: (attachment: any) => void;
  setBulkEditorAttachmentView: (isAttachmentView: boolean) => void;
  setOpenMediaSlider: (openMediaSlider: boolean) => void;
  setOpenDeleteModal: (isModalOpen: boolean) => void;
  setLayoutView: (view: LayoutTypes) => void;
};

const persistedStateVersion = 0.2;

const defaultBulkEditorState: MediaBulkEditorState = {
  isModalOpen: false,
  isEditing: false,
  isAttachmentView: false,
  cleanUpAfterSave: false,
  activeAttachmentIndex: 0,
  attachments: [],
  attachmentsForm: {},
  activeAttachment: {},
};

const handleShiftSelection = ({
  attachment,
  attachments,
  selectedAttachments,
  shiftSelectedAttachments,
}: {
  attachment: any;
  attachments: any[];
  shiftSelectedAttachments: number[];
  selectedAttachments: any[];
}) => {
  const index = attachments.findIndex((a) => a.id === attachment.id);
  const attachmentsIndex = [...shiftSelectedAttachments, index];
  if (attachmentsIndex.length > 1) {
    const start = Math.min(...attachmentsIndex);
    const end = Math.max(...attachmentsIndex);

    let allSelectedAttachments = attachments.slice(start, end).map((a) => a.id);
    allSelectedAttachments = uniq([
      ...allSelectedAttachments,
      ...selectedAttachments,
      attachment.id,
    ]);

    return {
      allSelectedAttachments,
      allShiftSelectedAttachments: [],
    };
  }
  return {
    allShiftSelectedAttachments: [index],
    allSelectedAttachments: [attachment.id],
  };
};

export const useAttachmentStore = create(
  persist(
    immer<State & Actions>((set, get) => ({
      pagination: {},
      attachments: [],
      filesToUpload: [],
      layoutView: "grid" as LayoutTypes.Grid,
      isSavingAttachment: false,
      isSearching: false,
      isFetching: false,
      selectedAttachment: {},
      selectedAttachments: [],
      selectedGalleryView: GalleryView.Grid,
      selectedImageVariant: AvailableImageVariants.contain,
      modals: {
        openDeleteModal: false,
        openMediaSlider: false,
        openUploadAndEditModal: false,
        openMoveAttachmentsModal: false,
        openCreateConstructionDetailModal: false,
        openEditConstructionDetailModal: false,
      },
      mediaDropZoneFiles: {
        status: MediaDropzoneUploadStatus.Success,
        files: [],
      },
      shiftSelectedAttachments: [],
      bulkEditorState: defaultBulkEditorState,
      searchTerm: "",
      attachmentProject: {},
      setAttachmentProject: (attachmentProject) => {
        set((state) => {
          state.attachmentProject = attachmentProject;
        });
      },
      clearAttachments: () => {
        set((state) => {
          state.attachments = [];
        });
      },
      setLayoutView: (view) => {
        set((state) => {
          state.layoutView = view;
        });
      },
      setOpenDeleteModal: (isModalOpen) => {
        set((state) => {
          state.modals.openDeleteModal = isModalOpen;
        });
      },
      setOpenMediaSlider: (openMediaSlider) =>
        set((state) => {
          state.modals.openMediaSlider = openMediaSlider;
        }),
      setBulkEditorActiveAttachment: (attachment) =>
        set((state) => {
          state.bulkEditorState.activeAttachment = attachment;
        }),
      setShiftSelectedAttachment: (attachment) =>
        set((state) => {
          const { attachments, selectedAttachments, shiftSelectedAttachments } =
            get();

          const { allSelectedAttachments, allShiftSelectedAttachments } =
            handleShiftSelection({
              attachment,
              attachments,
              selectedAttachments,
              shiftSelectedAttachments,
            });

          state.shiftSelectedAttachments = allShiftSelectedAttachments;
          state.selectedAttachments = allSelectedAttachments;
        }),
      setSelectedAttachments: (attachmentIds) =>
        set((state) => {
          state.selectedAttachments = attachmentIds;
        }),
      nextBulkEditorActiveAttachment: () =>
        set((state) => {
          const { attachments, activeAttachmentIndex } = get().bulkEditorState;
          const lastIndex = attachments.length - 1;

          state.bulkEditorState.activeAttachmentIndex = Math.min(
            lastIndex,
            activeAttachmentIndex + 1,
          );
          state.bulkEditorState.cleanUpAfterSave =
            activeAttachmentIndex + 1 > lastIndex;
        }),
      setBulkEditorAttachmentView: (isAttachmentView) =>
        set((state) => {
          const cleanUpAfterSave = get().bulkEditorState.cleanUpAfterSave;
          if (isAttachmentView && !cleanUpAfterSave) {
            state.bulkEditorState.isAttachmentView = true;
          } else {
            state.bulkEditorState.isAttachmentView = false;
          }
        }),
      setBulkEditorAttachmentsForm: (attachmentsForm) =>
        set((state) => {
          state.bulkEditorState.attachmentsForm = {
            ...get().bulkEditorState.attachmentsForm,
            ...attachmentsForm,
          };
        }),
      cleanUpBulkEditorState: () =>
        set((state) => {
          const cleanUpAfterSave = get().bulkEditorState.cleanUpAfterSave;

          if (cleanUpAfterSave) {
            state.bulkEditorState = defaultBulkEditorState;
            state.selectedAttachments = [];
          }
        }),
      updateMediaDropzoneFiles: ({ file, files, status }) =>
        set((state) => {
          if (files) {
            state.mediaDropZoneFiles.files = files;
          }
          if (file) {
            const files = get().mediaDropZoneFiles.files;
            state.mediaDropZoneFiles.files = Boolean(file?.remove)
              ? files.filter((f) => f.id !== file.id)
              : Boolean(file?.update)
                ? files.map((f) => (f.id === file.id ? file : f))
                : [...files, file];
          }
          if (status) {
            state.mediaDropZoneFiles.status = status;
            if (status === MediaDropzoneUploadStatus.Success) {
              state.filesToUpload = [];
              state.mediaDropZoneFiles.files = [];
            }
          }
        }),
      updateOrRemoveAttachment: (attachment) =>
        set((state) => {
          state.attachments = Boolean(attachment?.isDeleted)
            ? get().attachments.filter((a) => a?.id !== attachment?.id)
            : get().attachments.map((a) =>
                a?.id === attachment?.id ? attachment : a,
              );
        }),
      updateSelectedAttachmentImages: (attachment) =>
        set((state) => {
          const selectedAttachment = get().selectedAttachment;

          if (
            !Boolean(attachment?.isDeleted) &&
            selectedAttachment.id === attachment?.id
          ) {
            state.selectedAttachment.url = attachment.url;
            state.selectedAttachment.extractedImages =
              attachment.extractedImages;
          }
        }),
      updateAttachments: (attachment) =>
        set((state) => {
          const attachments = get().attachments;

          state.attachments = Boolean(attachment?.isDeleted)
            ? attachments.filter((a) => a.id !== attachment.id)
            : attachments.map((a) => (a.id === attachment.id ? attachment : a));
        }),
      removeAttachmentByIds: (attachmentIds) =>
        set((state) => {
          state.attachments = state.attachments.filter(
            (a) => !attachmentIds.includes(a.id),
          );
        }),
      setSelectedImageFile: (file) =>
        set((state) => {
          state.selectedImageFile = file;
        }),
      setAvatarFile: (file) =>
        set((state) => {
          state.avatarFile = file;
        }),
      toggleBulkEditorModal: (isOpen, withSelectedAttachments) =>
        set((state) => {
          state.bulkEditorState.isModalOpen = isOpen;

          if (isOpen && withSelectedAttachments) {
            const attachments = get().attachments;
            const selectedAttachments = get().selectedAttachments;
            const bulkEditAttachments = attachments.filter((a) =>
              selectedAttachments.includes(a.id),
            );

            state.bulkEditorState.isEditing = true;
            state.bulkEditorState.attachments = bulkEditAttachments;
          }
          if (!isOpen) {
            state.bulkEditorState = defaultBulkEditorState;
          }
        }),
      addAttachmentToBulkEditor: (
        attachment,
        attachmentAddedFromModal = true,
      ) =>
        set((state) => {
          const attachments = get().bulkEditorState.attachments;
          const attachmentAddedFromDropzone =
            !attachmentAddedFromModal && get().bulkEditorState.isModalOpen;
          if (attachmentAddedFromModal || attachmentAddedFromDropzone) {
            state.bulkEditorState.attachments = [attachment, ...attachments];
          }
        }),
      setBulkEditorState: (key, value) =>
        set((state) => {
          state.bulkEditorState = {
            ...get().bulkEditorState,
            [key]: value,
          };
        }),
      toggleMoveAttachmentsModal: (openMoveAttachmentsModal) =>
        set((state) => {
          state.modals.openMoveAttachmentsModal = openMoveAttachmentsModal;
        }),
      setFilesToUpload: (files = []) =>
        set((state) => {
          state.filesToUpload = files;
        }),
      setAttachments: ({ attachments, ...rest }) =>
        set((state) => {
          state.attachments = attachments;
          state.pagination = rest;
        }),
      setSelectedImageVariant: (selectedImageVariant) =>
        set((state) => {
          state.selectedImageVariant = selectedImageVariant;
        }),
      setSelectedAttachment: (selectedAttachment) =>
        set((state) => {
          state.selectedAttachment = selectedAttachment;
        }),
      setIsSavingAttachment: (isSavingAttachment) =>
        set((state) => {
          state.isSavingAttachment = isSavingAttachment;
        }),
      toggleSelectedAttachment: (attachmentId) =>
        set((state) => {
          const isSelected = state.selectedAttachments.includes(attachmentId);
          if (isSelected) {
            state.selectedAttachments = state.selectedAttachments.filter(
              (id) => id !== attachmentId,
            );
          } else {
            state.selectedAttachments = [
              ...state.selectedAttachments,
              attachmentId,
            ];
          }
        }),
      setSelectAllAttachments: () =>
        set((state) => {
          state.selectedAttachments = state.attachments.map(
            (attachment) => attachment.id,
          );
        }),
      clearSelectedAttachments: () =>
        set((state) => {
          state.selectedAttachments = [];
        }),
      setSelectedGalleryView: (selectedGalleryView) =>
        set((state) => {
          state.selectedGalleryView = selectedGalleryView;
        }),
      setIsSearching: (isSearching) =>
        set((state) => {
          state.isSearching = isSearching;
        }),
      setIsFetching: (isFetching) =>
        set((state) => {
          state.isFetching = isFetching;
        }),
      updateBulkEditorAttachment: (attachment) =>
        set((state) => {
          const attachments = get().bulkEditorState.attachments;
          state.bulkEditorState.attachments = attachments.map((a) =>
            a.id === attachment.id ? attachment : a,
          );
        }),
    })),
    {
      name: "attachments-storage",
      version: persistedStateVersion,
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({
        selectedGalleryView: state.selectedGalleryView,
      }),
    },
  ),
);

const SINGLE_FILE_UPLOAD = gql`
  mutation SingleFileUpload($category: AllowedCategory!, $file: Upload!) {
    file: singleFileUpload(category: $category, file: $file) {
      bucketname
      filename
      mimetype
      metadata {
        dimensions
        type
        size
      }
    }
  }
`;

const ADD_ATTACHMENTS_TO_RESOURCE = gql`
  mutation AddAttachmentsToResource($input: AttachmentAndResourceInput!) {
    resource: addAttachmentsToResource(input: $input)
  }
`;

const SET_PROJECT_GALLERY_IMAGE = gql`
  mutation SetProjectGalleryImage($input: ProjectGalleryImageInput!) {
    galleryImageAdded: setProjectGalleryImage(input: $input)
  }
`;

const GENERATE_ATTACHMENT_URL = gql`
  mutation GenerateAttachmentURL($input: AttachmentUrlInput!) {
    attachment: generateAttachmentURL(input: $input) {
      url
    }
  }
`;

const DELETE_ATTACHMENTS = gql`
  mutation DeleteAttachments($attachmentIds: [String!]!) {
    deleteAttachments(attachmentIds: $attachmentIds)
  }
`;

export const useAddAttachmentToResource = (onComplete?: () => void) => {
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callAddAttachmentToResource] = useMutation(
    ADD_ATTACHMENTS_TO_RESOURCE,
  );

  const addAttachmentToResource = (variables: Record<string, any>) => {
    return callAddAttachmentToResource(variables).then(({ data }) => {
      showNotification(
        AvailableNotificationTypes.Success,
        "Attachments moved successfully",
      );
      onComplete?.();
    });
  };
  return {
    addAttachmentToResource,
    loading: result.fetching,
  };
};

export const useSetProjectGalleryImage = () => {
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callSetProjectGallery] = useMutation(
    SET_PROJECT_GALLERY_IMAGE,
  );

  const setProjectGallery = (variables: Record<string, any>) => {
    return callSetProjectGallery(variables).then(({ data }) => {
      if (data.galleryImageAdded) {
        showNotification(
          AvailableNotificationTypes.Success,
          "Gallery image updated",
        );
      }
      return data;
    });
  };

  return {
    setProjectGallery,
    projectGalleryLoader: result.fetching,
  };
};

export const useGenerateAttachmentUrl = () => {
  const [result, callGenerateAttachmentUrl] = useMutation(
    GENERATE_ATTACHMENT_URL,
  );
  const showNotification = useAppStore((state) => state.showNotification);

  const generateAttachmentUrl = async ({
    notificationMessage,
    callback,
    ...variables
  }: {
    input: any;
    notificationMessage?: string;
    callback?: (thumbnailUrl: string) => void;
  }) => {
    if (notificationMessage) {
      showNotification(AvailableNotificationTypes.General, notificationMessage);
    }
    return callGenerateAttachmentUrl(variables).then(({ data }) => {
      if (callback && data?.attachment?.url) {
        callback(data.attachment.url);
      }
      return data?.attachment;
    });
  };

  return {
    generateAttachmentUrl,
    generateAttachmentUrlLoader: result.fetching,
  };
};

export const useSingleFileUpload = () => {
  const [result, callSingleFileUpload] = useMutation(SINGLE_FILE_UPLOAD);

  const singleFileUpload = async (variables: Record<string, any>) => {
    return callSingleFileUpload(variables, {
      customHeaders: {
        "Apollo-Require-Preflight": "true",
      },
    }).then(({ data }) => data);
  };

  return {
    singleFileUpload,
    singleFileUploadLoader: result.fetching,
  };
};

export const useDeleteAttachments = () => {
  const [result, callDeleteAttachments] = useMutation(DELETE_ATTACHMENTS);
  const showNotification = useAppStore((state) => state.showNotification);
  const removeAttachmentByIds = useAttachmentStore(
    (state) => state.removeAttachmentByIds,
  );

  const deleteAttachments = async (variables: Record<string, any>) => {
    return callDeleteAttachments(variables)
      .then(({ data }) => {
        if (data?.deleteAttachments) {
          removeAttachmentByIds(variables.attachmentIds);
          showNotification(
            AvailableNotificationTypes.Success,
            "Attachment deleted successfully",
          );
        }
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error.message);
      });
  };

  return {
    deleteAttachments,
    deleteAttachmentsLoader: result.fetching,
  };
};

const CREATE_ATTACHMENT = gql`
  ${ATTACHMENT_FRAGMENT}
  mutation CreateAttachment($input: AttachmentInput!) {
    createAttachment(input: $input) {
      ...AttachmentFragment
    }
  }
`;

const UPDATE_ATTACHMENT = gql`
  ${ATTACHMENT_FRAGMENT}
  mutation UpdateAttachment($input: AttachmentUpdateInput!) {
    updateAttachment(input: $input) {
      ...AttachmentFragment
    }
  }
`;

export const useCreateAttachment = () => {
  const setSelectedAttachment = useAttachmentStore(
    (state) => state.setSelectedAttachment,
  );
  const [upsertResult, callCreateAttachment] = useMutation(CREATE_ATTACHMENT);

  const createAttachment = ({
    callback,
    ...variables
  }: Record<string, any>) => {
    return callCreateAttachment(variables).then(({ data }) => {
      const createAttachment = data?.createAttachment;
      if (createAttachment) {
        callback
          ? callback(createAttachment)
          : setSelectedAttachment(createAttachment);
      }
      return createAttachment;
    });
  };

  const { fetching } = upsertResult;

  return {
    createAttachment,
    loading: fetching,
  };
};

export const useUpdateAttachment = () => {
  const setSelectedAttachment = useAttachmentStore(
    (state) => state.setSelectedAttachment,
  );
  const updateOrRemoveAttachment = useAttachmentStore(
    (state) => state.updateOrRemoveAttachment,
  );
  const [upsertResult, callUpdateAttachment] = useMutation(UPDATE_ATTACHMENT);

  const updateAttachment = (variables: Record<string, any>) => {
    return callUpdateAttachment(variables).then(({ data }) => {
      const updateAttachment = data?.updateAttachment || {};
      setSelectedAttachment(updateAttachment);
      updateOrRemoveAttachment(updateAttachment);
      return updateAttachment;
    });
  };

  const { fetching } = upsertResult;

  return {
    updateAttachment,
    loading: fetching,
  };
};

const UPDATE_MULTIPLE_ATTACHMENTS = gql`
  mutation UpdateMultipleAttachments($input: MultipleAttachmentUpdateInput!) {
    updateMultipleAttachments(input: $input) {
      id: _id
      success
    }
  }
`;

export const useUpdateMultipleAttachments = () => {
  const [upsertResult, callUpdateMultipleAttachments] = useMutation(
    UPDATE_MULTIPLE_ATTACHMENTS,
  );

  const updateMultipleAttachments = (variables: Record<string, any>) => {
    return callUpdateMultipleAttachments(variables).then(({ data }) => {
      const results = data?.updateMultipleAttachments || [];

      return results;
    });
  };

  const { fetching } = upsertResult;

  return {
    loading: fetching,
    updateMultipleAttachments,
  };
};
