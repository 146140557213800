import { AppRoutes, AuthFormType } from "@amenda-types";
import {
  AuthLayout,
  CheckYourEmailComponent,
  DefaultLoginForm,
  RequestPasswordResetLink,
} from "@amenda-components/Auth";
import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { devConsole } from "@amenda-utils";
import { storage } from "@amenda-domains/client";
import { useAuthStore } from "@amenda-domains/mutations";

export const DefaultLoginPage: FC = () => {
  const formType = useAuthStore((state) => state.formType);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    storage
      .clear()
      .then(() => devConsole?.info("amenda:cache cleared [login]"));
  }, []);

  useEffect(() => {
    if (location.state?.from === AppRoutes.Logout) {
      navigate("/login", {
        state: { from: "/login" },
      });
      window.location.reload();
    }
  }, [location.state?.from, navigate]);

  return (
    <AuthLayout
      label={
        [AuthFormType.ResetPassword, AuthFormType.CheckYourEmail].includes(
          formType,
        )
          ? "Request password reset link"
          : "Sign in"
      }
    >
      {formType === AuthFormType.ResetPassword ? (
        <RequestPasswordResetLink />
      ) : formType === AuthFormType.CheckYourEmail ? (
        <CheckYourEmailComponent />
      ) : (
        <DefaultLoginForm />
      )}
    </AuthLayout>
  );
};
