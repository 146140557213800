import { AllowedContactType, LogicalOperators } from "@amenda-types";
import {
  Control,
  Controller,
  UseFormSetValue,
  useController,
  useFieldArray,
} from "react-hook-form";
import { FC, useEffect, useState } from "react";
import { MiniSwitch, ReactSelect } from "@amenda-components/FormComponents";
import {
  ResourceSharingPermissionTypes,
  enrichSearchFilters,
  sharePermissionsSecondaryOptions,
} from "./common";
import { useGetKeywords, useSearchUsers } from "@amenda-domains/queries";

import { ContactCardWrapper } from "@amenda-components/Contacts/ContactCardWrapper";
import { ContactPermissionCard } from "./ContactPermissionCard";
import { FilterIcon } from "lucide-react";
import { OperationsMenu } from "@amenda-components/Dashboard";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  control: Control<any>;
  componentIds?: string[];
  setValue: UseFormSetValue<any>;
}

const UsersMatchingKeywords: FC<Pick<Props, "control">> = ({ control }) => {
  const { fields, update, remove } = useFieldArray({
    control,
    name: "matchingUsers",
    keyName: "arrId",
  });

  const onPermissionChange = (index: number, role: string) => {
    if (role === sharePermissionsSecondaryOptions[0].value) {
      remove(index);
    } else {
      update(index, {
        role,
        id: (fields[index] as any).id,
      });
    }
  };

  if (isEmpty(fields)) {
    return null;
  }
  return (
    <div className="w-full space-y-1 transition duration-150 ease-in-out">
      {fields.map((field: any, i) => {
        return (
          <ContactCardWrapper
            key={field.id}
            userId={field.id}
            contactType={AllowedContactType.office}
          >
            {(contact) => (
              <ContactPermissionCard
                contact={contact}
                secondaryOptions={sharePermissionsSecondaryOptions}
                permission={field.role}
                handleChangePermission={(o) => onPermissionChange(i, o.value)}
              />
            )}
          </ContactCardWrapper>
        );
      })}
    </div>
  );
};

export const CollectionUserTags: FC<Props> = ({
  id,
  control,
  componentIds = [],
  setValue,
}) => {
  const { t } = useTranslation();
  const {
    field: { value: isShared, onChange: setIsShared },
  } = useController({
    control,
    defaultValue: false,
    name: "shareable",
  });
  const [keywords, setKeywords] = useState<any[]>([]);
  const { getKeywords, loading } = useGetKeywords();
  const { searchUsers } = useSearchUsers();
  const [operation, setOperation] = useState(LogicalOperators.AND);

  const options = keywords.map((k) => ({
    value: k.id,
    label: k.name,
    componentId: k.componentId,
  }));
  const operationOptions = [
    { value: LogicalOperators.AND, label: "and" },
    { value: LogicalOperators.OR, label: "or" },
    { value: LogicalOperators.NOT, label: "not" },
  ];

  const generateSearchUserArgs = (
    keywords: any[],
    operation: LogicalOperators,
  ) => {
    const args: Record<string, string[]> = {};
    const searchArgs: Record<string, any> = {};

    keywords.forEach((keyword) => {
      const { value, componentId } = keyword;

      if (args[componentId]) {
        args[componentId].push(value);
      } else {
        args[componentId] = [value];
      }
    });

    Object.keys(args).forEach((key) => {
      const id = `userDetails.${key}`;
      searchArgs[id] = enrichSearchFilters({ operation }, args[key]);
    });

    return searchArgs;
  };

  const getUserIdsByKeywords = async (
    keywords: any[],
    operation: LogicalOperators,
  ) => {
    if (isEmpty(keywords)) {
      return setValue("matchingUsers", []);
    }
    const match = generateSearchUserArgs(keywords, operation);

    await searchUsers({
      match,
      searchTerm: "",
      callback: (data) => {
        setValue(
          "matchingUsers",
          (data ?? []).map((u) => ({
            id: u.id,
            role: u.role ?? ResourceSharingPermissionTypes.View,
          })),
        );
      },
    });
  };

  useEffect(() => {
    if (!isEmpty(componentIds)) {
      getKeywords({
        componentIds,
        setKeywords,
      });
    }
  }, [getKeywords, componentIds]);

  if (!isShared) {
    return (
      <div className="flex justify-between">
        <label htmlFor={id} className="amenda-component-label">
          {t("Share with Contacts")}
        </label>
        <div className="flex items-center space-x-1">
          <MiniSwitch
            label="Auto create tags"
            value={isShared}
            onChange={setIsShared}
          />
        </div>
      </div>
    );
  }
  return (
    <>
      <Controller
        name="userKeywords"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <div className="w-full">
              <ReactSelect
                id={id}
                isMulti
                label="Share with Contacts of Tags"
                isLoading={loading}
                options={options}
                error={error?.message}
                value={value}
                hasMenuOverflow={true}
                onChange={async (value) => {
                  onChange(value);
                  await getUserIdsByKeywords(value, operation);
                }}
                optional={
                  <div className="flex items-center space-x-2">
                    <OperationsMenu
                      withIcon
                      Icon={FilterIcon}
                      options={operationOptions}
                      selectedOption={operation}
                      onChange={async (option) => {
                        const operation = option.value as LogicalOperators;

                        setOperation(operation);
                        await getUserIdsByKeywords(value, operation);
                      }}
                    />
                    <MiniSwitch
                      label="Auto create tags"
                      value={isShared}
                      onChange={setIsShared}
                    />
                  </div>
                }
              />
            </div>
          );
        }}
      />
      <UsersMatchingKeywords control={control} />
    </>
  );
};
