import { FC, useEffect, useState } from "react";

import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Option } from "@amenda-types";
import { Spinner } from "./Spinner";
import { Transition } from "@headlessui/react";
import { buttonTheme } from "@amenda-styles/theme";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export type ButtonDropdownOption = Option & {
  properties?: Record<string, any>;
};

interface Props {
  loading?: boolean;
  ariaLabel?: string;
  tooltipId?: string;
  disabled?: boolean;
  options: ButtonDropdownOption[];
  menuClassName?: string;
  defaultIndex?: number;
  menuPosition?: "bottom-right" | "top-left" | "bottom-left";
  onClick: (value: string) => void;
}

export const ButtonWithDropdown: FC<Props> = ({
  options,
  loading,
  ariaLabel,
  tooltipId,
  disabled,
  defaultIndex = 0,
  menuPosition = "bottom-right",
  menuClassName = "w-40",
  onClick,
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<ButtonDropdownOption>(
    options[defaultIndex],
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleMenu = () => setIsOpen(!isOpen);
  const handleClick = (value: string) => () => onClick(value);
  const handleSelectOption = (value: Option) => () => {
    setSelected(value);
    setIsOpen(false);
  };

  useEffect(() => {
    setSelected(options[defaultIndex]);
  }, [defaultIndex, options]);

  return (
    <div className="relative">
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <ul
          className={clsx(
            "absolute z-40 divide-y divide-gray-200 bg-white py-1 text-sm text-gray-700 shadow",
            menuClassName,
            {
              "left-0 top-0": menuPosition === "top-left",
              "bottom-0 right-0": menuPosition === "bottom-right",
            },
          )}
        >
          {options.map((option) => (
            <li key={option.value}>
              <button
                className={clsx("block w-full px-4 py-2", {
                  "bg-gray-800 text-white": option.value === selected.value,
                  "hover:bg-gray-100": option.value !== selected.value,
                })}
                type="button"
                onClick={handleSelectOption(option)}
              >
                {t(option.label)}
              </button>
            </li>
          ))}
        </ul>
      </Transition>
      <nav
        tabIndex={-1}
        className="isolate inline-flex -space-x-px rounded-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        aria-label={ariaLabel}
        data-tooltip-id={tooltipId}
      >
        <button
          className={buttonTheme({
            size: "md",
            variant: "primary",
          })}
          type="button"
          disabled={disabled}
          onClick={handleClick(selected.value)}
          {...(selected?.properties || {})}
        >
          {t(selected.label)}
          {loading && <Spinner spinnerSize="xs" variant="primary" />}
        </button>
        <button
          type="button"
          className={buttonTheme({
            size: "md",
            variant: "primary",
            class: "px-1 md:px-2",
          })}
          onClick={toggleMenu}
        >
          <span className="sr-only">{t("Open menu")}</span>
          <ChevronRightIcon
            className={clsx("h-5 w-5", {
              "rotate-90": isOpen,
            })}
            aria-hidden="true"
          />
        </button>
      </nav>
    </div>
  );
};
