import {
  AllProjectsView,
  CostEstimationModal,
  ProjectLayout,
  ProjectsActionBar,
} from "@amenda-components/Projects";
import {
  AllowedCollectionType,
  ProjectView,
  ReactTableKeys,
} from "@amenda-types";
import {
  isSearchFilterEmpty,
  transformFilters,
} from "@amenda-components/Shared/common";
import {
  useAppStore,
  useFormStore,
  useProjectStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";
import { useGetAllProjects, useSearchProjects } from "@amenda-domains/queries";

import { AggregationWidgetViewerWrapper } from "@amenda-components/Dashboard";
import { FC } from "react";
import { PaginationLimit } from "@amenda-constants";
import { getProjectArgs } from "@amenda-components/Projects/common";
import isEmpty from "lodash/isEmpty";
import { useRunOnMountOnly } from "@amenda-utils";

interface Props {
  isCollection?: boolean;
}

export const ProjectsPageWrapper: FC<Props> = ({ isCollection = false }) => {
  const { getAllProjects } = useGetAllProjects();
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const similarProjectIds = useProjectStore(
    (state) => state.similarityResourceIds,
  );
  const { sidebarFilters, searchTerm } = useSidebarFiltersWithPath();
  const clearProjects = useProjectStore((state) => state.clearProjects);
  const { searchProjects } = useSearchProjects();
  const projectView = useProjectStore((state) => state.projectView);
  const columnSorting = useAppStore(
    (state) => state.tableState[ReactTableKeys.Projects].columnSorting,
  );

  const selectedCollection =
    selectedCollectionByType?.[AllowedCollectionType.Projects];

  useRunOnMountOnly(
    async () => {
      const isTableView = projectView === ProjectView.Table;

      clearProjects();
      if (!isEmpty(searchTerm) || !isSearchFilterEmpty(sidebarFilters)) {
        await searchProjects({
          searchTerm,
          isCollection,
          isTableView,
          columnSorting,
          filters: transformFilters(sidebarFilters),
          resourceIds: selectedCollection?.resourceIds,
        });
      } else {
        await getAllProjects({
          isTableView,
          columnSorting,
          ...getProjectArgs({
            isCollection,
            similarProjectIds,
            collectionProjectIds: selectedCollection?.resourceIds,
          }),
          limit: PaginationLimit.projects,
          context: {
            requestPolicy: "cache-and-network",
          },
        });
      }
    },
    JSON.stringify([
      isCollection,
      projectView,
      selectedCollection?.resourceIds,
    ]),
  );

  return (
    <ProjectLayout
      className="px-4"
      title={isCollection ? "Projects collection" : "All Projects"}
      wrapperClassName="h-screen overflow-y-auto overscroll-y-contain"
    >
      <ProjectsActionBar isCollection={isCollection} />
      <AggregationWidgetViewerWrapper />
      <CostEstimationModal />
      <AllProjectsView isCollection={isCollection} />
    </ProjectLayout>
  );
};
