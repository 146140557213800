import { FC, ReactNode } from "react";

import { Helmet } from "react-helmet-async";
import { MaxWidthLayout } from "@amenda-components/Shared";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  children: ReactNode;
}

export const SettingsLayout: FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={t("Settings")} />
      <MaxWidthLayout
        wrapperClassName="h-screen"
        className="h-full px-4 py-1 pb-5 lg:py-6 2xl:w-[1200px]"
      >
        {children}
      </MaxWidthLayout>
    </>
  );
};
