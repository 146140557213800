import { FC, useEffect, useState } from "react";
import { FullColumn, GridWrapper } from "@amenda-components/PageLayouts";

import { LoaderWrapper } from "@amenda-components/App";
import { MiniCard } from "@amenda-components/Shared";
import isEmpty from "lodash/isEmpty";
import { useAttachmentStore } from "@amenda-domains/mutations";
import { useGetProject } from "@amenda-domains/queries";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const GalleryReferences: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectedAttachment = useAttachmentStore(
    (state) => state.selectedAttachment,
  );
  const [project, setProject] = useState<any>();
  const { loading, getProject } = useGetProject();

  const projectId = selectedAttachment?.formValues?.project;

  const handleClick = () => {
    navigate(`/projekte/${projectId}`);
  };

  useEffect(() => {
    if (projectId) {
      getProject({
        id: projectId,
        callback: setProject,
      });
    }
  }, [getProject, projectId]);

  if (loading) {
    return <LoaderWrapper />;
  } else if (isEmpty(project)) {
    return (
      <div className="px-2 pt-4">
        <p className="text-sm text-gray-500">
          {t("This attachment has no references")}
        </p>
      </div>
    );
  }
  return (
    <GridWrapper>
      <FullColumn>
        <div className="cursor-pointer px-2" onClick={handleClick}>
          <MiniCard
            image={project?.galleryUrl}
            projectName={project?.name}
            projectNumber={project?.number}
            projectDescription={project?.description_short}
          />
        </div>
      </FullColumn>
    </GridWrapper>
  );
};
