import { FC } from "react";
import { FileImage } from "lucide-react";
import clsx from "clsx";

interface SkeletonProps {
  width?: number;
  height?: number;
}

export const Skeleton: FC<SkeletonProps> = ({ width = 24, height = 4 }) => {
  return (
    <div className="flex animate-pulse flex-row items-center">
      <div className="flex flex-col space-y-2">
        <div
          className={clsx(
            "rounded-sm bg-gray-300",
            `w-${width}`,
            `h-${height}`,
          )}
        />
      </div>
    </div>
  );
};

interface SkeletonAltProps {
  className?: string;
}

export const SkeletonAlt: FC<SkeletonAltProps> = ({
  className = "bg-gray-300",
}) => {
  return (
    <div className="flex animate-pulse flex-row items-center">
      <div className="flex flex-col space-y-2">
        <div className={clsx("rounded-xs", className)} />
      </div>
    </div>
  );
};

interface SkeletonImageProps {
  className?: string;
}

export const SkeletonImage: FC<SkeletonImageProps> = ({
  className = "h-48 w-full",
}) => {
  return (
    <div
      className={clsx(
        "flex animate-pulse items-center justify-center rounded bg-gray-200",
        className,
      )}
    >
      <FileImage className="h-10 w-10 text-gray-500" />
    </div>
  );
};
