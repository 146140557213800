import { AvailableImageVariants, HoverVariants } from "@amenda-types";
import {
  ColoredBadge,
  HoverSelector,
  Image,
  ReadOnlyColoredSelect,
  Tag,
  Tags,
} from "@amenda-components/App";
import {
  formatDate,
  getComponentsFromForms,
  isRestricted,
} from "@amenda-utils";
import {
  getGalleryCardKeywordIds,
  getKeywordsFromSidebarFilters,
} from "@amenda-components/Gallery/common";
import {
  useAttachmentStore,
  useProjectStore,
  useSettingsStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";

import { AttachmentType } from "./types";
import { AvailableForms } from "@amenda-constants";
import { Clock4Icon } from "lucide-react";
import { EmptyIndicator } from "@amenda-components/Shared";
import { FC } from "react";
import { ReadOnlyKeywordsBase } from "@amenda-components/Shared/ReadOnlyKeywords";
import { cardTheme } from "@amenda-styles/theme";
import clsx from "clsx";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

interface Props {
  rootRoute: string;
  attachment: AttachmentType;
  componentsById: Record<string, any>;
}

const TagsAndName: FC<Pick<Props, "attachment">> = ({ attachment }) => {
  const { ref, inView } = useInView();
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const { sidebarFilters } = useSidebarFiltersWithPath();
  const forms = useProjectStore((state) => state.forms);

  const availableForms = forms?.[AvailableForms.ConstructionDetails];
  const components = getComponentsFromForms(availableForms);
  const { keywordIds, keywordComponentIds } = getGalleryCardKeywordIds({
    permissions,
    components,
    attachment,
  });
  const filterKeywords = getKeywordsFromSidebarFilters(
    sidebarFilters,
    keywordComponentIds,
  );

  return (
    <div ref={ref} className="h-6 w-full overflow-hidden">
      {inView && (
        <ReadOnlyKeywordsBase keywords={keywordIds}>
          {(loading, selectedKeywords) => (
            <Tags>
              {selectedKeywords?.map((keyword) => {
                const isSelected = filterKeywords.includes(keyword.id);

                return (
                  <Tag
                    key={keyword?.id}
                    variant="primary"
                    isSelected={isSelected}
                  >
                    {keyword?.name}
                  </Tag>
                );
              })}
            </Tags>
          )}
        </ReadOnlyKeywordsBase>
      )}
    </div>
  );
};

interface ConstructionCardWrapperProps
  extends Pick<Props, "rootRoute" | "attachment"> {
  children: (props: {
    isSelected: boolean;
    handleClick: () => void;
    handleSelect: () => void;
    handleShiftSelect: () => void;
  }) => React.ReactNode;
}

const ConstructionCardWrapper: FC<ConstructionCardWrapperProps> = ({
  attachment,
  rootRoute,
  children,
}) => {
  const navigate = useNavigate();
  const setSelectedAttachment = useAttachmentStore(
    (state) => state.setSelectedAttachment,
  );
  const toggleSelectedAttachment = useAttachmentStore(
    (state) => state.toggleSelectedAttachment,
  );
  const selectedAttachments = useAttachmentStore(
    (state) => state.selectedAttachments,
  );
  const setShiftSelectedAttachment = useAttachmentStore(
    (state) => state.setShiftSelectedAttachment,
  );

  const isSelected = selectedAttachments.includes(attachment.id);

  const handleClick = () => {
    const path = `${rootRoute}/${attachment.id}`;

    setSelectedAttachment(attachment);
    navigate(path);
  };

  const handleSelect = () => {
    toggleSelectedAttachment(attachment.id);
  };

  const handleShiftSelect = () => {
    setShiftSelectedAttachment(attachment);
  };

  return (
    <>
      {children({
        isSelected,
        handleClick,
        handleSelect,
        handleShiftSelect,
      })}
    </>
  );
};

export const ConstructionDetailsCard: FC<Props> = ({
  rootRoute,
  attachment,
  componentsById,
}) => {
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  return (
    <ConstructionCardWrapper rootRoute={rootRoute} attachment={attachment}>
      {({ isSelected, handleClick, handleSelect, handleShiftSelect }) => {
        const { imageCss, cardCss, titleCss, descriptionCss } = cardTheme({
          isSelected,
          variant: "secondary",
        });

        return (
          <HoverSelector
            className={cardCss()}
            canSelect={true}
            variant={HoverVariants.Checkbox}
            id={attachment.id}
            isSelected={isSelected}
            handleSelect={handleSelect}
            handleClick={handleClick}
            handleShiftSelect={handleShiftSelect}
          >
            <div>
              <Image
                className={imageCss()}
                isFullWidth={true}
                url={attachment.url}
                variant={AvailableImageVariants.contain}
                size="pb-2/3"
                magicSize="gallery"
              />
              <div className="w-full pt-1">
                <div className="flex items-center justify-between">
                  {!isRestricted(componentsById["name"], permissions) && (
                    <div className={titleCss()}>
                      {attachment?.formValues?.name ??
                        attachment.originalFilename}
                    </div>
                  )}
                  {!isRestricted(componentsById["rateDetail"], permissions) && (
                    <ColoredBadge
                      id={`colored-badge-${attachment.id}`}
                      size="sm"
                      value={attachment?.formValues?.rateDetail}
                    />
                  )}
                </div>
                {!isRestricted(
                  componentsById["constructionDate"],
                  permissions,
                ) && (
                  <div
                    className={descriptionCss({
                      className: clsx("flex", {
                        "items-center":
                          !!attachment?.formValues?.constructionDate,
                      }),
                    })}
                  >
                    <Clock4Icon className="mr-1 h-4 w-4" />
                    {attachment?.formValues?.constructionDate ? (
                      <span>
                        {formatDate(attachment.formValues.constructionDate)}
                      </span>
                    ) : (
                      <EmptyIndicator />
                    )}
                  </div>
                )}
                <TagsAndName attachment={attachment} />
              </div>
            </div>
          </HoverSelector>
        );
      }}
    </ConstructionCardWrapper>
  );
};

export const ConstructionDetailsListCard: FC<Props> = ({
  rootRoute,
  attachment,
  componentsById,
}) => {
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  return (
    <ConstructionCardWrapper rootRoute={rootRoute} attachment={attachment}>
      {({ isSelected, handleClick, handleSelect, handleShiftSelect }) => {
        const { imageCss, cardCss, titleCss, descriptionCss } = cardTheme({
          isSelected,
          variant: "secondary",
        });

        return (
          <HoverSelector
            className={cardCss()}
            canSelect={true}
            variant={HoverVariants.Checkbox}
            id={attachment.id}
            isSelected={isSelected}
            handleSelect={handleSelect}
            handleClick={handleClick}
            handleShiftSelect={handleShiftSelect}
          >
            <div className="grid grid-cols-1 items-center gap-y-2 md:grid-cols-5 md:gap-x-2 lg:grid-cols-7 lg:gap-x-4">
              <Image
                className={imageCss()}
                isFullWidth={true}
                variant={AvailableImageVariants.contain}
                url={attachment.url}
                size="pb-2/3"
                magicSize="gallery"
              />
              <div className="flex flex-col space-y-1">
                {!isRestricted(componentsById["name"], permissions) && (
                  <div
                    className={titleCss({
                      class: "lg:mt-0",
                    })}
                  >
                    {attachment?.formValues?.name ??
                      attachment.originalFilename}
                  </div>
                )}
                {!isRestricted(
                  componentsById["constructionDate"],
                  permissions,
                ) && (
                  <div
                    className={descriptionCss({
                      className: clsx("flex", {
                        "items-center":
                          !!attachment?.formValues?.constructionDate,
                      }),
                    })}
                  >
                    <Clock4Icon className="mr-1 h-4 w-4" />
                    {attachment?.formValues?.constructionDate ? (
                      <span>
                        {formatDate(attachment.formValues.constructionDate)}
                      </span>
                    ) : (
                      <EmptyIndicator />
                    )}
                  </div>
                )}
              </div>
              {!isRestricted(componentsById["description"], permissions) && (
                <div
                  className={descriptionCss({
                    class:
                      "col-span-1 whitespace-pre-line md:col-span-2 lg:mb-0",
                  })}
                >
                  {attachment?.formValues?.description}
                </div>
              )}
              {!isRestricted(componentsById["rateDetail"], permissions) && (
                <div className="text-sm">
                  <ReadOnlyColoredSelect
                    options={[]}
                    value={attachment?.formValues?.rateDetail}
                  />
                </div>
              )}
              <div className="col-span-1 md:col-span-2">
                <TagsAndName attachment={attachment} />
              </div>
            </div>
          </HoverSelector>
        );
      }}
    </ConstructionCardWrapper>
  );
};
