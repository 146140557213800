import { FC, useRef } from "react";
import { LoaderWrapper, Spinner } from "@amenda-components/App";
import {
  useAppStore,
  useFormStore,
  useProjectStore,
} from "@amenda-domains/mutations";

import { AllowedCollectionType } from "@amenda-types";
import { PaddingRow } from "@amenda-components/PageBuilder/PaddingRow";
import { PaginationLimit } from "@amenda-constants";
import { ProjectCard } from "./ProjectCard";
import clsx from "clsx";
import { getPadding } from "@amenda-components/Shared/reactTableHelpers";
import { getProjectArgs } from "./common";
import isEmpty from "lodash/isEmpty";
import { isSafeCollection } from "@amenda-utils";
import { useGetAllProjects } from "@amenda-domains/queries";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import { useVirtualizer } from "@tanstack/react-virtual";

interface Props {
  componentsById: Record<string, any>;
  projects: any[];
  isCollection?: boolean;
}

export const ProjectGridView: FC<Props> = ({
  isCollection,
  projects,
  componentsById,
}) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const sidebarOpen = useAppStore((state) => state.isSidebarOpen);
  const { getAllProjects } = useGetAllProjects();
  const pagination = useProjectStore((state) => state.pagination);
  const isFetching = useProjectStore((state) => state.isFetching);
  const isSearching = useProjectStore((state) => state.isSearching);
  const similarProjectIds = useProjectStore(
    (state) => state.similarityResourceIds,
  );
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const virtualizer = useVirtualizer({
    lanes: 6,
    overscan: 6,
    count: projects.length,
    getScrollElement: () => wrapperRef.current,
    estimateSize: () => 1,
  });

  const virtualRows = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();
  const { paddingBottom, paddingTop } = getPadding(virtualRows, totalSize);
  const { ref } = useInView({
    threshold: 0.5,
    root: containerRef.current,
    onChange(inView, entry) {
      if (
        inView &&
        !isFetching &&
        pagination?.hasNext &&
        isSafeCollection(isCollection, selectedCollection?.resourceIds)
      ) {
        getAllProjects({
          ...getProjectArgs({
            isCollection,
            similarProjectIds,
            collectionProjectIds: selectedCollection?.resourceIds,
          }),
          paginationProps: {
            next: pagination?.next,
            limit: PaginationLimit.projects,
          },
        });
      }
    },
  });

  const selectedCollection =
    selectedCollectionByType?.[AllowedCollectionType.Projects];
  const isLoadingInVirtualizer =
    isEmpty(virtualizer.getVirtualItems()) && !isEmpty(projects);

  return (
    <div
      ref={containerRef}
      className="relative h-[calc(100vh-96px)] w-full overflow-y-auto overscroll-y-contain"
    >
      {isSearching || isLoadingInVirtualizer ? (
        <LoaderWrapper />
      ) : isFetching && isEmpty(projects) ? (
        <LoaderWrapper className="h-44" />
      ) : null}
      {containerRef?.current && !isSearching && projects.length > 0 && (
        <div
          ref={wrapperRef}
          className={clsx(
            "grid-cols grid auto-rows-min gap-2 transition-all duration-500 md:gap-2 lg:gap-2 xxl:gap-8",
            {
              "grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 xxl:grid-cols-5":
                !sidebarOpen,
              "grid-cols-2 md:grid-cols-2 lg:grid-cols-4": sidebarOpen,
            },
          )}
          style={{
            height: containerRef.current.clientHeight,
          }}
        >
          {totalSize >= 12 && (
            <div className="col-span-full">
              <PaddingRow padding={paddingTop} />
            </div>
          )}
          {virtualizer.getVirtualItems().map((virtualRow, i) => {
            const project = projects[virtualRow.index];
            const projectName = componentsById["name"];
            const projectNumber = componentsById["number"];
            const projectAddress = componentsById["address"];

            return (
              <ProjectCard
                key={project.id}
                project={project}
                projectName={projectName}
                projectNumber={projectNumber}
                projectAddress={projectAddress}
              />
            );
          })}
          <div className="col-span-full">
            {pagination?.hasNext && (
              <div className="flex w-full items-center justify-center space-x-2">
                <Spinner spinnerSize="xs" variant="default" />
                <span className="text-sm">{t("Loading more projects...")}</span>
              </div>
            )}
            <div ref={ref} className="h-8 w-full" />
            {totalSize >= 12 && <PaddingRow padding={paddingBottom} />}
          </div>
        </div>
      )}
    </div>
  );
};
