import { FC, useEffect } from "react";
import {
  ProjectPrintView,
  ReadonlyProjectHeader,
} from "@amenda-components/Projects";

import { MaxWidthLayout } from "@amenda-components/Shared";
import { useGetProject } from "@amenda-domains/queries";
import { useParams } from "react-router";
import { useProjectStore } from "@amenda-domains/mutations";

export const PrintProjectAltPage: FC = () => {
  const { projectId } = useParams<{ projectId?: string }>();
  const { getProject, loading } = useGetProject();
  const clearProjectValues = useProjectStore(
    (state) => state.clearProjectValues,
  );

  useEffect(() => {
    if (projectId) {
      getProject({
        id: projectId,
        redirectWhenEmpty: true,
        context: {
          requestPolicy: "cache-and-network",
        },
      });
    }
  }, [projectId, getProject]);

  useEffect(() => {
    return () => {
      clearProjectValues();
    };
  }, [clearProjectValues]);

  return (
    <MaxWidthLayout
      wrapperClassName="py-4 px-6 h-full"
      className="lg:max-w-[1200px]"
    >
      <ReadonlyProjectHeader showPrintView={true} />
      <div className="w-full pt-4">
        <ProjectPrintView loading={loading} />
      </div>
    </MaxWidthLayout>
  );
};
