import { Control, UseFormSetValue } from "react-hook-form";
import { getComponentsFromForms, groupComponents } from "@amenda-utils";
import { getProjectComponents, permissionColumns } from "../../common";

import { AvailableForms } from "@amenda-constants";
import { ComponentsTreeView } from "@amenda-components/PageBuilder";
import { FC } from "react";
import { SettingsPermissionConfig } from "@amenda-components/Settings";
import { useProjectStore } from "@amenda-domains/mutations";

interface Props {
  control: Control;
  setValue: UseFormSetValue<any>;
  globalData: Record<string, any>;
}

export const SettingsProjectsPermissions: FC<Props> = ({
  control,
  setValue,
  globalData,
}) => {
  const forms = useProjectStore((state) => state.forms);

  const projectForms = forms?.[AvailableForms.Project] || [];
  const components = getComponentsFromForms([...projectForms]);
  const groupedComponents = groupComponents(components);
  const projectComponents = getProjectComponents(groupedComponents);

  return (
    <ComponentsTreeView
      readOnly={false}
      control={control}
      setValue={setValue}
      globalData={globalData}
      components={projectComponents}
      columns={permissionColumns}
      label="project permissions"
      searchPlaceholder="Filter..."
      className="h-[calc(100vh-9rem)] max-h-full"
      RowBody={SettingsPermissionConfig}
    />
  );
};
