import { Control, UseFormSetValue } from "react-hook-form";
import { GeneralPermissionKeys, contactPermissionTabs } from "../common";
import { HeadlessTabs, Modal } from "@amenda-components/App";
import {
  SettingsConstructionDetailsPermissions,
  SettingsContactsPermissions,
  SettingsMediaPermissions,
  SettingsProjectsPermissions,
  SettingsUnitPricePermissions,
} from "@amenda-components/Settings";

import { FC } from "react";

interface Props {
  isOpen: boolean;
  selectedTab: GeneralPermissionKeys;
  control: Control<any, object>;
  globalData?: Record<string, any>;
  setValue: UseFormSetValue<any>;
  onClose: () => void;
}

const SettingsPermissionsWrapper: FC<Omit<Props, "isOpen" | "onClose">> = ({
  selectedTab,
  control,
  globalData = {},
  setValue,
}) => {
  if (selectedTab === GeneralPermissionKeys.Projects) {
    return (
      <SettingsProjectsPermissions
        control={control}
        setValue={setValue}
        globalData={globalData}
      />
    );
  } else if (selectedTab === GeneralPermissionKeys.Media) {
    return (
      <SettingsMediaPermissions
        control={control}
        setValue={setValue}
        globalData={globalData}
      />
    );
  } else if (selectedTab === GeneralPermissionKeys.UnitPrice) {
    return (
      <SettingsUnitPricePermissions
        control={control}
        setValue={setValue}
        globalData={globalData}
      />
    );
  } else if (selectedTab === GeneralPermissionKeys.ConstructionDetails) {
    return (
      <SettingsConstructionDetailsPermissions
        control={control}
        setValue={setValue}
        globalData={globalData}
      />
    );
  } else if (selectedTab === GeneralPermissionKeys.Contacts) {
    return (
      <HeadlessTabs tabs={contactPermissionTabs}>
        {(tab) => (
          <SettingsContactsPermissions
            tab={tab}
            control={control}
            globalData={globalData}
            setValue={setValue}
          />
        )}
      </HeadlessTabs>
    );
  }
  return null;
};

export const FieldPermissionsModal: FC<Props> = ({
  isOpen,
  onClose,
  ...rest
}) => {
  return (
    <Modal
      size="md"
      title="Configure field permissions"
      message="Toggle general permissions for this field."
      isOpen={isOpen}
      withCancel={false}
      closeModalFromTitle={true}
      onClose={onClose}
    >
      <div className="py-2">
        <SettingsPermissionsWrapper {...rest} />
      </div>
    </Modal>
  );
};
