import { gql } from "urql";

export const KEYWORD_FRAGMENT = gql`
  fragment KeywordFragment on Keyword {
    id: _id
    name
    tenantId
    componentId
    resourceId
  }
`;

export const COLLECTIONS_FRAGMENT = gql`
  fragment CollectionsFragment on Collection {
    id: _id
    name
    keywords
    ownerId
    resourceIds
    description
    collectionType
    share {
      system {
        id
        role
      }
      users {
        id
        role
      }
    }
  }
`;

export const COLLECTION_FRAGMENT = gql`
  fragment CollectionFragment on Collection {
    id: _id
    name
    keywords
    createdAt
    updatedAt
    isDeleted
    resourceIds
    description
    collectionType
    ownerId
    share {
      system {
        id
        role
      }
      users {
        id
        role
      }
    }
  }
`;

export const COLLECTION_SUBSCRIPTION_FRAGMENT = gql`
  fragment CollectionSubsFragment on CollectionSubscription {
    id: _id
    isDeleted
  }
`;

export const FORM_FRAGMENT = gql`
  fragment FormFragment on Form {
    id: _id
    name
    order
    category
    components
    isDeleted
    properties
  }
`;
