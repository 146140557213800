import {
  AppRoutes,
  NavigationProps,
  TabItem,
  TabItemLink,
} from "@amenda-types";

export const getSidebarNavigation = (
  hasFullAdminAccess: boolean,
): NavigationProps[] => {
  const navigation: { label: string; path: string }[] = [
    // {
    //   label: "Dashboard",
    //   path: AppRoutes.Root,
    // },
    {
      label: "Projects",
      path: AppRoutes.Projects,
    },
    {
      label: "Medien",
      path: AppRoutes.Attachments,
    },
    {
      label: "Contacts",
      path: AppRoutes.Contacts,
    },
    {
      label: "Colleagues",
      path: AppRoutes.Colleagues,
    },
    {
      label: "Einheitspreise",
      path: AppRoutes.Materials,
    },
    {
      label: "Construction Details",
      path: AppRoutes.ConstructionDetails,
    },
    {
      label: "Settings",
      path: AppRoutes.Settings,
    },
  ];
  if (hasFullAdminAccess) {
    navigation.push({
      label: "Administration",
      path: AppRoutes.Admin,
    });
  }

  return navigation;
};

export const topNavigation: NavigationProps[] = [
  {
    label: "Projects",
    path: "/projekte",
  },
  {
    label: "Medien",
    path: "/medien",
  },
  {
    label: "Contacts",
    path: "/kontaktverzeichnis",
  },
];
export const userMenuNavigation: NavigationProps[] = [
  { label: "Your Profile", path: "/einstellungen" },
  { label: "Administration", path: "/admin" },
  { label: "Sign out", path: "/logout" },
];

export const PDF_TOKEN = "amenda_LOCAL:GENERATE_PDF_TOKEN";

export const settingsNavigation: TabItem[] = [
  {
    label: "Profile & Notifications",
    value: "/einstellungen",
  },
  { label: "Security", value: "/einstellungen/security" },
];

export const adminSettingsNavigation: TabItem[] = [
  { label: "Users", value: "/admin" },
  { label: "Roles", value: "/admin/rollen" },
  {
    label: "Office Information",
    value: "/admin/konto",
  },
  {
    label: "Regional Factors",
    value: "/admin/regional-factors",
  },
  {
    label: "Similarity Search",
    value: "/admin/similarity-search",
  },
  {
    label: "Form Builder",
    value: "/admin/form-builder",
  },
];

export const projectBreadCrumb: TabItemLink[] = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "Projektübersicht",
    path: "/projekte",
  },
  {
    label: "Projekt bearbeiten",
    path: "#",
  },
];

export const pdfSettingsNavigation: TabItemLink[] = [
  { label: "General", path: "/einstellungen/styles" },
  {
    label: "Project Portfolios",
    path: "/einstellungen/styles/project-portfolios",
  },
  {
    label: "Project Lists",
    path: "/einstellungen/styles/project-list",
  },
];

export const inboxBreadCrumb: TabItemLink[] = [
  {
    label: "Home",
    path: "/inbox",
  },
  {
    label: "Filter 1",
    path: "/filter1",
  },
  {
    label: "New Portfolio",
    path: "/portfolio/new",
  },
];

const truncateName = (value: string, totalChar: number = 25) => {
  if (value && value.length > totalChar) {
    return value.slice(0, totalChar - 1);
  }
  return value;
};

export const getProjectBreadcrumb = ({
  selectedProject,
  isEditing = false,
}: {
  selectedProject?: any;
  isEditing?: boolean;
}) => {
  let crumbs = [
    {
      label: "Home",
      key: "home",
      path: "/",
    },
    {
      label: "Projektübersicht",
      key: "all_project",
      path: "/projekte",
    },
    {
      label: "New Project",
      key: "new_project",
      path: "#",
    },
  ];
  if (selectedProject) {
    crumbs = crumbs.map((crumb) =>
      crumb.key === "new_project"
        ? {
            label: truncateName(selectedProject.name),
            key: "selected_project",
            path: isEditing ? `/projekte/${selectedProject.id}` : "#",
          }
        : crumb,
    );
    if (isEditing) {
      crumbs.push({
        label: "Edit",
        key: "edit_project",
        path: "#",
      });
    }
  }
  return crumbs;
};

export const getPortfolioBreadCrumb = ({
  selectedPortfolio,
  isEditing = false,
}: {
  selectedPortfolio?: any;
  isEditing?: boolean;
}) => {
  let crumbs = [
    {
      label: "Home",
      key: "home",
      path: "/",
    },
    {
      label: "All Portfolios",
      key: "all_portfolios",
      path: "/portfolio",
    },
    {
      label: "New Portfolio",
      key: "new_portfolio",
      path: "#",
    },
  ];
  if (selectedPortfolio) {
    crumbs = crumbs.map((crumb) =>
      crumb.key === "new_portfolio"
        ? {
            label: truncateName(selectedPortfolio.name),
            key: "selected_portfolio",
            path: isEditing ? `/portfolio/${selectedPortfolio?.id}` : "#",
          }
        : crumb,
    );
    if (isEditing) {
      crumbs.push({
        label: "Portfolio",
        key: "edit_portfolio",
        path: "#",
      });
    }
  }
  return crumbs;
};

export enum BlockedRoutes {
  ContactsCollection = "/kontaktverzeichnis/sammlungen",
  ColleaguesCollection = "/bueroverzeichnis/sammlungen",
  ProjectNew = "/projekte/neu/allgemein",
  ProjectEdit = "/projekte/:projectId/bearbeiten",
  MaterialNew = "/einheitspreise/neu",
  MaterialEdit = "/einheitspreise/:materialId/bearbeiten",
  MaterialCollectionEdit = "/einheitspreise/sammlungen/:collectionId/bearbeiten",
  Admin = "/admin",
  ContactsEdit = "/kontaktverzeichnis/:userId/bearbeiten",
  ContactsCollectionEdit = "/kontaktverzeichnis/sammlungen/:collectionId/:userId/bearbeiten",
  ColleaguesEdit = "/bueroverzeichnis/:userId/bearbeiten",
  ColleaguesCollectionEdit = "/bueroverzeichnis/sammlungen/:collectionId/:userId/bearbeiten",
  MediaEdit = "/medien/:mediaId/bearbeiten",
  MediaCollectionEdit = "/medien/sammlungen/:collectionId/:mediaId/bearbeiten",
  ConstructionDetailsNew = "/planungsdetails/neu",
  ConstructionDetailsEdit = "/planungsdetails/:constructionDetailId/bearbeiten",
  ConstructionDetailsCollectionEdit = "/planungsdetails/sammlungen/:collectionId/:constructionDetailId/bearbeiten",
}

export const NotFoundRoute = "/nicht-gefunden";
