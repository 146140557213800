import { FC, useRef, useState } from "react";
import { LoaderWrapper, Spinner } from "@amenda-components/App";
import {
  getComponentsById,
  getComponentsFromForms,
  useRunOnMountOnly,
} from "@amenda-utils";
import { useAttachmentStore, useProjectStore } from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import { ConstructionDetailsCard } from "./ConstructionDetailsCard";
import { ConstructionDetailsProps } from "./types";
import { PaddingRow } from "@amenda-components/PageBuilder/PaddingRow";
import { getPadding } from "@amenda-components/Shared/reactTableHelpers";
import { isEmpty } from "lodash";
import { layoutTheme } from "@amenda-styles/theme";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import { useVirtualizer } from "@tanstack/react-virtual";

type Props = Pick<
  ConstructionDetailsProps,
  | "pagination"
  | "canUpload"
  | "rootRoute"
  | "attachments"
  | "fetchPaginatedAttachments"
>;

export const ConstructionDetailsGridView: FC<Props> = ({
  pagination,
  attachments,
  rootRoute,
  fetchPaginatedAttachments,
}) => {
  const { t } = useTranslation();
  const [key, setKey] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const isSearching = useAttachmentStore((state) => state.isSearching);
  const isFetching = useAttachmentStore((state) => state.isFetching);
  const virtualizer = useVirtualizer({
    lanes: 4,
    overscan: 4,
    count: attachments.length,
    getScrollElement: () => wrapperRef.current,
    estimateSize: () => 1,
  });
  const forms = useProjectStore((state) => state.forms);

  const availableForms = forms?.[AvailableForms.ConstructionDetails];
  const components = getComponentsFromForms(availableForms);
  const componentsById = getComponentsById(components);
  const virtualRows = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();
  const { paddingBottom, paddingTop } = getPadding(virtualRows, totalSize);
  const { ref } = useInView({
    threshold: 0.5,
    root: containerRef.current,
    onChange(inView, entry) {
      if (inView && !isFetching && fetchPaginatedAttachments) {
        fetchPaginatedAttachments();
      }
    },
  });

  const { gridCss } = layoutTheme({});
  const isLoadingInVirtualizer =
    isEmpty(virtualizer.getVirtualItems()) && !isEmpty(attachments);

  useRunOnMountOnly(() => {
    setKey((prev) => prev + 1);
  });

  return (
    <div
      key={key}
      ref={containerRef}
      className="relative h-[calc(100vh-200px)] w-full overflow-y-auto overscroll-y-contain"
    >
      {isSearching || isLoadingInVirtualizer ? (
        <LoaderWrapper />
      ) : isFetching && isEmpty(attachments) ? (
        <LoaderWrapper className="h-44" />
      ) : null}
      {containerRef?.current && !isSearching && attachments.length > 0 && (
        <div
          ref={wrapperRef}
          className={gridCss()}
          style={{
            height: containerRef.current.clientHeight,
          }}
        >
          {totalSize >= 12 && (
            <div className="col-span-full">
              <PaddingRow padding={paddingTop} />
            </div>
          )}
          {virtualizer.getVirtualItems().map((virtualRow, i) => {
            const attachment = attachments[virtualRow.index];
            return (
              <ConstructionDetailsCard
                key={attachment.id}
                rootRoute={rootRoute}
                attachment={attachment}
                componentsById={componentsById}
              />
            );
          })}
          <div className="col-span-full">
            {pagination?.hasNext && (
              <div className="flex w-full items-center justify-center space-x-2">
                <Spinner spinnerSize="xs" variant="default" />
                <span className="text-sm">
                  {t("Loading more construction details...")}
                </span>
              </div>
            )}
            <div ref={ref} className="h-8 w-full" />
            {totalSize >= 12 && <PaddingRow padding={paddingBottom} />}
          </div>
        </div>
      )}
    </div>
  );
};
