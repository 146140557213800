import {
  ContactPermissionBaseCard,
  ContactPermissionCard,
} from "./ContactPermissionCard";
import { Control, UseFormSetValue, useFieldArray } from "react-hook-form";
import { FC, useMemo, useRef } from "react";
import {
  ResourceSharingPermissionTypes,
  sharePermissionsSecondaryOptions,
} from "./common";
import {
  customStyling,
  selectMenuOverflow,
  selectMenuOverflowProps,
  selectStylesControlOverride,
} from "@amenda-styles/customStyling";

import { AllowedContactType } from "@amenda-types";
import AsyncSelect from "react-select/async";
import { ContactCardWrapper } from "@amenda-components/Contacts/ContactCardWrapper";
import { DebounceTimes } from "@amenda-constants";
import { debounce } from "lodash";
import { getUserName } from "@amenda-components/Contacts/common";
import { useSearchUsers } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  ownerId: string;
  control: Control<any>;
  ignoreStylesOverride?: boolean;
  setValue: UseFormSetValue<any>;
}

const DropdownIndicator = () => {
  return null;
};

const ClearIndicator = () => {
  return null;
};

const IndicatorSeparator = () => {
  return null;
};

export const CollectionContactsShare: FC<Props> = ({
  id,
  ownerId,
  control,
  ignoreStylesOverride = false,
  setValue,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const { fields, update, remove } = useFieldArray({
    control,
    name: id,
    keyName: "arrId",
  });
  const { searchUsers, loading } = useSearchUsers();

  const debouncedSearch = useMemo(
    () =>
      debounce(async (searchTerm: string, resolve: (users: any[]) => void) => {
        await searchUsers({
          searchTerm,
          callback: resolve,
        });
      }, DebounceTimes.Search),
    [searchUsers],
  );

  const loadOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      if (inputValue.length > 1) {
        await debouncedSearch(inputValue, resolve);
      }
    });

  const onPermissionChange = (index: number, role: string) => {
    if (role === sharePermissionsSecondaryOptions[0].value) {
      remove(index);
    } else {
      update(index, {
        role,
        id: (fields[index] as any).id,
      });
    }
  };

  return (
    <div className="pb-6 pt-2" ref={ref}>
      <div className="w-full">
        <AsyncSelect
          cacheOptions
          isMulti={true}
          value={fields}
          onChange={(value) => {
            setValue(
              id,
              ((value as any[]) ?? []).map((u) => ({
                id: u.id,
                role: u.role ?? ResourceSharingPermissionTypes.View,
              })),
            );
          }}
          menuPlacement="auto"
          isClearable={false}
          backspaceRemovesValue={false}
          controlShouldRenderValue={false}
          placeholder={t("Search Contacts")}
          className={customStyling.select.containerClass}
          isLoading={loading}
          getOptionValue={(option: any) => option.id}
          getOptionLabel={(option: any) => getUserName(option)}
          loadOptions={loadOptions}
          noOptionsMessage={({ inputValue }) =>
            inputValue ? t("No results found") : t("Start typing")
          }
          components={{
            DropdownIndicator,
            ClearIndicator,
            IndicatorSeparator,
          }}
          styles={{
            ...customStyling.select.styleOverride,
            ...selectMenuOverflow(true, ref),
            ...(ignoreStylesOverride ? {} : selectStylesControlOverride),
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
          })}
          {...selectMenuOverflowProps(true)}
        />
      </div>
      <div className="pt-4">
        <ul className="flex flex-col space-y-1">
          <li>
            {ownerId && (
              <ContactCardWrapper
                userId={ownerId}
                contactType={AllowedContactType.office}
              >
                {(contact) => (
                  <ContactPermissionBaseCard disabled={true} contact={contact}>
                    <span className="text-xs text-gray-500">{t("Owner")}</span>
                  </ContactPermissionBaseCard>
                )}
              </ContactCardWrapper>
            )}
          </li>
          {fields.map((field: any, i) => {
            return (
              <li key={field.id}>
                <ContactCardWrapper
                  userId={field.id}
                  contactType={AllowedContactType.office}
                >
                  {(contact) => (
                    <ContactPermissionCard
                      contact={contact}
                      secondaryOptions={sharePermissionsSecondaryOptions}
                      permission={field.role}
                      handleChangePermission={(o) =>
                        onPermissionChange(i, o.value)
                      }
                    />
                  )}
                </ContactCardWrapper>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
