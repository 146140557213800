import { ChangeEvent, FC, useState } from "react";

import { Modal } from "@amenda-components/App";
import { ReadOnlyLabelledInputCard } from "./LabelledInput";
import { TableCellWrapper } from "@amenda-components/Shared/ReactTableComponents";
import { isEmpty } from "lodash";

interface Props {
  type?: string;
  values: {
    label: string;
    value: string;
  }[];
}

export const LabelledInputTableCell: FC<Props> = ({ type, values }) => {
  const [openModal, setOpenModal] = useState(false);

  if (isEmpty(values)) {
    return null;
  }

  const selectedValues = values.slice(0, 2);
  selectedValues.push({ value: "...", label: "More" });

  const handleClose = () => setOpenModal(false);
  const handleClick = (e: ChangeEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();

    setOpenModal(true);
  };

  return (
    <>
      <TableCellWrapper isText={true} onClick={handleClick}>
        {selectedValues.map(({ value }) => value).join(", ")}
      </TableCellWrapper>
      <Modal
        isOpen={openModal}
        isElevated={true}
        withCancel={false}
        closeModalFromTitle={true}
        size="md"
        title="View Details"
        contentClassName="pb-6"
        className="w-11/12 md:w-1/2 lg:w-4/12"
        onClose={handleClose}
      >
        <div className="flex flex-wrap">
          {values.map(({ label, value }) => (
            <div key={value} className="w-full px-1 pb-1">
              <ReadOnlyLabelledInputCard
                label={label}
                value={value}
                type={(type as any) || "email"}
              />
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};
