import { Map, getCenterCoordinates } from "@amenda-components/Maps";
import { useProjectStore, useTenantStore } from "@amenda-domains/mutations";

import { FC } from "react";
import { ProjectMarkers } from "./MapComponents";

interface Props {
  projects: any[];
}

export const ProjectMapView: FC<Props> = ({ projects }) => {
  const primaryTenant = useTenantStore((state) => state.primaryTenant);
  const selectedProject = useProjectStore((state) => state.selectedProject);

  return (
    <div className="h-[calc(100vh-80px)] w-full">
      <Map
        centerCoordinate={getCenterCoordinates(
          selectedProject?.address?.coordinates,
          primaryTenant?.coordinates,
        )}
      >
        <ProjectMarkers
          officeAddress={primaryTenant?.coordinates}
          projects={projects}
          showCentralPin={true}
        />
      </Map>
    </div>
  );
};
