import { AddComponentButton, SortItem } from "./DragAndDropWrapper";
import { GripIcon, PencilIcon } from "lucide-react";

import { FC } from "react";
import { GridWrapper } from "./GridWrapper";
import { IconButtonBase } from "@amenda-components/App";
import { LayoutProps } from "./common";
import clsx from "clsx";
import { isChildrenEmpty } from "@amenda-utils";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export const FullColumnGrid: FC<LayoutProps> = ({
  children,
  isPrintView,
  isFormBuilder,
  config,
}) => {
  const { t } = useTranslation();
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );

  const { label, description } = config?.properties ?? {};
  const handleEdit = () => {
    updateFormBuilderState("openEditComponentModal", true);
    updateFormBuilderState("selectedFormComponent", {
      config,
    });
  };

  return (
    <SortItem
      className={clsx("group/full-col-grid col-span-6", {
        "py-5": !isPrintView,
        "border-b border-gray-300 pb-4": isPrintView,
      })}
      config={config}
      isFormBuilder={isFormBuilder}
      showBorder={isChildrenEmpty(children)}
    >
      {isFormBuilder && (
        <div
          className={clsx("relative flex items-center space-x-2 pb-2", {
            "invisible group-hover/full-col-grid:visible":
              !isChildrenEmpty(children),
          })}
        >
          <IconButtonBase
            size="sm"
            className="cursor-grab px-0.5 py-1"
            variant="clean"
          >
            <GripIcon className="h-4 w-4 stroke-[1.5]" />
          </IconButtonBase>
          <IconButtonBase
            size="sm"
            className="p-1"
            variant="clean"
            onClick={handleEdit}
          >
            <PencilIcon className="h-4 w-4" />
          </IconButtonBase>
          <div>
            <AddComponentButton isFormBuilder={isFormBuilder} config={config} />
          </div>
        </div>
      )}
      {label && isPrintView && (
        <h3
          className={clsx({
            "amenda-form-heading": !isPrintView && !isFormBuilder,
            "text-sm": isFormBuilder,
            "text-lg": isPrintView,
          })}
        >
          {t(label)}
        </h3>
      )}
      {description && isPrintView && (
        <p
          className={clsx("mt-1 text-gray-600", {
            "text-sm": !isFormBuilder,
            "text-xs": isFormBuilder,
          })}
        >
          {t(description)}
        </p>
      )}
      <GridWrapper isFormBuilder={isFormBuilder} config={config}>
        {children}
      </GridWrapper>
    </SortItem>
  );
};
