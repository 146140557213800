import { FC, useEffect } from "react";
import {
  MoveAttachmentModal,
  ViewOrEditAttachment,
} from "@amenda-components/Gallery";

import { AllowedAttachmentType } from "@amenda-types";
import { FaceAPIProvider } from "@amenda-context";
import { useGetAttachment } from "@amenda-domains/queries";
import { useParams } from "react-router-dom";

export const AttachmentPage: FC = () => {
  const { getAttachment, loading } = useGetAttachment();
  const { attachmentId } = useParams<{
    attachmentId: string;
  }>();

  useEffect(() => {
    if (attachmentId) {
      getAttachment({
        id: attachmentId,
        type: AllowedAttachmentType.image,
        selectedAttachmentFields: { metadata: 1, url: 1, name: 1 },
      });
    }
  }, [attachmentId, getAttachment]);

  return (
    <FaceAPIProvider>
      <MoveAttachmentModal />
      <ViewOrEditAttachment loading={loading} />
    </FaceAPIProvider>
  );
};
