import {
  Avatar,
  ReadOnlyColoredSelect,
  Skeleton,
  Tooltip,
} from "@amenda-components/App";
import { formatDate, formatNumbers, isRestricted } from "@amenda-utils";
import { isArray, isNil, isString } from "lodash";

import { ColumnDef } from "@tanstack/react-table";
import { ComponentValidationType } from "@amenda-types";
import { FormComponentTypes } from "@amenda-constants";
import { LabelledInputTableCell } from "@amenda-components/FormComponents";
import { ReadOnlyKeywordsBase } from "@amenda-components/Shared/ReadOnlyKeywords";
import { ReadOnlyRegionalSelectBase } from "@amenda-components/Shared";
import { ReadOnlySearchAndSelectBase } from "@amenda-components/Shared/ReadOnlySearchAndSelect";
import { ReadOnlySearchAndSelectProjectsBase } from "@amenda-components/Projects";
import { TableCellWrapper } from "@amenda-components/Shared/ReactTableComponents";
import { getOptionsTree } from "@amenda-components/FormComponents/common";
import { getUserName } from "@amenda-components/Contacts/common";

const getTableCellValue = ({
  component,
  value,
  columnId,
}: {
  component: any;
  value: any;
  columnId: string;
}) => {
  const options: any[] = component?.properties?.options || [];
  const contactType = component?.properties?.contactType;
  const isNumeric =
    component?.validation?.type === ComponentValidationType.Number;

  switch (component?.component) {
    case FormComponentTypes.DatePicker:
      if (!value) return <span />;
      return (
        <TableCellWrapper isText={true}>{formatDate(value)}</TableCellWrapper>
      );
    case FormComponentTypes.DatePickerRange:
      if (!value) return <span />;
      return (
        <TableCellWrapper isText={true}>
          {[value?.to, value?.from]
            ?.filter(Boolean)
            ?.map((date) => formatDate(date))
            .join(" - ")}
        </TableCellWrapper>
      );
    case FormComponentTypes.ColoredSelect:
      if (!value) return <span />;
      return (
        <TableCellWrapper isText={true}>
          <ReadOnlyColoredSelect value={value} options={options} />
        </TableCellWrapper>
      );
    case FormComponentTypes.MultiSelect:
    case FormComponentTypes.Badges:
    case FormComponentTypes.Checkbox:
    case FormComponentTypes.Select:
    case FormComponentTypes.RadioButton:
      if (!value) return <span />;
      const values = Array.isArray(value) ? value : [value];
      const optionsTree = getOptionsTree(options);

      return (
        <TableCellWrapper isText={true}>
          {values
            .map((id) => optionsTree[id]?.option?.label)
            .filter(Boolean)
            .join(", ")}
        </TableCellWrapper>
      );
    case FormComponentTypes.Keyword:
      return (
        <TableCellWrapper isText={true}>
          <ReadOnlyKeywordsBase keywords={value}>
            {(loading, selectedKeywords) => {
              if (loading) {
                return <Skeleton height={4} />;
              }
              if (!selectedKeywords) return null;
              return <>{selectedKeywords.map(({ name }) => name).join(", ")}</>;
            }}
          </ReadOnlyKeywordsBase>
        </TableCellWrapper>
      );
    case FormComponentTypes.SearchAndSelect:
      if (!value) return <span />;

      const userIds = Array.isArray(value)
        ? value.map((id: any) => id?.contactId ?? id)
        : [value];

      return (
        <TableCellWrapper>
          <div className="flex w-full items-center -space-x-2 overflow-hidden">
            <ReadOnlySearchAndSelectBase
              userIds={userIds}
              contactType={contactType}
            >
              {(users) => (
                <>
                  {users.map((user) => (
                    <Tooltip
                      key={user.id}
                      position="top"
                      positionStrategy="fixed"
                      id={`userName.${columnId}.${user.id}`}
                      message={getUserName(user)}
                      className="h-7 min-h-7 w-7 min-w-7 transition duration-150 ease-in-out hover:z-30 hover:h-8 hover:min-h-8 hover:w-8 hover:min-w-8"
                    >
                      <Avatar
                        name={getUserName(user)}
                        src={user?.photoURL}
                        className="h-full w-full hover:shadow-lg"
                        iconClassName="h-6 w-6"
                      />
                    </Tooltip>
                  ))}
                </>
              )}
            </ReadOnlySearchAndSelectBase>
          </div>
        </TableCellWrapper>
      );
    case FormComponentTypes.Switch:
      return (
        <TableCellWrapper isText={true}>
          {Boolean(value) ? "Ja" : "Nein"}
        </TableCellWrapper>
      );
    case FormComponentTypes.SearchAndSelectProjects:
      return (
        <TableCellWrapper isText={true}>
          <ReadOnlySearchAndSelectProjectsBase projectIds={value}>
            {(projects) => {
              return projects.map((project) => project?.name).join(", ");
            }}
          </ReadOnlySearchAndSelectProjectsBase>
        </TableCellWrapper>
      );
    case FormComponentTypes.RegionalSelect:
      return (
        <TableCellWrapper isText={true}>
          <ReadOnlyRegionalSelectBase regionId={value}>
            {(region) => {
              return region?.city;
            }}
          </ReadOnlyRegionalSelectBase>
        </TableCellWrapper>
      );
    case FormComponentTypes.AddressSearch:
      return <TableCellWrapper isText={true}>{value?.name}</TableCellWrapper>;
    case FormComponentTypes.LabelledInput:
      if (!value || isString(value))
        return <TableCellWrapper isText={true}>{value}</TableCellWrapper>;
      return (
        <LabelledInputTableCell
          values={value}
          type={component?.properties?.type}
        />
      );
    case FormComponentTypes.Input:
    case FormComponentTypes.Textarea:
      const updatedValue =
        value && isFinite(value) ? formatNumbers(value) : value;

      return (
        <TableCellWrapper isNumeric={isNumeric} isText={true}>
          {updatedValue}
        </TableCellWrapper>
      );
    default: {
      if (isArray(value)) {
        return <span />;
      }
      return <TableCellWrapper>{value}</TableCellWrapper>;
    }
  }
};

interface Props {
  dynamicColumnIds: string[];
  componentsById: Record<string, any>;
  permissions: Record<string, any>;
  flattenData?: (data?: any) => any;
  filterComponents?: (component: any) => boolean;
}

interface ResolveCellValuesArgs extends Pick<Props, "flattenData"> {
  columnId: string;
  component: any;
}

const resolveCellValues = ({
  columnId,
  component,
  flattenData,
}: ResolveCellValuesArgs) => {
  const label =
    component?.properties?.label || component?.properties?.readOnlyLabel;

  let column: ColumnDef<any> = {
    minSize: 240,
    header: label,
    id: columnId,
    meta: {
      label,
      formId: component?.formId,
    },
    cell: ({ row }) => {
      const data = flattenData ? flattenData(row?.original) : row?.original;

      return (
        <>
          {getTableCellValue({ component, value: data?.[columnId], columnId })}
        </>
      );
    },
  };

  if (component.component === FormComponentTypes.AddressSearch) {
    column = {
      ...column,
      meta: {
        ...column.meta,
        sortBy: `${columnId}.name`,
      },
      accessorFn: (row) => row?.[columnId]?.name,
    };
  } else if (component.component === FormComponentTypes.RegionalSelect) {
    column = {
      ...column,
      meta: {
        ...column.meta,
        sortBy: `${columnId}.city`,
      },
      accessorFn: (row) => row?.[columnId],
    };
  } else if (component.component === FormComponentTypes.DatePicker) {
    column = {
      ...column,
      accessorFn: (row) => row?.[columnId],
    };
  } else if (component.component === FormComponentTypes.Input) {
    const isNumber =
      component.validation.type === ComponentValidationType.Number;

    column = {
      ...column,
      meta: {
        ...column.meta,
        isNumber,
        useCaseInsensitiveSort: !isNumber,
      },
      accessorFn: (row) => row?.[columnId],
    };
  } else if (
    [
      FormComponentTypes.Select,
      FormComponentTypes.RadioButton,
      FormComponentTypes.ColoredSelect,
    ].includes(component.component)
  ) {
    column = {
      ...column,
      meta: {
        ...column.meta,
        useCaseInsensitiveSort: true,
      },
      accessorFn: (row) => row?.[columnId],
    };
  }
  return column;
};

const getColumns = (
  dynamicColumnIds: string[],
  componentsById: Record<string, any>,
) => {
  return dynamicColumnIds
    .map((columnId) => ({
      columnId,
      component: componentsById[columnId],
    }))
    .filter(({ component }) => !isNil(component));
};

export const dynamicTableColumns = ({
  dynamicColumnIds,
  componentsById,
  permissions,
  flattenData,
  filterComponents = (c) => true,
}: Props): ColumnDef<any>[] => {
  return getColumns(dynamicColumnIds, componentsById)
    .filter(({ component }) => !isRestricted(component, permissions))
    .filter(
      ({ component }) =>
        ![
          FormComponentTypes.Save,
          FormComponentTypes.Image,
          FormComponentTypes.Hidden,
          FormComponentTypes.Title,
          FormComponentTypes.MaterialPhoto,
          FormComponentTypes.SelectCostGroups,
          FormComponentTypes.MaterialCostInput,
          FormComponentTypes.MaterialPercentageValues,
        ].includes(component.component),
    )
    .filter(({ component }) => filterComponents(component))
    .map((columns) => {
      return resolveCellValues({
        flattenData,
        ...columns,
      });
    });
};

export const dynamicTableColumnsNoPermissions = ({
  dynamicColumnIds,
  componentsById,
  flattenData,
  filterComponents = (c) => true,
}: Omit<Props, "permissions">): ColumnDef<any>[] => {
  return getColumns(dynamicColumnIds, componentsById)
    .filter(({ component }) => filterComponents(component))
    .map((columns) => {
      return resolveCellValues({
        flattenData,
        ...columns,
      });
    });
};
