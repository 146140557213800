import { PaginationLimit, SearchCollections } from "@amenda-constants";
import { gql, useClient } from "urql";
import { useCallback, useState } from "react";

import { PaginationQueryProps } from "@amenda-types";
import { SYSTEM_ROLE_FRAGMENT } from "@amenda-domains/fragments/settings";
import { useSettingsStore } from "@amenda-domains/mutations";

export const GET_SYSTEM_ROLE = gql`
  ${SYSTEM_ROLE_FRAGMENT}
  query GetSystemRole($id: ID, $usersIds: [ID!]) {
    getSystemRole(_id: $id, usersIds: $usersIds) {
      ...UserRolesFragment
      permissions
    }
  }
`;

export const GET_ALL_SYSTEM_ROLES = gql`
  ${SYSTEM_ROLE_FRAGMENT}
  query GetAllSystemRoles(
    $limit: Int
    $name: String
    $next: String
    $previous: String
    $description: String
    $permissions: Object
    $usersIds: [ID!]
  ) {
    getAllSystemRoles(
      next: $next
      name: $name
      limit: $limit
      previous: $previous
      usersIds: $usersIds
      description: $description
      permissions: $permissions
    ) {
      next
      previous
      hasNext
      hasPrevious
      docsCount
      roles {
        ...UserRolesFragment
      }
    }
  }
`;

interface GetAllSystemRolesArgs extends PaginationQueryProps {
  name?: string;
  usersIds?: string[];
  pageLimit?: number;
  context?: Record<string, any>;
  callback?: (roles: any[]) => void;
}

export const useGetAllSystemRoles = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setSystemRoles = useSettingsStore((state) => state.setSystemRoles);

  const getAllSystemRoles = useCallback(
    async (args: GetAllSystemRolesArgs = {}) => {
      const {
        usersIds,
        callback,
        context = {},
        pageLimit = PaginationLimit.systemRoles,
        ...rest
      } = args;
      const hasUserIds = usersIds && usersIds.length > 0;
      const limit = hasUserIds ? usersIds.length : pageLimit;
      const variables: any = {
        limit,
        ...rest,
      };
      if (hasUserIds) {
        variables.usersIds = usersIds;
      }

      setLoading(true);
      const { data } = await client.query(
        GET_ALL_SYSTEM_ROLES,
        variables,
        context,
      );
      if (data?.getAllSystemRoles) {
        callback
          ? callback(data.getAllSystemRoles?.roles ?? [])
          : setSystemRoles(data.getAllSystemRoles);
      }
      setLoading(false);
    },
    [client, setSystemRoles],
  );

  return { loading, getAllSystemRoles };
};

interface GetSystemRoleArgs {
  id?: string;
  usersIds?: string[];
  name?: string;
  context?: Record<string, any>;
  callback?: (systemRole: any) => void;
}

export const useGetSystemRole = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setSelectedSystemRole = useSettingsStore(
    (state) => state.setSelectedSystemRole,
  );

  const getSystemRole = useCallback(
    async ({ callback, context = {}, ...variables }: GetSystemRoleArgs) => {
      setLoading(true);
      const { data } = await client.query(GET_SYSTEM_ROLE, variables, context);
      if (data?.getSystemRole) {
        callback
          ? callback(data.getSystemRole)
          : setSelectedSystemRole(data.getSystemRole);
      }
      setLoading(false);
    },
    [client, setSelectedSystemRole],
  );

  return { loading, getSystemRole };
};

const SEARCH_SYSTEM_ROLES = gql`
  ${SYSTEM_ROLE_FRAGMENT}
  query Search(
    $limit: Int
    $searchTerm: String
    $collections: [String!]
    $filters: Object
    $aggregations: Object
  ) {
    search(
      input: {
        limit: $limit
        filters: $filters
        searchTerm: $searchTerm
        collections: $collections
        aggregations: $aggregations
      }
    ) {
      system_roles {
        ...UserRolesFragment
      }
    }
  }
`;

export const useSearchSystemRoles = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setSystemRoles = useSettingsStore((state) => state.setSystemRoles);

  const searchSystemRoles = useCallback(
    async (searchTerm: string, callback?: (roles: any[]) => void) => {
      setLoading(true);
      const allAggregations = [
        {
          $search: {
            index: "default",
            compound: {
              should: [
                {
                  autocomplete: {
                    query: searchTerm,
                    path: "name",
                  },
                },
              ],
            },
          },
        },
        {
          $addFields: {
            score: {
              $meta: "searchScore",
            },
          },
        },
        {
          $setWindowFields: {
            output: {
              maxScore: {
                $max: "$score",
              },
            },
          },
        },
        {
          $addFields: {
            normalizedScore: {
              $divide: ["$score", "$maxScore"],
            },
          },
        },
      ];

      const { data } = await client.query(
        SEARCH_SYSTEM_ROLES,
        {
          collections: [SearchCollections.SystemRoles],
          aggregations: {
            [SearchCollections.SystemRoles]: allAggregations,
          },
        },
        {
          requestPolicy: "network-only",
        },
      );

      const roles = data?.search.system_roles ?? [];
      callback
        ? callback(roles)
        : setSystemRoles({
            roles,
          });

      setLoading(false);
    },
    [client, setSystemRoles],
  );

  return { loading, searchSystemRoles };
};
