import { FC, useEffect, useState } from "react";
import { Image, LoaderWrapper } from "@amenda-components/App";
import { useAttachmentStore, useProjectStore } from "@amenda-domains/mutations";

import { AppRoutes } from "@amenda-types";
import { SliderView } from "@amenda-components/FileUploads";
import { getSliderAttachments } from "@amenda-components/Gallery/common";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { useSearchAttachments } from "@amenda-domains/queries";

export const ProjectAttachmentSliderView: FC = () => {
  const navigate = useNavigate();
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const [attachments, setAttachments] = useState<any[]>([]);
  const { searchAttachments } = useSearchAttachments();
  const setGalleryAttachments = useAttachmentStore(
    (state) => state.setAttachments,
  );
  const setSelectedAttachment = useAttachmentStore(
    (state) => state.setSelectedAttachment,
  );

  const sliderAttachments = getSliderAttachments({
    attachments,
    selectedAttachments: [],
    galleryUrl: selectedProject?.galleryUrl,
  });

  const handleClick = (file: any) => {
    const path = `${AppRoutes.Attachments}/${file.id}`;

    setGalleryAttachments({ attachments });
    setSelectedAttachment(file);
    navigate(path);
  };

  useEffect(() => {
    const searchAttachmentAsync = async () => {
      if (selectedProject?.id) {
        await searchAttachments({
          filters: {
            "formValues.project": selectedProject.id,
          },
          callback: setAttachments,
        });
      }
    };

    searchAttachmentAsync();

    return () => {
      setAttachments([]);
    };
  }, [searchAttachments, selectedProject?.id]);

  if (isEmpty(sliderAttachments)) {
    return (
      <Image
        url=""
        size="pb-16/17"
        enableHighlighting={false}
        magicSize="galleryCropped"
      />
    );
  }
  return <SliderView files={sliderAttachments} onClick={handleClick} />;
};

export const PrintProjectAttachmentsView: FC = () => {
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const [attachments, setAttachments] = useState<any[]>([]);
  const { searchAttachments, loading } = useSearchAttachments();

  useEffect(() => {
    const searchAttachmentAsync = async () => {
      if (selectedProject?.id) {
        await searchAttachments({
          filters: {
            "formValues.project": selectedProject.id,
          },
          callback: setAttachments,
        });
      }
    };

    searchAttachmentAsync();

    return () => {
      setAttachments([]);
    };
  }, [searchAttachments, selectedProject?.id]);

  if (loading) {
    return <LoaderWrapper className="h-32" />;
  }
  if (isEmpty(attachments)) {
    return null;
  }
  return (
    <div className="columns-3 gap-2">
      {attachments.slice(0, 6).map((a) => {
        return (
          <Image
            key={a.id}
            url={a.url}
            size="h-60"
            enableHighlighting={false}
            magicSize="gallery"
            className="mb-2"
          />
        );
      })}
    </div>
  );
};
