import {
  ComponentTreeRendererWrapper,
  FormAutoSaveResourceProps,
  FormAutoSaveSubmitProps,
  PerformantFormAutoSaveBase,
} from "@amenda-components/PageBuilder";
import { FC, memo } from "react";

import { FormComponentProps } from "@amenda-types";
import { ObjectSchema } from "yup";

interface Props extends FormAutoSaveResourceProps {
  components: any[];
  className?: string;
  inputSchema: ObjectSchema<any>;
  customFormComponents?: Record<string, FC<FormComponentProps>>;
  values: Record<string, any>;
  globalProps?: Record<string, any>;
  onSubmit: (props: FormAutoSaveSubmitProps) => Promise<void>;
  processComponents: (components: any[]) => any[];
}

// more performant version of FormAutoSaveWrapper
export const PerfFormAutoSaveWrapper: FC<Props> = memo(
  ({
    components,
    globalProps = {},
    customFormComponents = {},
    processComponents,
    ...rest
  }) => {
    return (
      <PerformantFormAutoSaveBase {...rest}>
        {({ control, reset }) => (
          <ComponentTreeRendererWrapper
            reset={reset}
            control={control}
            components={components}
            globalProps={globalProps}
            customFormComponents={customFormComponents}
            processFormPermissions={processComponents}
          />
        )}
      </PerformantFormAutoSaveBase>
    );
  },
  (prevProps, nextProps) => {
    if (
      JSON.stringify(prevProps.components) !==
      JSON.stringify(nextProps.components)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.globalProps) !==
      JSON.stringify(nextProps.globalProps)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.values) !== JSON.stringify(nextProps.values)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.resourceIds) !==
      JSON.stringify(nextProps.resourceIds)
    ) {
      return false;
    } else if (prevProps.resourceId !== nextProps.resourceId) {
      return false;
    } else {
      return true;
    }
  },
);
