import { FC, ReactNode } from "react";

import { Layout } from "@amenda-components/App";

interface Props {
  children: ReactNode;
  hideSidebar?: boolean;
  isPrintView?: boolean;
}

export const PrivateRouteWrapper: FC<Props> = ({ children, ...props }) => {
  return <Layout {...props}>{children}</Layout>;
};
