import {
  Control,
  Controller,
  SubmitHandler,
  UseFormSetValue,
  useForm,
} from "react-hook-form";
import { FC, ReactNode } from "react";

import { FormTab } from "@amenda-types";
import { LoaderWrapper } from "@amenda-components/App";
import { TextField } from "@amenda-components/FormComponents";
import { getPermissionsDefaultValues } from "../common";
import { isEmpty } from "lodash";
import { roleInputSchema } from "@amenda-constants";
import { sanitizeData } from "@amenda-utils";
import { useSettingsStore } from "@amenda-domains/mutations";
import { yupResolver } from "@hookform/resolvers/yup";

interface RoleInput {
  name: string;
  description: string;
  permissions: Record<string, any>;
}

interface Props {
  roleId?: string;
  formId: string;
  formsByType: Record<string, FormTab[]>;
  children: (props: {
    setValue: UseFormSetValue<any>;
    control: Control<any, object>;
  }) => ReactNode;
  handleSystemRole: (role: any) => Promise<any>;
  isLoading: boolean;
}

export const RolesFormWrapper: FC<Props> = ({
  roleId,
  formId,
  children,
  isLoading,
  formsByType,
  handleSystemRole,
}) => {
  const values = useSettingsStore((state) => state.systemRoleForm);
  const selectedSystemRole = useSettingsStore(
    (state) => state.selectedSystemRole,
  );
  const defaultValues = getPermissionsDefaultValues(formsByType);
  const { control, setValue, handleSubmit } = useForm<RoleInput>({
    resolver: yupResolver(roleInputSchema),
    values,
    defaultValues,
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });

  const onSubmit: SubmitHandler<RoleInput> = async ({
    permissions = {},
    ...rest
  }) => {
    const filledPermissions: Record<string, any> = {};
    Object.keys(permissions).forEach((key) => {
      const values = sanitizeData(permissions[key]);
      if (!isEmpty(values)) {
        filledPermissions[key] = values;
      }
    });
    const input: RoleInput & {
      _id?: string;
    } = {
      ...rest,
      permissions: filledPermissions,
    };
    if (roleId) {
      input._id = roleId;
    }

    handleSystemRole({
      input,
    });
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <div className="pt-4 lg:pt-6">
        <div className="grid grid-cols-3 gap-4 pb-6">
          <div>
            <Controller
              name="name"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="name"
                    label="Role Name"
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                    readOnly={Boolean(selectedSystemRole?.isDefaultRole)}
                  />
                );
              }}
            />
          </div>
          <div className="col-span-2">
            <Controller
              name="description"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="description"
                    label="Short Description"
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                    readOnly={Boolean(selectedSystemRole?.isDefaultRole)}
                  />
                );
              }}
            />
          </div>
        </div>
        {isLoading && <LoaderWrapper />}
        {!isLoading && children({ control, setValue })}
      </div>
    </form>
  );
};
