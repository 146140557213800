import { FC, ReactNode } from "react";

import { Helmet } from "react-helmet-async";
import { MaxWidthLayout } from "@amenda-components/Shared";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  children: ReactNode;
  className?: string;
  wrapperClassName?: string;
}

export const ProjectLayout: FC<Props> = ({
  children,
  title,
  className,
  wrapperClassName,
}) => {
  const { t } = useTranslation();

  return (
    <MaxWidthLayout
      className={twMerge(className)}
      wrapperClassName={wrapperClassName}
    >
      <Helmet title={t(title)} />
      <div className="flex flex-col">{children}</div>
    </MaxWidthLayout>
  );
};
