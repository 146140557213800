import {
  getNonHeaderComponents,
  groupComponentsByParent,
  processDisplayComponents,
} from "@amenda-utils";

import { ComponentTreeRenderer } from "@amenda-components/PageBuilder";
import { FC } from "react";
import { LoaderWrapper } from "@amenda-components/App";
import { PageComponentProps } from "@amenda-types";
import { processFormPermissions } from "@amenda-components/Shared/common";
import { transformProjectToForm } from "./common";
import { useProjectStore } from "@amenda-domains/mutations";
import { useSettingsStore } from "@amenda-domains/mutations/settings";

interface Props {
  components: PageComponentProps[];
}

const ReadOnlyProjectForm: FC<Props> = ({ components }) => {
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  const data = transformProjectToForm(selectedProject);
  const pageComponents = getNonHeaderComponents(components);
  const availableComponents = processDisplayComponents(data, pageComponents);
  const componentTree = groupComponentsByParent(
    availableComponents,
    processFormPermissions(permissions),
  );
  const formValues = selectedProject?.formValues || {};

  return (
    <div>
      <ComponentTreeRenderer
        config={componentTree}
        globalProps={{
          data: formValues,
        }}
        readOnly={true}
      />
    </div>
  );
};

export const ReadOnlyProjectFormWrapper: FC<Props> = ({ components }) => {
  const isFetchingProject = useProjectStore((state) => state.isFetchingProject);

  if (isFetchingProject) {
    return <LoaderWrapper />;
  }
  return <ReadOnlyProjectForm components={components} />;
};
