import {
  ColumnDef,
  Row,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ConfigurationDropdown,
  DownloadColumnsModal,
  TableCellWrapper,
  TableGrouping,
  TablePinColumn,
  TableRowSelector,
  TableSortIndicators,
} from "./ReactTableComponents";
import {
  FC,
  ReactNode,
  RefObject,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { HelperMessage, IconButtonBase } from "@amenda-components/App";
import { Pagination, ReactTableKeys } from "@amenda-types";
import { SearchIcon, Table2Icon } from "lucide-react";
import {
  SpecialColumns,
  getCellStickyPosition,
  getDefaultPinnedColumns,
  getHeaderStickyPosition,
  getTableSize,
  isSelectColumn,
} from "./reactTableHelpers";
import {
  VirtualItem,
  Virtualizer,
  useVirtualizer,
} from "@tanstack/react-virtual";

import { MiniSearchField } from "@amenda-components/SearchComponents";
import clsx from "clsx";
import { inputFieldTheme } from "@amenda-styles/theme";
import { isEmpty } from "lodash";
import { useAppStore } from "@amenda-domains/mutations";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

interface Props {
  label?: string;
  tableId: ReactTableKeys;
  data: any[];
  columns: ColumnDef<any>[];
  isLoading?: boolean;
  pagination?: Pagination;
  containerClass?: string;
  showSelectorColumn?: boolean;
  showGroupingColumn?: boolean;
  showDefaultColumn?: boolean;
  canDownload?: boolean;
  isConfigurable?: boolean;
  isResizable?: boolean;
  isFullWidth?: boolean;
  maxEstimatedRowHeight?: number;
  hideActionColumn?: boolean;
  TableRowActions?: FC<{
    row: Row<any>;
  }>;
  EmptyStateWidget?: ReactNode;
  selectedRowIds?: string[];
  tableConfigChildren?: ReactNode;
  defaultPinnedColumns?: { left?: string[]; right?: string[] };
  groupingColumnChildren?: (row: any) => ReactNode;
  fetchNextPage?: () => Promise<void>;
  handleFilter?: (columnSorting: any) => void;
  handleSearch?: (searchText: string) => Promise<void>;
  onRowClick?: (row: Row<any>) => (e: any) => void;
  handleToggleRowSelection?: (id: string) => void;
  handleToggleRowsSelection?: (ids: string[]) => void;
}

const getAvailableColumns = ({
  tableId,
  columns,
  hideActionColumn,
  showGroupingColumn,
  showSelectorColumn,
  TableRowActions,
  groupingColumnChildren,
  handleToggleRowSelection,
  handleToggleRowsSelection,
}: Pick<
  Props,
  | "tableId"
  | "showGroupingColumn"
  | "columns"
  | "hideActionColumn"
  | "showSelectorColumn"
  | "TableRowActions"
  | "groupingColumnChildren"
  | "handleToggleRowSelection"
  | "handleToggleRowsSelection"
>) => {
  let availableColumns = columns;
  if (showGroupingColumn) {
    availableColumns = [
      {
        id: SpecialColumns.GROUPING,
        header: () => <span />,
        cell: ({ row }) => (
          <TableCellWrapper>
            <TableGrouping row={row}>
              {groupingColumnChildren?.(row)}
            </TableGrouping>
          </TableCellWrapper>
        ),
      },
      ...availableColumns,
    ];
  }
  if (showSelectorColumn) {
    availableColumns = [
      {
        size: 50,
        id: SpecialColumns.SELECT,
        cell: ({ row }) => (
          <div className="h-full flex-col content-center pl-2.5">
            <TableRowSelector
              row={row}
              tableId={tableId}
              handleToggleRowSelection={handleToggleRowSelection}
            />
          </div>
        ),
        header: ({ table }) => (
          <TableRowSelector
            table={table}
            tableId={tableId}
            handleToggleRowsSelection={handleToggleRowsSelection}
          />
        ),
      },
      ...availableColumns,
    ];
  }
  if (TableRowActions && !hideActionColumn) {
    availableColumns = [
      ...availableColumns,
      {
        minSize: 25,
        id: SpecialColumns.ACTIONS,
        header: () => <span />,
        cell: ({ row }) => (
          <TableCellWrapper>
            <TableRowActions row={row.original} />
          </TableCellWrapper>
        ),
      },
    ];
  }
  return availableColumns;
};

const generateRowSelection = (data: any[], selectedRowIds?: string[]) => {
  const rowSelection: Record<number, boolean> = {};

  if (selectedRowIds) {
    selectedRowIds.forEach((id) => {
      const index = data.findIndex((d) => d.id === id);

      if (index > -1) {
        rowSelection[index] = true;
      }
    });
  }

  return rowSelection;
};

interface TableRowProps extends Pick<Props, "onRowClick"> {
  row: Row<any>;
  isResizable: boolean;
  virtualRow: VirtualItem;
  tableRef: RefObject<HTMLTableSectionElement>;
  maxEstimatedRowHeight: number;
}

const TableRow: FC<TableRowProps> = ({
  row,
  tableRef,
  virtualRow,
  isResizable,
  maxEstimatedRowHeight,
  onRowClick,
}) => {
  const cells = row.getVisibleCells();

  const cellVirtualizer = useVirtualizer({
    overscan: 5,
    horizontal: true,
    count: cells.length,
    getScrollElement: () => tableRef.current,
    estimateSize: (i: number) => cells[i]?.column?.getSize(),
  });
  const virtualCells = cellVirtualizer.getVirtualItems();

  return (
    <tr
      key={row.id}
      className="group/tr absolute flex w-full border-b border-gray-200"
      style={{
        transform: `translateY(${virtualRow.start}px)`,
      }}
    >
      {virtualCells.map((virtualCell) => {
        const cell = cells[virtualCell.index];

        if (!cell?.column) {
          return <td />;
        }
        return (
          <td
            key={cell.id}
            style={{
              height: maxEstimatedRowHeight - 2,
              ...getCellStickyPosition(cells, virtualCell.index),
              ...([SpecialColumns.SELECT, SpecialColumns.ACTIONS].includes(
                cell.column.id as SpecialColumns,
              )
                ? {
                    width: cell.column.getSize(),
                    minWidth: cell.column.getSize(),
                  }
                : isResizable
                  ? {
                      width: cell.column.getSize(),
                    }
                  : {
                      width: "100%",
                      minWidth: cell.column.getSize(),
                    }),
            }}
            onClick={
              ![
                SpecialColumns.SELECT,
                SpecialColumns.ACTIONS,
                SpecialColumns.GROUPING,
              ].includes(cell.column.id as SpecialColumns) && onRowClick
                ? onRowClick(cell.row.original)
                : undefined
            }
            className={clsx(
              "flex border-r border-gray-200 bg-white text-sm group-hover/tr:bg-gray-50",
              {
                "sticky z-20": cell.column.getIsPinned(),
                "cursor-pointer":
                  Boolean(onRowClick) &&
                  ![
                    SpecialColumns.SELECT,
                    SpecialColumns.ACTIONS,
                    SpecialColumns.GROUPING,
                  ].includes(cell.column.id as SpecialColumns),
              },
            )}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        );
      })}
    </tr>
  );
};

type RefTableRowProps = TableRowProps &
  Pick<Props, "pagination" | "isLoading" | "fetchNextPage">;

const RefTableRow: FC<RefTableRowProps> = ({
  row,
  tableRef,
  isLoading,
  pagination,
  virtualRow,
  isResizable,
  maxEstimatedRowHeight,
  onRowClick,
  fetchNextPage,
}) => {
  const { ref } = useInView({
    onChange(inView, entry) {
      if (inView && !isLoading && pagination?.hasNext && fetchNextPage) {
        fetchNextPage();
      }
    },
  });
  const cells = row.getVisibleCells();

  const cellVirtualizer = useVirtualizer({
    overscan: 5,
    horizontal: true,
    count: cells.length,
    getScrollElement: () => tableRef.current,
    estimateSize: (i: number) => cells[i]?.column?.getSize(),
  });
  const virtualCells = cellVirtualizer.getVirtualItems();

  return (
    <tr
      ref={ref}
      key={row.id}
      className="group/tr absolute flex w-full border-b border-gray-200"
      style={{
        transform: `translateY(${virtualRow.start}px)`,
      }}
    >
      {virtualCells.map((virtualCell) => {
        const cell = cells[virtualCell.index];

        if (!cell?.column) {
          return <td />;
        }
        return (
          <td
            key={cell.id}
            style={{
              height: maxEstimatedRowHeight - 2,
              ...getCellStickyPosition(cells, virtualCell.index),
              ...([SpecialColumns.SELECT, SpecialColumns.ACTIONS].includes(
                cell.column.id as SpecialColumns,
              )
                ? {
                    width: cell.column.getSize(),
                    minWidth: cell.column.getSize(),
                  }
                : isResizable
                  ? {
                      width: cell.column.getSize(),
                    }
                  : {
                      width: "100%",
                      minWidth: cell.column.getSize(),
                    }),
            }}
            onClick={
              ![
                SpecialColumns.SELECT,
                SpecialColumns.ACTIONS,
                SpecialColumns.GROUPING,
              ].includes(cell.column.id as SpecialColumns) && onRowClick
                ? onRowClick(cell.row.original)
                : undefined
            }
            className={clsx(
              "flex border-r border-gray-200 bg-white text-sm group-hover/tr:bg-gray-50",
              {
                "sticky z-20": cell.column.getIsPinned(),
                "cursor-pointer":
                  Boolean(onRowClick) &&
                  ![
                    SpecialColumns.SELECT,
                    SpecialColumns.ACTIONS,
                    SpecialColumns.GROUPING,
                  ].includes(cell.column.id as SpecialColumns),
              },
            )}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        );
      })}
    </tr>
  );
};

interface TableLoaderProps
  extends Pick<Props, "data" | "isLoading" | "pagination"> {
  headers: any[];
  searchTerm: string;
  rowHeight: number;
  virtualizer: Virtualizer<any, any>;
}

interface TableLoaderRowProps
  extends Pick<TableLoaderProps, "headers" | "rowHeight"> {
  yPos: number;
}

const TableLoaderRow: FC<TableLoaderRowProps> = ({
  yPos,
  headers,
  rowHeight,
}) => {
  return (
    <tr
      className="absolute flex w-full"
      style={{
        transform: `translateY(${yPos}px)`,
      }}
    >
      {headers.map((header, i) => {
        return (
          <td
            key={`${header.id}_${i}`}
            className={clsx(
              "flex items-center justify-center border-b border-r border-gray-200 bg-white",
              {
                "sticky z-20": header.column.getIsPinned(),
              },
            )}
            style={{
              width: "100%",
              height: rowHeight,
              minWidth: header.getSize(),
              ...getHeaderStickyPosition(headers, i),
            }}
          >
            <div className="h-3 w-2/3 animate-pulse rounded-md bg-gray-100" />
          </td>
        );
      })}
    </tr>
  );
};

const TableLoader: FC<TableLoaderProps> = ({
  data,
  headers,
  isLoading,
  rowHeight,
  searchTerm,
  pagination,
  virtualizer,
}) => {
  const virtualRows = virtualizer.getVirtualItems();
  const isScrolling = virtualizer.isScrolling;
  const scrollDirection = virtualizer.scrollDirection;

  const count = virtualRows.length > 10 ? virtualRows.length : 20;
  let rows = Array.from({ length: count });
  const firstRow = virtualRows[0];
  const lastRow = virtualRows[virtualRows.length - 1];

  if (
    isLoading &&
    (isEmpty(data) ||
      (!Boolean(pagination?.hasNext) && !Boolean(pagination?.hasPrevious)))
  ) {
    return (
      <>
        {rows.map((_, index) => {
          const yPos = index * rowHeight;

          return (
            <TableLoaderRow
              key={index}
              yPos={yPos}
              headers={headers}
              rowHeight={rowHeight}
            />
          );
        })}
      </>
    );
  } else if (isScrolling && scrollDirection === "backward") {
    return (
      <>
        {rows.map((_, index) => {
          const yPos = firstRow.start - (index + 1) * rowHeight;

          return (
            <TableLoaderRow
              key={index}
              yPos={yPos}
              headers={headers}
              rowHeight={rowHeight}
            />
          );
        })}
      </>
    );
  } else if (isScrolling && scrollDirection === "forward") {
    if (lastRow && lastRow.index === data.length - 5) {
      rows = Array.from({ length: 5 });
    }
    return (
      <>
        {rows.map((_, index) => {
          const yPos = lastRow?.end + index * rowHeight;

          return (
            <TableLoaderRow
              key={index}
              yPos={yPos}
              headers={headers}
              rowHeight={rowHeight}
            />
          );
        })}
      </>
    );
  } else if (lastRow?.end && pagination?.hasNext && !searchTerm) {
    return (
      <>
        {rows.map((_, index) => {
          const yPos = lastRow.end + index * rowHeight;

          return (
            <TableLoaderRow
              key={index}
              yPos={yPos}
              headers={headers}
              rowHeight={rowHeight}
            />
          );
        })}
      </>
    );
  }
  return null;
};

export const ReactTable: FC<Props> = memo(
  ({
    data,
    label,
    tableId,
    columns,
    isLoading,
    TableRowActions,
    isConfigurable,
    defaultPinnedColumns,
    tableConfigChildren,
    pagination,
    selectedRowIds,
    EmptyStateWidget,
    isResizable = false,
    showDefaultColumn = false,
    hideActionColumn = false,
    isFullWidth = false,
    showGroupingColumn = false,
    showSelectorColumn = false,
    maxEstimatedRowHeight = 34,
    containerClass = "max-h-96 w-fit",
    handleFilter,
    handleSearch,
    fetchNextPage,
    onRowClick,
    groupingColumnChildren,
    handleToggleRowSelection,
    handleToggleRowsSelection,
  }) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLTableSectionElement>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [isMounted, setIsMounted] = useState(false);
    const [expanded, setExpanded] = useState({});
    const [showSearch, setShowSearch] = useState(false);
    const tableState = useAppStore((state) => state.tableState[tableId]);
    const pinnedColumns = useMemo(
      () =>
        getDefaultPinnedColumns({
          showGroupingColumn,
          defaultPinnedColumns,
          showSelectorColumn,
          showUserActionColumn: Boolean(TableRowActions),
        }),
      [
        showSelectorColumn,
        showGroupingColumn,
        defaultPinnedColumns,
        TableRowActions,
      ],
    );
    const memoizedColumns = useMemo(
      () =>
        getAvailableColumns({
          tableId,
          columns,
          hideActionColumn,
          showGroupingColumn,
          showSelectorColumn,
          groupingColumnChildren,
          TableRowActions,
          handleToggleRowSelection,
          handleToggleRowsSelection,
        }),
      [
        tableId,
        columns,
        hideActionColumn,
        showGroupingColumn,
        showSelectorColumn,
        groupingColumnChildren,
        TableRowActions,
        handleToggleRowSelection,
        handleToggleRowsSelection,
      ],
    );
    const memotizedData = useMemo(() => data, [data]);
    const setRowSelection = useAppStore((state) => state.setRowSelection);
    const setColumnPinning = useAppStore((state) => state.setColumnPinning);
    const setColumnVisibility = useAppStore(
      (state) => state.setColumnVisibility,
    );
    const setDefaultColumnVisibility = useAppStore(
      (state) => state.setDefaultColumnVisibility,
    );
    const setColumnOrder = useAppStore((state) => state.setColumnOrder);

    const table = useReactTable({
      data: memotizedData,
      columns: memoizedColumns,
      state: {
        expanded,
        ...tableState,
      },
      columnResizeMode: "onChange",
      onExpandedChange: setExpanded,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      getSubRows: (row) => row.subRows,
      onColumnOrderChange: (updater) => {
        setColumnOrder({
          tableId,
          updater,
        });
      },
      onRowSelectionChange: (updater) => {
        setRowSelection({
          tableId,
          updater,
        });
      },
      onColumnPinningChange: (updater) => {
        setColumnPinning({
          tableId,
          updater,
        });
      },
      onColumnVisibilityChange: (updater) => {
        setColumnVisibility({
          tableId,
          updater,
        });
      },
    });

    const virtualizer = useVirtualizer({
      overscan: 5,
      count: data?.length ?? 0,
      //measure dynamic row height, except in firefox because it measures table border height incorrectly
      measureElement:
        typeof window !== "undefined" &&
        navigator.userAgent.indexOf("Firefox") === -1
          ? (element) => element?.getBoundingClientRect().height
          : undefined,
      estimateSize: () => maxEstimatedRowHeight,
      getScrollElement: () => containerRef.current,
    });

    const { rows } = table.getRowModel();
    const virtualRows = virtualizer.getVirtualItems();
    const totalSize = virtualizer.getTotalSize();
    const { searchInputCss } = inputFieldTheme();

    const onSearch = (searchTerm: string) => {
      if (handleSearch) {
        setSearchTerm(searchTerm);
        handleSearch(searchTerm);
      }
    };

    const handleClearSearch = () => {
      setSearchTerm("");
      onSearch("");
      setShowSearch(false);
    };

    const handleOpenSearch = () => {
      setShowSearch(true);
    };

    useEffect(() => {
      if (!isMounted && data.length > 0 && memoizedColumns.length > 0) {
        setIsMounted(true);
        setColumnPinning({
          tableId,
          columnPinning: pinnedColumns,
        });

        if (selectedRowIds) {
          const rowSelection = generateRowSelection(data, selectedRowIds);
          setRowSelection({
            tableId,
            rowSelection,
          });
        }
        if (showDefaultColumn) {
          setDefaultColumnVisibility({
            tableId,
            pinnedColumns,
            columns: memoizedColumns,
          });
        }
      }
    }, [
      isMounted,
      data,
      selectedRowIds,
      tableId,
      pinnedColumns,
      memoizedColumns,
      setColumnPinning,
      setRowSelection,
      showDefaultColumn,
      setDefaultColumnVisibility,
    ]);

    return (
      <div className="relative w-full rounded-md">
        <DownloadColumnsModal data={data} tableId={tableId} columns={columns} />
        <div className="mb-2 flex justify-between">
          <div className="flex items-center space-x-2">
            {label && (
              <span className="font-gray-700 px-2 font-sans text-xl">
                {t(label)}
              </span>
            )}
            {!!handleSearch && (
              <>
                {showSearch ? (
                  <MiniSearchField
                    className={searchInputCss({
                      size: "xs",
                      className: "mt-2",
                    })}
                    placeholder="Search"
                    value={searchTerm}
                    onChange={onSearch}
                    onClear={handleClearSearch}
                  />
                ) : (
                  <IconButtonBase
                    size="xs"
                    className="focus:ring-0"
                    onClick={handleOpenSearch}
                  >
                    <span className="sr-only">{t("Open Search")}</span>
                    <SearchIcon className="h-4 w-4" />
                  </IconButtonBase>
                )}
              </>
            )}
          </div>
          <div className="flex items-center space-x-2">
            {isConfigurable && (
              <ConfigurationDropdown
                tableId={tableId}
                columns={memoizedColumns}
              />
            )}
            {tableConfigChildren}
          </div>
        </div>
        <div
          ref={containerRef}
          className={clsx(
            "relative overflow-auto overscroll-contain border border-gray-200",
            containerClass,
          )}
        >
          <table
            className="grid border-collapse"
            style={{
              ...getTableSize(table, isFullWidth),
            }}
          >
            <thead className="sticky top-0 z-20 grid bg-gray-50">
              {table.getHeaderGroups().map(({ id: rowId, headers }) => (
                <tr
                  key={rowId}
                  className="flex w-full border-b border-gray-200"
                >
                  {headers.map((header, i) => {
                    const pinPosition = header.column.getIsPinned();
                    const isResizing = header.column.getIsResizing();

                    return (
                      <th
                        className={clsx(
                          "amenda-table-heading group/th sticky top-0 border-r border-gray-200 bg-gray-50",
                          {
                            "z-30": pinPosition,
                            "z-20": !pinPosition,
                          },
                        )}
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          ...getHeaderStickyPosition(headers, i),
                          ...([
                            SpecialColumns.SELECT,
                            SpecialColumns.ACTIONS,
                          ].includes(header.id as SpecialColumns)
                            ? {
                                width: header.getSize(),
                                minWidth: header.getSize(),
                              }
                            : isResizable
                              ? {
                                  width: header.getSize(),
                                }
                              : {
                                  width: "100%",
                                  minWidth: header.getSize(),
                                }),
                        }}
                      >
                        {!Boolean(header.isPlaceholder) && (
                          <div className="flex h-full w-full items-center overflow-hidden text-gray-600">
                            <div className="truncate">
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                            </div>
                            <TableSortIndicators
                              tableId={tableId}
                              column={header.column}
                              handleFilter={handleFilter}
                            />
                            <TablePinColumn
                              column={header.column}
                              hidePin={isSelectColumn(header.id)}
                            />
                          </div>
                        )}
                        {isResizable &&
                          ![
                            SpecialColumns.ACTIONS,
                            SpecialColumns.SELECT,
                          ].includes(header.id as SpecialColumns) && (
                            <div
                              {...{
                                onDoubleClick: () => header.column.resetSize(),
                                onMouseDown: header.getResizeHandler(),
                                onTouchStart: header.getResizeHandler(),
                                className: clsx(
                                  "group-hover/th:opacity-100 absolute right-0 top-0 h-full w-[3px] cursor-col-resize select-none bg-gray-300",
                                  {
                                    hidden: isResizing,
                                    "opacity-0": !isResizing,
                                  },
                                ),
                                style: {
                                  transform: isResizing
                                    ? `translateX(${
                                        table.getState().columnSizingInfo
                                          .deltaOffset
                                      }px)`
                                    : "",
                                },
                              }}
                            />
                          )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody
              ref={tableRef}
              className="relative grid bg-white"
              style={{
                height: `${totalSize}px`,
              }}
            >
              {virtualRows.map((virtualRow, i) => {
                const row = rows[virtualRow.index] as Row<any>;

                if (i === virtualRows.length - 1) {
                  return (
                    <RefTableRow
                      key={row.id}
                      row={row}
                      tableRef={tableRef}
                      isLoading={isLoading}
                      pagination={pagination}
                      isResizable={isResizable}
                      virtualRow={virtualRow}
                      maxEstimatedRowHeight={maxEstimatedRowHeight}
                      onRowClick={onRowClick}
                      fetchNextPage={fetchNextPage}
                    />
                  );
                }
                return (
                  <TableRow
                    key={row.id}
                    row={row}
                    tableRef={tableRef}
                    isResizable={isResizable}
                    virtualRow={virtualRow}
                    maxEstimatedRowHeight={maxEstimatedRowHeight}
                    onRowClick={onRowClick}
                  />
                );
              })}
              <TableLoader
                data={data}
                isLoading={isLoading}
                pagination={pagination}
                searchTerm={searchTerm}
                virtualizer={virtualizer}
                headers={table.getHeaderGroups()?.[0]?.headers}
                rowHeight={maxEstimatedRowHeight}
              />
            </tbody>
          </table>
          {!isLoading && isEmpty(data) && (
            <>
              {EmptyStateWidget ? (
                EmptyStateWidget
              ) : (
                <HelperMessage
                  className="h-full min-h-96 w-full"
                  Icon={Table2Icon}
                  message={searchTerm ? "No results found" : "No data found"}
                  helpText={
                    searchTerm
                      ? "There are no results for this term"
                      : "There is no data available for this table"
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(nextProps.data)) {
      return false;
    } else if (
      JSON.stringify(prevProps.defaultPinnedColumns) !==
      JSON.stringify(nextProps.defaultPinnedColumns)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.pagination) !==
      JSON.stringify(nextProps.pagination)
    ) {
      return false;
    } else if (prevProps.hideActionColumn !== nextProps.hideActionColumn) {
      return false;
    } else if (prevProps.isLoading !== nextProps.isLoading) {
      return false;
    } else if (prevProps.showDefaultColumn !== nextProps.showDefaultColumn) {
      return false;
    }
    return true;
  },
);
