import { NavigationProps } from "@amenda-types";
import { capitalize } from "lodash";
import { devConsole } from "./data";
import qs from "qs";

const intlFormatter = (value: number, suffix = "") => {
  const formattedValue = new Intl.NumberFormat("de-DE").format(value);

  return [formattedValue, suffix].filter(Boolean).join(" ");
};

export const formatNumbers = (value: number | string, suffix = "") => {
  const roundedValue = Number(value).toFixed(2);
  return intlFormatter(Number(roundedValue), suffix);
};

export const roundAndFormatNumbers = (value: number | string, suffix = "") => {
  const roundedValue = Math.round(Number(value));
  return intlFormatter(roundedValue, suffix);
};

export const reverseNumberFormatting = (value: string) => {
  let updatedValue = value.split(".").join("");
  updatedValue = updatedValue.replace(",", ".");
  return Number(updatedValue);
};

export const getInitials = (name?: string) => {
  if (!name) return "";
  const [firstName, ...otherNames] = name.split(" ");
  const firstAndLastNames = [firstName, otherNames.pop()];
  return firstAndLastNames
    .map((word) => word && capitalize(word.charAt(0)))
    .join("");
};

export const getQueryParams = (searchString: string) => {
  const query = qs.parse(searchString, { ignoreQueryPrefix: true });
  return query as any;
};

export const matchUrls = (pathname: string, navigate: NavigationProps) => {
  if (pathname === "/") {
    return navigate.path === "/";
  } else if (navigate.path === "/") {
    return pathname === "/";
  } else if (navigate.matchPaths) {
    return navigate.matchPaths.some((path) => pathname.includes(path));
  }
  return pathname.includes(navigate.path);
};

const magicLinkSizes: Record<string, any> = {
  thumbnail: { s: 64 },
  gallery: { s: 400 },
  galleryCropped: { s: 400 },
  default: { s: 400 },
  xl: { w: 512, h: 512 },
  "2xl": { w: 1000, h: 1000 },
  fullscreen: { w: 1280 },
  original: { s: 0 },
  avatar: { p: undefined },
  square: { pp: undefined },
};

// Function to add Google-specific parameters to URL
const addGoogleParams = (params: Record<string, any>) => {
  const availableParams = {
    ...params,
  };

  return Object.entries(availableParams)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => `${key}${value}`)
    .join("-");
};

const cloudflareVariants: Record<string, any> = {
  default: { default: "default" },
  gallery: { gallery: "gallery" },
  galleryCropped: { galleryCropped: "galleryCropped" },
  thumbnail: { thumbnail: "thumbnail" },
  original: { original: "gamma=0" },
  fullscreen: { fullscreen: "fullscreen" },
};

// Function to add Cloudflare-specific parameters to URL
const addCloudflareParams = (params: Record<string, any>) => {
  const availableParams = {
    ...params,
  };

  return Object.entries(availableParams)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => {
      if (["w", "h"].includes(key)) {
        return `${key}=${value}`;
      }
      return `${key}`;
    });
};

export const processMagicLinks = (
  src: string,
  params: Record<string, any> = {},
) => {
  if (src) {
    const url = new URL(src);
    const { size, ...rest } = params;

    switch (url.hostname) {
      case "lh3.googleusercontent.com":
        return `${src}=${addGoogleParams({
          ...rest,
          ...magicLinkSizes[size],
        })}`;
      case "imagedelivery.net":
        return `${src}/${addCloudflareParams({
          ...rest,
          ...cloudflareVariants[size],
        })}`;
      default:
        return src;
    }
  }
  return src;
};

export const formatDescription = (values: any) => {
  if (Array.isArray(values)) {
    return values.map((value) => value.label);
  }
  if (values?.name) {
    return values?.name;
  }
  return values;
};

export const getFormatFromAttachment = (attachment: any) => {
  if (attachment?.filename?.includes("a4")) {
    return `Format: A4, ${attachment?.type?.toUpperCase()}`;
  }
  if (attachment?.filename?.includes("a3")) {
    return `Format: A3, ${attachment?.type?.toUpperCase()}`;
  }
};

interface CopyToClipBoard {
  text: string;
  onSuccess?: () => void;
  onError?: (err: any) => void;
}

export const legacyCopyToClipBoardFallback = ({
  text,
  onSuccess,
  onError,
}: CopyToClipBoard) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
    onSuccess?.();
  } catch (err) {
    onError?.(err);
  }

  document.body.removeChild(textArea);
};

export const copyToClickBoard = ({
  text,
  onSuccess,
  onError,
}: CopyToClipBoard) => {
  if (!navigator.clipboard) {
    return legacyCopyToClipBoardFallback({
      text,
      onError,
      onSuccess,
    });
  }
  navigator.clipboard
    .writeText(text)
    .then(() => {
      onSuccess?.();
    })
    .catch((err) => {
      onError?.(err);
    });
};

export const isAttachmentPdfOfTypeTemplate = (file: any) => {
  return file?.filename.includes("_a4_") || file?.filename.includes("_a3_");
};

export const isMac = () => {
  if (window?.navigator) {
    const platform = window.navigator.platform.toLowerCase();
    const userAgent = window.navigator.userAgent.toLowerCase();

    return platform.includes("mac") || userAgent.includes("macintosh");
  }
  return false;
};

export const downloadFromUrl = async (url: string, filename?: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = filename || "download";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  } catch (err) {
    devConsole?.log("Download failed:", err);
    throw err;
  }
};
