import {
  AllowedCollectionType,
  AppRoutes,
  AvailablePermissions,
  ComponentValidationType,
  FormTab,
  LogicalOperators,
} from "@amenda-types";
import {
  ApiFilterTypes,
  AvailableForms,
  FormComponentTypes,
} from "@amenda-constants";
import {
  Bars4Icon,
  EnvelopeIcon,
  GlobeAltIcon,
  MapPinIcon,
  PhoneIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import { isArray, isEmpty } from "lodash";
import {
  sanitizeData,
  sanitizeNestedData,
  transformSearchFilters,
} from "@amenda-utils";

import Fuse from "fuse.js";

export interface CollectionProps {
  resourceIds: string[];
  collectionType: AllowedCollectionType;
  getResourceIds: (args: any) => Promise<string[]>;
  clearSelectedResources: () => void;
}

export enum LayoutTypes {
  Grid = "grid",
  List = "list",
  Table = "table",
  Directory = "directory",
}

export const PermissionSharePath = "amendaPermissionSharePath";

export const getFilterType = (component: any) => {
  if (component?.component === FormComponentTypes.Hidden) {
    return ApiFilterTypes.MetaData;
  } else if (component?.component === FormComponentTypes.Keyword) {
    return ApiFilterTypes.Keywords;
  }
  return ApiFilterTypes.Values;
};

export const getSearchValue = (value?: string) => {
  return {
    filterType: ApiFilterTypes.Values,
    componentType: FormComponentTypes.Input,
    isNumberInputField: false,
    value,
  };
};

export const isSearchFilterEmpty = (filters?: any) => {
  if (isEmpty(filters)) return true;
  return Object.keys(filters).every((key) => {
    return isEmpty(filters[key].value);
  });
};

export const processSearchFilters = ({
  component,
  value,
  other = {},
}: {
  component: any;
  value: any;
  other?: Record<string, any>;
}) => {
  return {
    ...other,
    value,
    filterType: getFilterType(component),
    componentType: component.component,
    isNested: Boolean(component?.properties?.isNested),
    isNumberInputField:
      component?.validation?.type === ComponentValidationType.Number,
  };
};

export const processReadAndEditPermissions = (
  permission: AvailablePermissions,
) =>
  [AvailablePermissions.Read, AvailablePermissions.Edit].includes(permission);

export const processComponentPermissions = (
  component: any,
  permissions: Record<string, any>,
) => {
  const { id, formId } = component;
  const formPermissions = permissions[formId] || {};
  const permission = formPermissions[id];

  if (!permission) return false;
  return processReadAndEditPermissions(permission);
};

export const addReadOnlyProperty = (
  component: any,
  permissions: Record<string, any>,
) => {
  const { id, formId } = component;
  const formPermissions = permissions[formId] || {};
  const permission = formPermissions[id];

  return {
    ...component,
    properties: {
      readOnly: permission === AvailablePermissions.Read,
      ...(component?.properties || {}),
    },
  };
};

export const processFormPermissions =
  (permissions: Record<string, any>) => (components: any[]) => {
    return components
      .filter((c) => processComponentPermissions(c, permissions))
      .map((c) => addReadOnlyProperty(c, permissions));
  };

export const getComponentIcons = (type?: string) => {
  switch (type) {
    case "EnvelopeIcon":
      return EnvelopeIcon;
    case "GlobeAltIcon":
      return GlobeAltIcon;
    case "PhoneIcon":
      return PhoneIcon;
    case "Bars4Icon":
      return Bars4Icon;
    case "MapPinIcon":
      return MapPinIcon;
    case "UserCircleIcon":
      return UserCircleIcon;
    default:
      return null;
  }
};

export const getFormsByCollectionType = (
  collectionType: AllowedCollectionType,
  forms: Record<string, FormTab[]> | undefined,
) => {
  let availableForms: FormTab[] | undefined;

  switch (collectionType) {
    case AllowedCollectionType.Projects:
      availableForms = forms?.[AvailableForms.Project];
      break;
    case AllowedCollectionType.Attachments:
      availableForms = forms?.[AvailableForms.Gallery];
      break;
    case AllowedCollectionType.Users:
      availableForms = [
        ...(forms?.[AvailableForms.Persons] || []),
        ...(forms?.[AvailableForms.Company] || []),
      ];
      break;
    case AllowedCollectionType.Contacts:
      availableForms = forms?.[AvailableForms.Office];
      break;
    default:
      break;
  }
  return availableForms;
};

export enum ResourceSharingPermissionTypes {
  Edit = "edit",
  View = "view",
  Admin = "admin",
}

export const sharePermissionsOptions = [
  {
    label: "View only",
    value: ResourceSharingPermissionTypes.View,
  },
  {
    label: "Edit",
    value: ResourceSharingPermissionTypes.Edit,
  },
  {
    label: "Admin",
    value: ResourceSharingPermissionTypes.Admin,
  },
];

export const sharePermissionsSecondaryOptions = [
  {
    label: "Remove access",
    value: "removeAccess",
  },
];

export const generateCollectionShare = ({
  systemRoles,
  users,
  matchingUsers,
}: {
  matchingUsers: any[];
  systemRoles: any[];
  users: any[];
}) => {
  const share = {
    users: users.concat(matchingUsers),
    system: systemRoles,
  };

  return sanitizeNestedData(share);
};

export const transformCollection = ({
  share,
  description,
  ...rest
}: Record<string, any>) => {
  const form = {
    ...rest,
    description: description ?? "",
    users: share?.users ?? [],
    systemRoles: share?.system ?? [],
    shareable: !isEmpty(share?.users) || !isEmpty(share?.system),
  };

  return sanitizeData(form);
};

// enrich search filters with logical operators
export const enrichSearchFilters = (selectedFilter: any, data: any) => {
  const { operation } = selectedFilter;

  if (!operation || operation === LogicalOperators.AND) {
    return { [LogicalOperators.AND]: data };
  }
  if (operation === LogicalOperators.OR) {
    return {
      [LogicalOperators.OR]: data,
    };
  }
  return {
    $exists: true,
    [LogicalOperators.NOT]: data,
  };
};

export const transformFilters = (
  searchFilters: Record<string, any>,
  valuesKey = "formValues",
) => {
  const { keywords, values, metadata } = transformSearchFilters(searchFilters);
  let filters: Record<string, any> = {};

  if (values) {
    Object.keys(values).forEach((key) => {
      if (
        [
          FormComponentTypes.Select,
          FormComponentTypes.MultiSelect,
          FormComponentTypes.Keyword,
          FormComponentTypes.Badges,
          FormComponentTypes.Checkbox,
          FormComponentTypes.RadioButton,
          FormComponentTypes.ColoredSelect,
          FormComponentTypes.SearchAndSelect,
          FormComponentTypes.SearchAndSelectProjects,
          FormComponentTypes.RegionalSelect,
        ].includes(searchFilters[key]["componentType"])
      ) {
        filters = {
          ...filters,
          [`${valuesKey}.${key}`]: enrichSearchFilters(
            searchFilters[key],
            values[key],
          ),
        };
      } else if (
        FormComponentTypes.AddressSearch === searchFilters[key]["componentType"]
      ) {
        filters = {
          ...filters,
          [`${valuesKey}.${key}.name`]: values[key],
        };
      } else if (
        FormComponentTypes.DatePickerRange ===
        searchFilters[key]["componentType"]
      ) {
        if (values[key]?.from) {
          filters = {
            ...filters,
            [`${valuesKey}.${key}.from`]: values[key].from,
          };
        }
        if (values[key]?.to) {
          filters = {
            ...filters,
            [`${valuesKey}.${key}.to`]: values[key].to,
          };
        }
      } else {
        filters = {
          ...filters,
          [`${valuesKey}.${key}`]: values[key],
        };
      }
    });
  }
  if (keywords) {
    Object.keys(keywords).forEach((key) => {
      filters = {
        ...filters,
        [`${valuesKey}.${key}`]: enrichSearchFilters(
          searchFilters[key],
          keywords[key],
        ),
      };
    });
  }
  if (metadata) {
    Object.keys(metadata).forEach((key) => {
      filters = {
        ...filters,
        [`metadata.${key}`]: isArray(metadata[key])
          ? metadata[key][0]
          : metadata[key],
      };
    });
  }
  return filters;
};

export const getErrorFromFieldError = (fieldError: any[]) => {
  let error = "";

  fieldError.forEach((err) => {
    if (!isEmpty(err)) {
      Object.keys(err).forEach((key) => {
        error = key + ": " + err[key]?.message;
        return;
      });
      return;
    }
  });

  return error;
};

export const addMatchForCollection = (resourceIds: string[]) => {
  return {
    isDeleted: false,
    _id: {
      $in: resourceIds,
    },
  };
};

export const isAttachmentOrProjectCollection = (pathname: string) => {
  return (
    pathname.includes(AppRoutes.ProjectsCollection) ||
    pathname.includes(AppRoutes.AttachmentsCollection)
  );
};

export const matchIgnoreIds = (ids: string[]) => {
  return {
    _id: {
      [LogicalOperators.NOT]: ids,
    },
  };
};

export const checkCollectionPermissions = ({
  collection,
  permission,
  currentUser,
}: {
  currentUser: Record<string, any>;
  collection: Record<string, any>;
  permission: ResourceSharingPermissionTypes;
}) => {
  if (collection.ownerId === currentUser.id) {
    return true;
  }

  const system = collection?.share?.system ?? [];
  const users = collection?.share?.users ?? [];

  const user = users.find((u: any) => u.id === currentUser.id);
  if (user && user.role && user.id === currentUser.id) {
    return user.role === permission;
  }

  const systemRole = system.find((s: any) => s.id === currentUser.id);
  if (systemRole && systemRole.role && systemRole.id === currentUser.id) {
    return systemRole.role === permission;
  }
  return false;
};

export const searchInCollections = (collections: any[], searchTerm: string) => {
  if (searchTerm.length > 0) {
    const fuse = new Fuse(collections, {
      includeScore: true,
      keys: ["name"],
      threshold: 0.2,
    });
    const results = fuse.search(searchTerm);
    return results.map((res) => res.item);
  }
  return [...collections].sort((a, b) => {
    const aName = a.name ?? "";
    const bName = b.name ?? "";

    return aName.localeCompare(bName);
  });
};
