import { AllowedContactType, AppRoutes } from "@amenda-types";
import { Avatar, Modal, Skeleton } from "@amenda-components/App";
import { ChangeEvent, FC, ReactNode, useEffect, useState } from "react";
import { StaticColumnProps, flattenUserDetails, getUserName } from "./common";
import { useGetAllContacts, useGetContact } from "@amenda-domains/queries";

import { TableCellWrapper } from "@amenda-components/Shared/ReactTableComponents";
import { isEmpty } from "lodash";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { useUsersStore } from "@amenda-domains/mutations";

type LinkedDataItem = (props: { value: any; onClick: () => void }) => ReactNode;

interface LinkedDataViewProps {
  values: any[];
  children: LinkedDataItem;
  onClick: (value: any) => void;
}

interface LinkedDataProps {
  user: any;
}

const LinkedDataView: FC<LinkedDataViewProps> = ({
  values,
  children,
  onClick,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => setOpenModal(false);

  const handleClick = (e: ChangeEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();

    setOpenModal(true);
  };

  if (isEmpty(values)) {
    return null;
  }

  const selectedValues = values.slice(0, 2);

  return (
    <>
      <TableCellWrapper isText={true} onClick={handleClick}>
        {selectedValues.map((v) => v.label).join(", ")}
      </TableCellWrapper>
      <Modal
        isOpen={openModal}
        isElevated={true}
        closeModalFromTitle={true}
        withCancel={false}
        size="md"
        contentClassName="pb-6"
        className="w-11/12 md:w-1/2 lg:w-4/12"
        title="View Details"
        onClose={handleClose}
      >
        <div className="flex flex-wrap">
          {values.map(({ label, ...value }) => (
            <div key={label} className="w-full px-1 pb-1">
              {children({
                value,
                onClick: () => onClick(value),
              })}
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

const LinkedPersons: FC<LinkedDataProps> = ({ user }) => {
  const { ref, inView } = useInView();
  const [contacts, setContacts] = useState<any[]>([]);
  const navigate = useNavigate();
  const setOpenUsersTableView = useUsersStore(
    (state) => state.setOpenUsersTableView,
  );
  const { getAllContacts, loading } = useGetAllContacts();

  const companyId = user?.type === AllowedContactType.company && user?.id;

  const handleClick = (user: any) => {
    const path = `${AppRoutes.Contacts}/${user.id}`;

    setOpenUsersTableView(false);
    navigate(path);
  };

  useEffect(() => {
    if (companyId && inView) {
      getAllContacts({
        isDeleted: false,
        type: AllowedContactType.person,
        contactDetails: {
          company: companyId,
        },
        callback: (contacts = []) => setContacts(contacts),
      });
    }

    return () => {
      setContacts([]);
    };
  }, [companyId, inView, getAllContacts]);

  if (loading) {
    return <Skeleton />;
  }
  return (
    <div className="h-full w-full" ref={ref}>
      <LinkedDataView
        values={contacts.map((user) => ({
          ...user,
          label: getUserName(user),
        }))}
        onClick={handleClick}
      >
        {({ value, onClick }) => (
          <div
            onClick={onClick}
            className="flex w-full cursor-pointer space-x-2 border border-gray-300 bg-white px-4 py-2 hover:bg-gray-50"
          >
            <div className="px-2">
              <Avatar
                className="h-8 w-8 text-base"
                name={`${value?.firstName} ${value?.lastName}`}
              />
            </div>
            <div>
              <p className="text-base text-gray-900">
                {`${value.firstName ?? ""} ${value.lastName ?? ""}`}
              </p>
              <p className="text-left text-sm text-gray-500">
                {value?.address?.name ?? ""}
              </p>
            </div>
          </div>
        )}
      </LinkedDataView>
    </div>
  );
};

const getContactLabels = (contacts: any[]) => {
  return contacts.map((c) => {
    const fUser = flattenUserDetails(c);

    return {
      ...fUser,
      label: fUser?.companyName,
    };
  });
};

const LinkedCompany: FC<LinkedDataProps> = ({ user }) => {
  const { inView, ref } = useInView();
  const [contacts, setContacts] = useState<any[]>([]);
  const { getContact, loading } = useGetContact();
  const navigate = useNavigate();
  const setOpenUsersTableView = useUsersStore(
    (state) => state.setOpenUsersTableView,
  );

  const companyId = flattenUserDetails(user)?.company;

  const handleClick = (user: any) => {
    const path = `${AppRoutes.Contacts}/${user.id}`;

    setOpenUsersTableView(false);
    navigate(path);
  };

  useEffect(() => {
    if (companyId && inView) {
      getContact({
        id: companyId,
        callback: (c) => setContacts([c]),
      });
    }

    return () => {
      setContacts([]);
    };
  }, [inView, companyId, getContact]);

  if (loading) {
    return <Skeleton />;
  }
  return (
    <div className="h-full w-full" ref={ref}>
      <LinkedDataView values={getContactLabels(contacts)} onClick={handleClick}>
        {({ value, onClick }) => (
          <div
            onClick={onClick}
            className="flex w-full cursor-pointer space-x-2 border border-gray-300 bg-white px-4 py-2 hover:bg-gray-50"
          >
            <div className="px-2">
              <Avatar className="h-8 w-8 text-base" name={value?.companyName} />
            </div>
            <div>
              <p className="text-left text-base text-gray-900 hover:text-gray-600">
                {value?.companyName}
              </p>
              <p className="text-left text-sm text-gray-500">
                {value?.address?.name ?? ""}
              </p>
            </div>
          </div>
        )}
      </LinkedDataView>
    </div>
  );
};

export const getLinkedContactDataColumns = (
  t: (label: string) => string,
  contactType?: AllowedContactType,
) => {
  let columns: StaticColumnProps[];
  const linkedPersonColumn = {
    id: "linkedPersons",
    label: t("Related Persons"),
    contactType: AllowedContactType.company,
    getCell: (row: any) => {
      return <LinkedPersons user={row} />;
    },
  };
  const linkedCompanyColumn = {
    id: "linkedCompany",
    label: t("Related Company"),
    contactType: AllowedContactType.person,
    getCell: (row: any) => {
      return <LinkedCompany user={row} />;
    },
  };

  if (contactType === AllowedContactType.office) {
    columns = [];
  } else if (contactType === AllowedContactType.person) {
    columns = [linkedCompanyColumn];
  } else if (contactType === AllowedContactType.company) {
    columns = [linkedPersonColumn];
  } else {
    columns = [linkedPersonColumn, linkedCompanyColumn];
  }
  return columns;
};
