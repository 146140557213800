import {
  MATERIAL_FRAGMENT,
  MATERIAL_SUBSCRIPTION_FRAGMENT,
} from "@amenda-domains/fragments/materials";
import { gql, useClient, useSubscription } from "urql";
import { useCallback, useState } from "react";
import { useTenantStore, useUsersStore } from "@amenda-domains/mutations";

import { PaginationQueryProps } from "@amenda-types";
import { SearchCollections } from "@amenda-constants";
import { isEmpty } from "lodash";
import { useMaterialsStore } from "@amenda-domains/mutations/materials";

export const GET_ALL_MATERIALS = gql`
  ${MATERIAL_FRAGMENT}
  query GetAllMaterials(
    $ids: [ID!]
    $formValues: Object
    $isDeleted: Boolean
    $limit: Int
    $name: String
    $next: String
    $previous: String
    $selectMaterialFields: Object
    $sort: Object
  ) {
    getAllMaterials(
      ids: $ids
      formValues: $formValues
      isDeleted: $isDeleted
      limit: $limit
      name: $name
      next: $next
      previous: $previous
      selectMaterialFields: $selectMaterialFields
      sort: $sort
    ) {
      next
      docsCount
      previous
      hasNext
      hasPrevious
      materials {
        ...MaterialFragment
      }
    }
  }
`;

const GET_MATERIAL = gql`
  ${MATERIAL_FRAGMENT}
  query GetMaterial(
    $id: ID
    $formValues: Object
    $isDeleted: Boolean
    $name: String
  ) {
    getMaterial(
      _id: $id
      formValues: $formValues
      isDeleted: $isDeleted
      name: $name
    ) {
      ...MaterialFragment
      maxPrice
      minPrice
      averagePrice
    }
  }
`;

const GET_MATERIAL_PRICES = gql`
  query GetMaterial($id: ID) {
    getMaterial(_id: $id) {
      id: _id
      maxPrice
      minPrice
      averagePrice
    }
  }
`;

const MATERIAL_CREATED_SUBS = gql`
  ${MATERIAL_SUBSCRIPTION_FRAGMENT}
  subscription MaterialCreated($ownerId: String!, $tenantId: String!) {
    materialCreated(ownerId: $ownerId, tenantId: $tenantId) {
      ...MaterialSubsFragment
    }
  }
`;

const MATERIAL_UPDATED_SUBS = gql`
  ${MATERIAL_SUBSCRIPTION_FRAGMENT}
  subscription MaterialUpdated($ownerId: String!, $tenantId: String!) {
    materialUpdated(ownerId: $ownerId, tenantId: $tenantId) {
      ...MaterialSubsFragment
    }
  }
`;

const MATERIAL_DELETED_SUBS = gql`
  subscription MaterialDeleted($ownerId: String!, $tenantId: String!) {
    materialDeleted(ownerId: $ownerId, tenantId: $tenantId) {
      id: _id
      isDeleted
    }
  }
`;

interface GetAllMaterials extends PaginationQueryProps {
  autoSelect?: boolean;
  isDeleted?: boolean;
  isCollection?: boolean;
  selectMaterialFields?: any;
  formValues?: any;
  ids?: string[];
  skipStore?: boolean;
  name?: string;
}

export const useGetAllMaterials = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setMaterials = useMaterialsStore((state) => state.setMaterials);
  const setGetMaterialsLoader = useMaterialsStore(
    (state) => state.setGetMaterialsLoader,
  );

  const getAllMaterials = useCallback(
    async ({
      autoSelect,
      isCollection,
      skipStore = false,
      ...rest
    }: GetAllMaterials) => {
      setLoading(true);
      setGetMaterialsLoader(true, rest);

      const { data } = await client
        .query(GET_ALL_MATERIALS, {
          sort: {
            "case_insensitive_formValues.name": 1,
          },
          selectMaterialFields: {
            name: 1,
            ownerId: 1,
            share: 1,
          },
          ...rest,
        })
        .toPromise();

      if (data?.getAllMaterials && !skipStore) {
        setMaterials({
          autoSelect,
          isCollection,
          ...data.getAllMaterials,
        });
      }
      setLoading(false);
      setGetMaterialsLoader(false, rest);
      return data?.getAllMaterials?.materials || [];
    },
    [client, setMaterials, setGetMaterialsLoader],
  );

  return {
    loading,
    getAllMaterials,
  };
};

export const useGetMaterial = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setSelectedMaterial = useMaterialsStore(
    (state) => state.setSelectedMaterial,
  );

  const getMaterial = useCallback(
    async ({
      context = {},
      ...variables
    }: {
      id: string;
      context?: Record<string, any>;
    }) => {
      setLoading(true);

      const { data } = await client
        .query(GET_MATERIAL, variables, context)
        .toPromise();

      if (data?.getMaterial) {
        setSelectedMaterial(data.getMaterial);
      }
      setLoading(false);
    },
    [client, setSelectedMaterial],
  );

  return {
    loading,
    getMaterial,
  };
};

export const useGetMaterialPrices = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);

  const getMaterialPrices = useCallback(
    async ({
      id,
      callback,
    }: {
      id: string;
      callback: (materialPrices: any) => void;
    }) => {
      setLoading(true);

      const { data } = await client
        .query(GET_MATERIAL_PRICES, {
          id,
        })
        .toPromise();

      if (data?.getMaterial) {
        callback(data.getMaterial);
      }
      setLoading(false);
    },
    [client],
  );

  return {
    loading,
    getMaterialPrices,
  };
};

export const useMaterialCreatedSubscription = () => {
  const currentUser = useUsersStore((state) => state.currentUser);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  useSubscription(
    {
      query: MATERIAL_CREATED_SUBS,
      variables: {
        ownerId: currentUser?.id,
        tenantId: primaryTenant?.tenantId,
      },
    },
    () => {},
  );
};

export const useMaterialUpdatedSubscription = () => {
  const currentUser = useUsersStore((state) => state.currentUser);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  useSubscription(
    {
      query: MATERIAL_UPDATED_SUBS,
      variables: {
        ownerId: currentUser?.id,
        tenantId: primaryTenant?.tenantId,
      },
    },
    () => {},
  );
};

export const useMaterialDeletedSubscription = () => {
  const currentUser = useUsersStore((state) => state.currentUser);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  useSubscription(
    {
      query: MATERIAL_DELETED_SUBS,
      variables: {
        ownerId: currentUser?.id,
        tenantId: primaryTenant?.tenantId,
      },
    },
    () => {},
  );
};

const SEARCH_MATERIALS = gql`
  query Search($input: SearchInput!) {
    search(input: $input) {
      materials
    }
  }
`;

interface SearchMaterialsArgs {
  autoSelect?: boolean;
  searchTerm: string;
  match?: Record<string, any>;
  groupByComponentIds?: string[];
  isCollection?: boolean;
}

export const useSearchMaterials = () => {
  const client = useClient();
  const [loading, setLoading] = useState<boolean>(false);
  const updateMaterials = useMaterialsStore((state) => state.updateMaterials);
  const setIsSearchingMaterials = useMaterialsStore(
    (state) => state.setIsSearchingMaterials,
  );

  const searchMaterials = useCallback(
    async ({
      autoSelect,
      searchTerm,
      groupByComponentIds,
      isCollection,
      match: matchArgs = {},
    }: SearchMaterialsArgs) => {
      try {
        setLoading(true);
        setIsSearchingMaterials(true);

        let match: any = { ...matchArgs, isDeleted: false };
        let allAggregations: any[] = [];
        let sortAggregations: any[] = [
          {
            $sort: {
              "case_insensitive_formValues.name": 1,
            },
          },
        ];

        if (searchTerm) {
          allAggregations = [
            {
              $search: {
                index: "autocomplete",
                compound: {
                  should: [
                    {
                      autocomplete: {
                        query: searchTerm,
                        path: "name",
                      },
                    },
                  ],
                },
              },
            },
            {
              $addFields: {
                score: {
                  $meta: "searchScore",
                },
              },
            },
            {
              $setWindowFields: {
                output: {
                  maxScore: {
                    $max: "$score",
                  },
                },
              },
            },
            {
              $addFields: {
                normalizedScore: {
                  $divide: ["$score", "$maxScore"],
                },
              },
            },
          ];
        }

        if (!isEmpty(groupByComponentIds)) {
          const groupIds = groupByComponentIds?.reduce<any>((acc, value) => {
            acc[value] = `$formValues.${value}`;
            return acc;
          }, {});
          const groupIdsNotNull = groupByComponentIds?.reduce<any>(
            (acc, value) => {
              acc.push({ [`formValues.${value}`]: { $exists: true } });
              return acc;
            },
            [],
          );

          match = {
            ...match,
            $or: groupIdsNotNull,
          };
          sortAggregations = [
            {
              $sort: {
                "case_insensitive_formValues.name": 1,
              },
            },
          ];

          const groupAggregations = [
            {
              $match: match,
            },
            {
              $group: {
                _id: groupIds,
                materials: { $push: "$$ROOT" },
                count: { $count: {} },
              },
            },
            {
              $match: {
                count: { $gte: 1 },
              },
            },
            {
              $unwind: "$materials",
            },
          ];

          allAggregations = [...allAggregations, ...groupAggregations];
        } else {
          allAggregations = [
            ...allAggregations,
            {
              $match: match,
            },
          ];
        }

        allAggregations = [...allAggregations, ...sortAggregations];

        const response = await client.query(
          SEARCH_MATERIALS,
          {
            input: {
              collections: [SearchCollections.Materials],
              aggregations: {
                [SearchCollections.Materials]: allAggregations,
              },
            },
          },
          {
            customKey: "searchMaterials",
            requestPolicy: "network-only",
          },
        );

        const { data } = response;
        let materials: any[] = data?.search?.materials ?? [];

        if (isEmpty(groupByComponentIds)) {
          materials = materials.map((m) => ({
            ...m,
            id: m._id,
          }));
        }

        updateMaterials({
          materials,
          autoSelect,
          isCollection,
          isGrouped: !isEmpty(groupByComponentIds),
        });
        setLoading(false);
        setIsSearchingMaterials(false);
      } catch (err) {
        setLoading(false);
        setIsSearchingMaterials(false);
      }
    },
    [client, updateMaterials, setIsSearchingMaterials],
  );

  return {
    loading,
    searchMaterials,
  };
};
