import {
  AvailableNotificationTypes,
  FormTab,
  Pagination,
  ReactTableKeys,
} from "@amenda-types";
import { FormCategories, SidebarFilters } from "@amenda-constants";
import { createJSONStorage, persist } from "zustand/middleware";
import { gql, useMutation } from "urql";
import { useCallback, useMemo } from "react";

import { KEYWORD_FRAGMENT } from "@amenda-domains/fragments/forms";
import { SpecialColumns } from "@amenda-components/Shared/reactTableHelpers";
import { TIMELINE_ACTIVITY_FRAGMENT } from "@amenda-domains/fragments/app";
import { Updater } from "@tanstack/react-table";
import { WIDGET_FRAGMENT } from "@amenda-domains/fragments/settings";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import isEmpty from "lodash/isEmpty";
import { useDashboardStore } from "./dashboard";
import { useLocation } from "react-router-dom";

enum AvailableLanguages {
  English = "en",
  German = "de",
}

type NotificationPopover = {
  openNotification: boolean;
  type: AvailableNotificationTypes;
  message: string;
};

type NotificationMessage = {
  id: string;
  message: string;
  path: string;
};

interface timelinePrimaryValues {
  searchTerm: string;
  cursorPosition: number | null;
  showTimelineContactsSearch: boolean;
  caretPosition: { top: number; left: number };
}

interface FormBuilderState {
  selectedCategory?: FormCategories;
  selectedForm?: FormTab;
  createNewFormModal?: {
    isOpen: boolean;
    source?: "xlsx" | "json" | "template";
  };
  openEditFormModal?: boolean;
  openEditComponentModal?: boolean;
  openCreateComponentModal?: boolean;
  openCostGroupComponentModal?: boolean;
  selectedFormComponent?: {
    config: Record<string, any>;
    global: Record<string, any>;
  };
  deleteForm?: boolean;
  deleteComponent?: boolean;
  deleteCostGroupComponent?: boolean;
  selectedCostGroupComponent?: any;
  isSavingComponentSorting?: boolean;
}

interface TableState {
  rowSelection: Record<number, boolean>;
  columnVisibility: Record<string, boolean>;
  columnPinning: Record<string, any>;
  columnOrder: string[];
  columnSorting: {
    direction?: 1 | -1;
    alias?: string;
    id?: string;
  };
}

type State = {
  isSubmitted: boolean;
  sidebarCollapsed: boolean;
  formBuilderState: FormBuilderState;
  pressedKey?: any;
  hoverSelector: boolean;
  tableState: Record<ReactTableKeys, TableState>;
  bottomSheetHeight: number;
  timelineActivities: any[];
  timelineActivitiesPagination: any;
  hasNewNotification: boolean;
  language: AvailableLanguages;
  isSidebarOpen: boolean;
  notification?: NotificationPopover;
  modals: Record<string, boolean>;
  searchResults: Record<string, any>;
  notificationMessages: NotificationMessage[];
  searchTerm: Record<string, string>;
  sidebarFilters: Record<string, Record<string, SidebarFilters>>;
  sidebarKeywords: Record<string, any[]>;
  expandedComponentGroup: Record<string, boolean>;
  visibleComponents: any[];
  selectedActivityTimeline: Record<string, any>;
  timelinePrimaryValues: timelinePrimaryValues;
  timelineEditValues: timelinePrimaryValues;
  isScrolling: boolean;
};

type Actions = {
  setColumnOrder: (args: {
    tableId: ReactTableKeys;
    columnOrder?: string[];
    updater?: Updater<any>;
  }) => void;
  setRowSelection: (args: {
    tableId: ReactTableKeys;
    rowSelection?: Record<number, boolean>;
    updater?: Updater<any>;
  }) => void;
  setColumnPinning: (args: {
    tableId: ReactTableKeys;
    columnPinning?: Record<string, any>;
    updater?: Updater<any>;
  }) => void;
  setDefaultColumnVisibility: (args: {
    tableId: ReactTableKeys;
    columns: any[];
    pinnedColumns: {
      left: string[];
      right: string[];
    };
  }) => void;
  setColumnVisibility: (args: {
    tableId: ReactTableKeys;
    columnVisibility?: Record<string, boolean>;
    updater?: Updater<any>;
  }) => void;
  setColumnSorting: (args: {
    tableId: ReactTableKeys;
    columnSorting: {
      id: string;
      alias?: string;
      direction?: 1 | -1;
      useCaseSensitive?: boolean;
    };
  }) => void;
  setIsScrolling: (isScrolling: boolean) => void;
  setIsSubmitted: (isSubmitted: boolean) => void;
  setSidebarCollapsed: (collapsed: boolean) => void;
  updateFormBuilderState: (key: keyof FormBuilderState, value: any) => void;
  setPressedKey: (key: any) => void;
  setHoverSelector: (hoverSelector: boolean) => void;
  setBottomSheetHeight: (bottomSheetHeight: number) => void;
  clearSelectedActivityTimeline: () => void;
  setSelectedActivityTimeline: (
    selectedActivityTimeline: Record<string, any>,
  ) => void;
  setActivityTimelines: (
    data: Pagination & {
      timelineActivities: any[];
    },
  ) => void;
  addActivityTimeline: (activity: any) => void;
  removeActivityTimeline: (activity: any) => void;
  updateActivityTimeline: (activity: any) => void;
  setExpandedComponentGroup: (
    expandedComponentGroup: Record<string, boolean>,
  ) => void;
  addNotificationMessage: (notificationMessage: NotificationMessage) => void;
  setHasNewNotification: (hasNewNotification: boolean) => void;
  toggleSearchModal: (isOpen: boolean) => void;
  setOpenSidebar: (isOpen: boolean) => void;
  setSearchResults: (searchResults?: Record<string, any>) => void;
  showNotification: (type: AvailableNotificationTypes, message: string) => void;
  closeNotification: () => void;
  setDefaultLanguage: (language: AvailableLanguages) => void;
  clearNotificationMessage: (message: NotificationMessage) => void;
  setSidebarFilters: (
    path: string,
    filters: Record<string, SidebarFilters>,
  ) => void;
  setSearchTerm: (path: string, searchTerm: string) => void;
  clearSidebarFilter: (path: string) => void;
  setVisibleComponents: (visibleComponents: any[]) => void;
  setTimelinePrimaryValues: (
    key: keyof timelinePrimaryValues,
    value: timelinePrimaryValues[keyof timelinePrimaryValues],
  ) => void;
  setTimelineEditValues: (
    key: keyof timelinePrimaryValues,
    value: timelinePrimaryValues[keyof timelinePrimaryValues],
  ) => void;
  setSidebarKeywords: (componentIds: string, keywords: any[]) => void;
  upsertSidebarKeywords: (componentId: string, keyword: any) => void;
  setOpenTableDownloadModal: (openModal: boolean) => void;
};

const persistedStateVersion = 0.8;
const maxHeight = window.innerHeight;
const allowedMaxHeight = maxHeight - maxHeight * 0.35;
const defaultTableState: TableState = {
  columnSorting: {},
  rowSelection: {},
  columnPinning: {},
  columnVisibility: {},
  columnOrder: [],
};

export const useAppStore = create(
  persist(
    immer<State & Actions>((set, get) => ({
      language: AvailableLanguages.German,
      isSidebarOpen: false,
      modals: {
        openSearchModal: false,
        openTableDownload: false,
      },
      searchResults: {},
      hasNewNotification: false,
      notificationMessages: [],
      sidebarFilters: {},
      searchTerm: {},
      expandedComponentGroup: {},
      visibleComponents: [],
      timelineActivities: [],
      selectedActivityTimeline: {},
      timelineActivitiesPagination: {},
      timelinePrimaryValues: {
        searchTerm: "",
        cursorPosition: null,
        showTimelineContactsSearch: false,
        caretPosition: { top: 0, left: 0 },
      },
      timelineEditValues: {
        searchTerm: "",
        cursorPosition: null,
        showTimelineContactsSearch: false,
        caretPosition: { top: 0, left: 0 },
      },
      bottomSheetHeight: allowedMaxHeight,
      tableState: {
        [ReactTableKeys.Gallery]: defaultTableState,
        [ReactTableKeys.Projects]: defaultTableState,
        [ReactTableKeys.Roles]: defaultTableState,
        [ReactTableKeys.Users]: defaultTableState,
        [ReactTableKeys.RegionalFactors]: defaultTableState,
        [ReactTableKeys.ConstructionDetails]: defaultTableState,
        [ReactTableKeys.ConstructionPriceIndices]: defaultTableState,
        [ReactTableKeys.ContactsFullScreenTable]: defaultTableState,
      },
      formBuilderState: {},
      sidebarKeywords: {},
      sidebarCollapsed: false,
      isSubmitted: false,
      isScrolling: false,
      hoverSelector: false,
      setIsScrolling(isScrolling) {
        set((state) => {
          state.isScrolling = isScrolling;
        });
      },
      setOpenTableDownloadModal(openModal) {
        set((state) => {
          state.modals.openTableDownload = openModal;
        });
      },
      setHoverSelector(hoverSelector) {
        set((state) => {
          state.hoverSelector = hoverSelector;
        });
      },
      setIsSubmitted(isSubmitted) {
        set((state) => {
          state.isSubmitted = isSubmitted;
        });
      },
      setSidebarCollapsed: (collapsed) =>
        set((state) => {
          state.sidebarCollapsed = collapsed;
        }),
      setSidebarKeywords: (componentId, keywords) =>
        set((state) => {
          state.sidebarKeywords[componentId] = keywords;
        }),
      upsertSidebarKeywords: (componentId, keyword) =>
        set((state) => {
          const keywords = get().sidebarKeywords[componentId] ?? [];

          const hasKeyword = keywords.some((k) => k.id === keyword.id);

          state.sidebarKeywords[componentId] = hasKeyword
            ? keywords.map((k) => (k.id === keyword.id ? keyword : k))
            : [...keywords, keyword];
        }),
      updateFormBuilderState: (key, value) =>
        set((state) => {
          state.formBuilderState[key] = value;
        }),
      setPressedKey: (key) =>
        set((state) => {
          state.pressedKey = key;
        }),
      setBottomSheetHeight: (bottomSheetHeight) =>
        set((state) => {
          state.bottomSheetHeight = bottomSheetHeight;
        }),
      clearSelectedActivityTimeline: () =>
        set((state) => {
          state.selectedActivityTimeline = {};
        }),
      setTimelinePrimaryValues: (key, value) =>
        set((state) => {
          state.timelinePrimaryValues[key] = value as never;
        }),
      setTimelineEditValues: (key, value) =>
        set((state) => {
          state.timelineEditValues[key] = value as never;
        }),
      setSelectedActivityTimeline: (selectedActivityTimeline) =>
        set((state) => {
          state.selectedActivityTimeline = selectedActivityTimeline;
        }),
      addActivityTimeline: (activity) =>
        set((state) => {
          const activities = get().timelineActivities;

          state.timelineActivities = [activity, ...activities];
        }),
      setActivityTimelines: ({ timelineActivities, ...rest }) =>
        set((state) => {
          state.timelineActivities = timelineActivities;
          state.timelineActivitiesPagination = rest;
        }),
      removeActivityTimeline: (activity) =>
        set((state) => {
          state.timelineActivities = state.timelineActivities.filter(
            (a) => a.id !== activity?.id,
          );
        }),
      updateActivityTimeline: (activity) =>
        set((state) => {
          const activities = get().timelineActivities;

          state.timelineActivities = activities.map((a) =>
            a.id === activity.id ? activity : a,
          );
        }),
      closeNotification: () =>
        set((state) => {
          if (state.notification?.openNotification) {
            state.notification.openNotification = false;
          }
        }),
      setExpandedComponentGroup: (expandedComponentGroup) =>
        set((state) => {
          state.expandedComponentGroup = expandedComponentGroup;
        }),
      setVisibleComponents: (visibleComponents) =>
        set((state) => {
          state.visibleComponents = visibleComponents;
        }),
      setSidebarFilters: (path, filters) =>
        set((state) => {
          state.sidebarFilters[path] = filters;
        }),
      clearSidebarFilter: (path) =>
        set((state) => {
          state.searchTerm[path] = "";
          state.sidebarFilters[path] = {};
        }),
      setSearchTerm: (path, searchTerm) =>
        set((state) => {
          state.searchTerm[path] = searchTerm;
        }),
      setHasNewNotification: (hasNewNotification) =>
        set((state) => {
          state.hasNewNotification = hasNewNotification;
        }),
      setDefaultLanguage: (language) =>
        set((state) => {
          state.language = language;
        }),
      showNotification: (type, message) =>
        set((state) => {
          state.notification = {
            type,
            message,
            openNotification: true,
          };
        }),
      setSearchResults: (searchResults = {}) =>
        set((state) => {
          state.searchResults = searchResults;
        }),
      setOpenSidebar: (isOpen) =>
        set((state) => {
          state.isSidebarOpen = isOpen;
        }),
      toggleSearchModal: (isOpen) =>
        set((state) => {
          state.modals.openSearchModal = isOpen;
        }),
      clearNotificationMessage: (message) =>
        set((state) => {
          state.notificationMessages = state.notificationMessages.filter(
            (m) => m.id !== message.id,
          );
        }),
      addNotificationMessage: (notificationMessage) =>
        set((state) => {
          state.notificationMessages = [
            notificationMessage,
            ...state.notificationMessages,
          ];
        }),
      setColumnSorting({ tableId, columnSorting }) {
        set((state) => {
          const prevTableState = get().tableState;

          state.tableState = {
            ...prevTableState,
            [tableId]: {
              ...prevTableState[tableId],
              columnSorting,
            },
          };
        });
      },
      setRowSelection({ tableId, updater, rowSelection = {} }) {
        set((state) => {
          const prevTableState = get().tableState;
          const prev = prevTableState[tableId].rowSelection;
          let values = rowSelection;

          if (updater) {
            values = updater instanceof Function ? updater(prev) : updater;
          }
          state.tableState = {
            ...prevTableState,
            [tableId]: {
              ...prevTableState[tableId],
              rowSelection: values,
            },
          };
        });
      },
      setColumnVisibility: ({ tableId, updater, columnVisibility = {} }) =>
        set((state) => {
          const prevTableState = get().tableState;
          const prev = prevTableState[tableId].columnVisibility;
          let values = columnVisibility;

          if (updater) {
            values = updater instanceof Function ? updater(prev) : updater;
          }

          const prevColumnOrder = prevTableState[tableId].columnOrder;
          let columnOrder = [...prevColumnOrder];
          Object.keys(values)
            .filter(
              (key) =>
                ![
                  SpecialColumns.ACTIONS,
                  SpecialColumns.GROUPING,
                  SpecialColumns.SELECT,
                ].includes(key as any),
            )
            .forEach((key) => {
              if (!columnOrder.includes(key) && values[key]) {
                columnOrder.push(key);
              } else if (columnOrder.includes(key) && !values[key]) {
                columnOrder = columnOrder.filter((c) => c !== key);
              }
            });

          state.tableState = {
            ...prevTableState,
            [tableId]: {
              ...prevTableState[tableId],
              columnOrder,
              columnVisibility: values,
            },
          };
        }),
      setColumnPinning({ tableId, updater, columnPinning = {} }) {
        set((state) => {
          const prevTableState = get().tableState;
          const prev = prevTableState[tableId].columnPinning;
          let values = isEmpty(prev) ? columnPinning : prev;

          if (updater) {
            values = updater instanceof Function ? updater(prev) : updater;
          }
          state.tableState = {
            ...prevTableState,
            [tableId]: {
              ...prevTableState[tableId],
              columnPinning: values,
            },
          };
        });
      },
      setColumnOrder({ tableId, updater, columnOrder = [] }) {
        set((state) => {
          const prevTableState = get().tableState;
          const prev = prevTableState[tableId].columnOrder;
          let values = columnOrder;

          if (updater) {
            values = updater instanceof Function ? updater(prev) : updater;
          }
          state.tableState = {
            ...prevTableState,
            [tableId]: {
              ...prevTableState[tableId],
              columnOrder: values,
            },
          };
        });
      },
      setDefaultColumnVisibility({ tableId, columns, pinnedColumns }) {
        set((state) => {
          const prevTableState = get().tableState;
          const prev = prevTableState[tableId].columnVisibility;

          if (isEmpty(prev)) {
            let visibleColumnIds = columns
              .filter(
                (c) =>
                  ![
                    SpecialColumns.ACTIONS,
                    SpecialColumns.GROUPING,
                    SpecialColumns.SELECT,
                  ].includes(c.id),
              )
              .map((c) => c.id)
              .slice(0, 8);

            const columnVisibility = visibleColumnIds.reduce((acc, id) => {
              acc[id] = true;
              return acc;
            }, {});

            columns
              .filter((c) => !visibleColumnIds.includes(c.id))
              .forEach((c) => {
                columnVisibility[c.id] = false;
              });

            pinnedColumns.left.forEach((id) => {
              columnVisibility[id] = true;
            });
            pinnedColumns.right.forEach((id) => {
              columnVisibility[id] = true;
            });

            state.tableState = {
              ...prevTableState,
              [tableId]: {
                ...prevTableState[tableId],
                columnVisibility,
                columnOrder: visibleColumnIds,
              },
            };
          }
        });
      },
    })),
    {
      name: "app-storage",
      version: persistedStateVersion,
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({
        sidebarCollapsed: state.sidebarCollapsed,
        tableState: state.tableState,
      }),
    },
  ),
);

export const useSidebarFiltersWithPath = () => {
  const { pathname } = useLocation();
  const searchTerms = useAppStore((state) => state.searchTerm);
  const filters = useAppStore((state) => state.sidebarFilters);
  const setFilters = useAppStore((state) => state.setSidebarFilters);
  const clearFilters = useAppStore((state) => state.clearSidebarFilter);
  const setSearchTerms = useAppStore((state) => state.setSearchTerm);

  const searchTerm = useMemo(
    () => searchTerms[pathname],
    [searchTerms, pathname],
  );
  const sidebarFilters = useMemo(
    () => filters[pathname] ?? {},
    [filters, pathname],
  );

  const setSidebarFilters = useCallback(
    (filters: Record<string, SidebarFilters>) => {
      setFilters(pathname, filters);
    },
    [setFilters, pathname],
  );

  const clearSidebarFilters = useCallback(() => {
    clearFilters(pathname);
  }, [clearFilters, pathname]);

  const setSearchTerm = useCallback(
    (searchTerm: string) => {
      setSearchTerms(pathname, searchTerm);
    },
    [setSearchTerms, pathname],
  );

  return {
    searchTerm,
    sidebarFilters,
    setSidebarFilters,
    clearSidebarFilters,
    setSearchTerm,
  };
};

const UPSERT_KEYWORDS = gql`
  ${KEYWORD_FRAGMENT}
  mutation UpsertComponentKeywords(
    $id: ID
    $componentId: ID!
    $name: String!
    $resourceId: ID
    $isDeleted: Boolean
  ) {
    upsertComponentKeywords(
      input: {
        _id: $id
        componentId: $componentId
        name: $name
        resourceId: $resourceId
        isDeleted: $isDeleted
      }
    ) {
      ...KeywordFragment
    }
  }
`;

interface UpsertKeywordsArgs {
  id?: string;
  componentId: string;
  name: string;
  resourceId?: string;
  isDeleted?: boolean;
  callback?: (keyword: any) => void;
}

export const useUpsertKeywords = () => {
  const [result, callUpsertKeywords] = useMutation(UPSERT_KEYWORDS);
  const upsertSidebarKeywords = useAppStore(
    (state) => state.upsertSidebarKeywords,
  );

  const upsertKeywords = async ({
    callback,
    ...variables
  }: UpsertKeywordsArgs) => {
    return callUpsertKeywords(variables).then(({ data }) => {
      if (callback && data?.upsertComponentKeywords) {
        callback(data.upsertComponentKeywords);
        upsertSidebarKeywords(
          variables.componentId,
          data.upsertComponentKeywords,
        );
      }
      return data?.upsertComponentKeywords;
    });
  };

  return {
    upsertKeywords,
    loading: result.fetching,
  };
};

const UPSERT_USER_WIDGETS = gql`
  ${WIDGET_FRAGMENT}
  mutation UpsertUserWidgets($input: UserWidgetInput!) {
    widget: upsertUserWidgets(input: $input) {
      ...WidgetFragment
    }
  }
`;

// urql mutation hook for upserting user widgets
export const useUpsertUserWidgets = () => {
  const [result, callUpsertUserWidgets] = useMutation(UPSERT_USER_WIDGETS);
  const setWidget = useDashboardStore((state) => state.setWidget);

  const upsertUserWidgets = async (variables: Record<string, any>) => {
    return callUpsertUserWidgets(variables).then(({ data }) => {
      if (data?.widget) {
        setWidget(data.widget);
      }
    });
  };

  return {
    upsertUserWidgets,
    loading: result.fetching,
  };
};

const CREATE_TIMELINE_ACTIVITY = gql`
  ${TIMELINE_ACTIVITY_FRAGMENT}
  mutation CreateTimelineActivity($input: TimelineActivityInput!) {
    createTimelineActivity(input: $input) {
      ...TimelineActivityFragment
    }
  }
`;

export const useCreateTimelineActivity = () => {
  const [result, callCreateTimelineActivity] = useMutation(
    CREATE_TIMELINE_ACTIVITY,
  );
  const addActivityTimeline = useAppStore((state) => state.addActivityTimeline);

  const createTimelineActivity = async (variables: Record<string, any>) => {
    return callCreateTimelineActivity(variables).then(({ data }) => {
      if (data?.createTimelineActivity) {
        addActivityTimeline(data.createTimelineActivity);
      }
      return data?.createTimelineActivity;
    });
  };

  return {
    createTimelineActivity,
    loading: result.fetching,
  };
};

const UPDATE_TIMELINE_ACTIVITY = gql`
  ${TIMELINE_ACTIVITY_FRAGMENT}
  mutation UpdateTimelineActivity($input: TimelineActivityUpdateInput!) {
    updateTimelineActivity(input: $input) {
      ...TimelineActivityFragment
    }
  }
`;

export const useUpdateTimelineActivity = () => {
  const [result, callUpdateTimelineActivity] = useMutation(
    UPDATE_TIMELINE_ACTIVITY,
  );
  const removeActivityTimeline = useAppStore(
    (state) => state.removeActivityTimeline,
  );
  const updateActivityTimeline = useAppStore(
    (state) => state.updateActivityTimeline,
  );

  const updateTimelineActivity = async (variables: Record<string, any>) => {
    return callUpdateTimelineActivity(variables).then(({ data }) => {
      if (data?.updateTimelineActivity) {
        data.updateTimelineActivity?.isDeleted
          ? removeActivityTimeline(data.updateTimelineActivity)
          : updateActivityTimeline(data.updateTimelineActivity);
      }
    });
  };

  return {
    updateTimelineActivity,
    loading: result.fetching,
  };
};
