import { CompanySlideOver, PersonSlideOver } from "@amenda-components/Contacts";
import { FC, ReactNode } from "react";
import {
  FormsEarlyLoader,
  SimilaritySearchBottomSheet,
} from "@amenda-components/Projects";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { Navigate, useLocation } from "react-router-dom";
import { NotFoundRoute, getSidebarNavigation } from "@amenda-constants";
import {
  useAppStore,
  useAuthStore,
  useSettingsStore,
} from "@amenda-domains/mutations";

import { GeneralPermissionTypes } from "@amenda-types";
import { Sidebar } from "@amenda-components/App";
import { SidebarFiltersWrapper } from "@amenda-components/Shared";
import { SidebarMobileMenu } from "@amenda-components/App/SidebarComponents";
import clsx from "clsx";
import dayjs from "dayjs";
import dayjsLocale from "dayjs/locale/de";
import { de } from "date-fns/locale/de";
import { registerLocale } from "react-datepicker";
import { shouldRedirectRestrictedPaths } from "./common";
import { useHandleKeyDown } from "@amenda-utils";

interface Props {
  children: ReactNode;
  hideSidebar?: boolean;
  isPrintView?: boolean;
}

export const Layout: FC<Props> = ({
  children,
  hideSidebar = false,
  isPrintView = false,
}) => {
  const { pathname } = useLocation();
  const isSignedIn = useAuthStore((state) => state.isSignedIn);
  const sidebarOpen = useAppStore((state) => state.isSidebarOpen);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  const adminGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Admin,
  );
  const sidebarNavigation = getSidebarNavigation(
    adminGeneralPermissions[GeneralPermissionTypes.FullAccess],
  );

  registerLocale("de-DE", de);
  dayjs.locale(dayjsLocale);

  useHandleKeyDown();

  if (!isSignedIn) {
    return <Navigate to="/login" />;
  } else if (shouldRedirectRestrictedPaths(pathname, permissions)) {
    return <Navigate to={NotFoundRoute} />;
  }
  return (
    <FormsEarlyLoader>
      <PersonSlideOver />
      <CompanySlideOver />
      {isPrintView ? (
        <>{children}</>
      ) : (
        <div className="flex h-screen overflow-hidden scroll-smooth bg-white">
          {!hideSidebar && <Sidebar navigation={sidebarNavigation} />}
          <div className="flex w-0 flex-1 flex-col overflow-hidden">
            <main className="relative flex flex-1 overflow-hidden focus:outline-none">
              <div
                className={clsx("h-full w-full", {
                  "min-w-0 flex-1 flex-col": !hideSidebar && sidebarOpen,
                })}
              >
                {!hideSidebar && (
                  <SidebarMobileMenu navigation={sidebarNavigation} />
                )}
                {children}
                <SimilaritySearchBottomSheet />
              </div>
              <SidebarFiltersWrapper />
            </main>
          </div>
        </div>
      )}
    </FormsEarlyLoader>
  );
};
