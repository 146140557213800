import { UpdatesConfig } from "@urql/exchange-graphcache";

const Query = "Query";

export const updates: UpdatesConfig = {
  Mutation: {
    assignSystemRoleToUser(result, _args, cache, _info) {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) =>
          ["getSystemRole", "getAllSystemRoles"].includes(fieldName),
        )
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    createContact: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllContacts")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    updateContact: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllContacts")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    updateUser: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllUsers")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    deleteAttachments(result, _args, cache, _info) {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllAttachments")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    updateTenant: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getCurrentTenant")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    upsertComponentKeywords: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getKeywords")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    createCostGroup: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCostGroups")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    updateCostGroup: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCostGroups")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    createProject(result, _args, cache, _info) {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllProjects")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    updateProject(result, _args, cache, _info) {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllProjects")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    upsertSystemRole(result, _args, cache, _info) {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllSystemRoles")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    deleteSystemRole(result, _args, cache, _info) {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllSystemRoles")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    createAttachment(result, _args, cache, _info) {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllAttachments")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    updateAttachment(result, _args, cache, _info) {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllAttachments")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    upsertUserWidgets(result, _args, cache, _info) {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getUserWidgets")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    deleteCostGroups(result, _args, cache, _info) {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCostGroups")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    deleteProjects(result, _args, cache, _info) {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllProjects")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    createMaterial: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllMaterials")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    deleteUsers(result, _args, cache, _info) {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllUsers")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    deleteContacts(result, _args, cache, _info) {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllContacts")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    updateMaterial: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllMaterials")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    createCollection: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCollections")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    updateCollection: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCollections")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    updateCollectionsResourceIds: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCollections")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    createTimelineActivity: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllTimelineActivities")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    updateTimelineActivity: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllTimelineActivities")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    createImageDescriptor: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllImageDescriptors")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    updateImageDescriptor: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllImageDescriptors")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    createForm: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllForms")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    updateForm: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllForms")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    deleteFormComponents: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllForms")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    updateMultipleForms: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllForms")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
  },
  Subscription: {
    attachmentCreated: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllAttachments")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    attachmentUpdated: (result, _args, cache, _info) => {
      const attachment = result.attachmentUpdated as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllAttachments")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });

      if (attachment.id) {
        cache
          .inspectFields(Query)
          .filter(
            (field) =>
              field.fieldName === "getAttachment" &&
              attachment.id === field.arguments?._id,
          )
          .forEach((field) => {
            cache.invalidate(Query, field.fieldKey);
          });
      }
    },
    attachmentDeleted: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllAttachments")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    projectCreated: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllProjects")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    projectUpdated: (result, _args, cache, _info) => {
      const project = result.projectUpdated as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllProjects")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });

      if (project.id) {
        cache
          .inspectFields(Query)
          .filter(
            (field) =>
              field.fieldName === "getProject" &&
              project.id === field.arguments?._id,
          )
          .forEach((field) => {
            cache.invalidate(Query, field.fieldKey);
          });
      }
    },
    projectDeleted: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllProjects")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    userCreated: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllUsers")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    userUpdated: (result, _args, cache, _info) => {
      const user = result.userUpdated as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllUsers")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });

      if (user.id) {
        cache
          .inspectFields(Query)
          .filter(
            (field) =>
              field.fieldName === "getUser" && user.id === field.arguments?._id,
          )
          .forEach((field) => {
            cache.invalidate(Query, field.fieldKey);
          });
      }
    },
    userDeleted: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllUsers")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    contactCreated: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllContacts")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    contactUpdated: (result, _args, cache, _info) => {
      const contact = result.contactUpdated as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllContacts")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });

      if (contact.id) {
        cache
          .inspectFields(Query)
          .filter(
            (field) =>
              field.fieldName === "getContact" &&
              contact.id === field.arguments?._id,
          )
          .forEach((field) => {
            cache.invalidate(Query, field.fieldKey);
          });
      }
    },
    contactDeleted: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllContacts")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    materialCreated: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllMaterials")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    materialUpdated: (result, _args, cache, _info) => {
      const material = result.materialUpdated as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllMaterials")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });

      if (material.id) {
        cache
          .inspectFields(Query)
          .filter(
            (field) =>
              field.fieldName === "getMaterial" &&
              material.id === field.arguments?._id,
          )
          .forEach((field) => {
            cache.invalidate(Query, field.fieldKey);
          });
      }
    },
    materialDeleted: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllMaterials")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    collectionCreated: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCollections")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    collectionUpdated: (result, _args, cache, _info) => {
      const collection = result.collectionUpdated as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCollections")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });

      if (collection.id) {
        cache
          .inspectFields(Query)
          .filter(
            (field) =>
              field.fieldName === "getCollection" &&
              collection.id === field.arguments?._id,
          )
          .forEach((field) => {
            cache.invalidate(Query, field.fieldKey);
          });
      }
    },
    collectionDeleted: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCollections")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    timelineActivityCreated: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllTimelineActivities")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    timelineActivityUpdated: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllTimelineActivities")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    timelineActivityDeleted: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllTimelineActivities")
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
  },
};
