import {
  Avatar,
  AvatarGroupWrapper,
  Chip,
  Skeleton,
} from "@amenda-components/App";
import { FC, useEffect, useState } from "react";
import {
  ResourceSharingPermissionTypes,
  checkCollectionPermissions,
} from "./common";
import { useGetKeywords, useGetUsersById } from "@amenda-domains/queries";

import { SingleCheckbox } from "@amenda-components/FormComponents";
import { getUserName } from "@amenda-components/Contacts/common";
import isEmpty from "lodash/isEmpty";
import { useUsersStore } from "@amenda-domains/mutations";

interface Props {
  collection: any;
  isSelected: boolean;
  handleSelect: () => void;
}

export const CollectionCard: FC<Props> = ({
  isSelected,
  collection,
  handleSelect,
}) => {
  const [users, setUsers] = useState<any[]>([]);
  const [keywords, setKeywords] = useState<any[]>([]);
  const { getUsersById } = useGetUsersById();
  const { getKeywords, loading } = useGetKeywords();
  const currentUser = useUsersStore((state) => state.currentUser);

  const slicedUsers = users.slice(0, 3);
  const hasCollectionPermissions =
    checkCollectionPermissions({
      collection,
      currentUser,
      permission: ResourceSharingPermissionTypes.Edit,
    }) ||
    checkCollectionPermissions({
      collection,
      currentUser,
      permission: ResourceSharingPermissionTypes.Admin,
    });

  useEffect(() => {
    if (hasCollectionPermissions && !isEmpty(collection?.keywords)) {
      getKeywords({
        ids: collection.keywords,
        setKeywords,
      });
    }
  }, [collection?.keywords, hasCollectionPermissions, getKeywords]);

  useEffect(() => {
    const userIds = collection.shares?.[0]?.userIds || [];

    if (userIds) {
      getUsersById(userIds, setUsers);
    }
  }, [getUsersById, collection.shares]);

  if (!hasCollectionPermissions) {
    return null;
  }
  return (
    <div className="relative h-full w-full">
      <div className="flex w-full flex-col justify-center border bg-white p-2">
        <div className="flex flex-row items-start justify-between">
          <div className="mt-px flex shrink-0 flex-row items-start gap-2">
            <SingleCheckbox
              id="selectCollection"
              checked={isSelected}
              onChange={handleSelect}
            />
            <div className="text-sm capitalize text-gray-800">
              {collection.name}
            </div>
          </div>
          <AvatarGroupWrapper>
            {slicedUsers.map((contact: any) => {
              return (
                <Avatar
                  key={contact.id}
                  src={contact?.photoURL}
                  name={getUserName(contact)}
                  className="h-10 min-h-10 w-10 min-w-10 border-2 border-white"
                  iconClassName="h-9 w-9"
                />
              );
            })}
            {users.length > slicedUsers.length && (
              <Avatar
                placeHolder={`+${users.length - slicedUsers.length}`}
                className="h-10 min-h-10 w-10 min-w-10 border-2 border-white"
                iconClassName="h-9 w-9"
              />
            )}
          </AvatarGroupWrapper>
        </div>
        <div className="mt-3 flex w-full flex-wrap items-center">
          {loading ? (
            <Skeleton height={5} />
          ) : (
            <>
              {keywords.map((keyword) => (
                <Chip
                  key={keyword.id}
                  className="mb-1 mr-1 bg-gray-200 text-gray-600 lg:text-xs"
                  label={keyword.name}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
