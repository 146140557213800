import { AllowedCollectionType, ReactTableKeys } from "@amenda-types";
import { ChangeEvent, FC, useCallback } from "react";
import { FormComponentTypes, PaginationLimit } from "@amenda-constants";
import {
  isSearchFilterEmpty,
  transformFilters,
} from "@amenda-components/Shared/common";
import {
  useAppStore,
  useFormStore,
  useProjectStore,
  useSettingsStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";
import { useGetAllProjects, useSearchProjects } from "@amenda-domains/queries";

import { ColumnDef } from "@tanstack/react-table";
import { ProgressIndicator } from "@amenda-components/App";
import { ReactTable } from "@amenda-components/Shared";
import { TableCellWrapper } from "@amenda-components/Shared/ReactTableComponents";
import { dynamicTableColumns } from "@amenda-components/PageBuilder/dynamicTableColumns";
import { getProjectArgs } from "./common";
import { isEmpty } from "lodash";
import { isRestricted } from "@amenda-utils";
import { useNavigate } from "react-router-dom";

interface Props {
  projects: any[];
  isCollection?: boolean;
  components: any[];
  componentsById: Record<string, any>;
}

const TableProgressIndicator: FC<{ row: any }> = ({ row: project }) => {
  const similarityScoreById = useProjectStore(
    (state) => state.similarityScoreById,
  );
  const selectedSimilarityScore = useProjectStore(
    (state) => state.selectedSimilarityScore,
  );

  const projectSimilarityScore = similarityScoreById[project.id];

  return (
    <TableCellWrapper className="justify-center">
      <ProgressIndicator
        showDefaultColor={projectSimilarityScore < selectedSimilarityScore}
        progress={projectSimilarityScore}
      />
    </TableCellWrapper>
  );
};

const getStaticColumns = ({
  permissions,
  componentsById,
  onClick,
}: {
  componentsById: Record<string, any>;
  permissions: Record<string, any>;
  onClick: (project: any) => (e: ChangeEvent<any>) => void;
}) => {
  const projectName = componentsById["name"];
  const projectNumber = componentsById["number"];
  const projectAddress = componentsById["address"];

  const columns: ColumnDef<any>[] = [
    {
      id: "name",
      minSize: 200,
      header: "Name",
      meta: {
        label: "Name",
        useCaseInsensitiveSort: true,
        showColumn: !isRestricted(projectName, permissions),
      },
      accessorFn: (row) => row?.name,
      cell: ({ row }) => {
        const project = row?.original;

        return (
          <TableCellWrapper isText={true} onClick={onClick(project)}>
            {project?.name}
          </TableCellWrapper>
        );
      },
    },
    {
      id: "number",
      minSize: 200,
      header: "Kürzel",
      meta: {
        label: "Kürzel",
        showColumn: !isRestricted(projectNumber, permissions),
      },
      accessorFn: (row) => row?.number,
      cell: ({ row }) => {
        const project = row?.original;

        return (
          <TableCellWrapper isText={true}>{project?.number}</TableCellWrapper>
        );
      },
    },
    {
      id: "address",
      minSize: 300,
      header: "Anschrift",
      meta: {
        label: "Anschrift",
        sortBy: "address.name",
        showColumn: !isRestricted(projectAddress, permissions),
      },
      accessorFn: (row) => row?.address?.name,
      cell: ({ row }) => {
        const project = row?.original;

        return (
          <TableCellWrapper isText={true}>
            {project?.address?.name}
          </TableCellWrapper>
        );
      },
    },
  ];

  return columns.filter((column) => Boolean((column?.meta as any)?.showColumn));
};

export const ProjectTableView: FC<Props> = ({
  projects,
  components,
  componentsById,
  isCollection,
}) => {
  const navigate = useNavigate();
  const setSelectedProject = useProjectStore(
    (state) => state.setSelectedProject,
  );
  const pagination = useProjectStore((state) => state.pagination);
  const selectedProjects = useProjectStore((state) => state.selectedProjects);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const setSelectedProjects = useProjectStore(
    (state) => state.setSelectedProjects,
  );
  const toggleSelectedProjects = useProjectStore(
    (state) => state.toggleSelectedProjects,
  );
  const { getAllProjects } = useGetAllProjects();
  const { searchProjects } = useSearchProjects();
  const isFetching = useProjectStore((state) => state.isFetching);
  const isSearching = useProjectStore((state) => state.isSearching);
  const similarProjectIds = useProjectStore(
    (state) => state.similarityResourceIds,
  );
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const { sidebarFilters, searchTerm } = useSidebarFiltersWithPath();
  const openSimilaritySearch = useProjectStore(
    (state) => state.openSimilaritySearch,
  );
  const columnSorting = useAppStore(
    (state) => state.tableState[ReactTableKeys.Projects].columnSorting,
  );

  const selectedCollection =
    selectedCollectionByType?.[AllowedCollectionType.Projects];
  const dynamicColumnIds = components
    .filter((component) => Boolean(component.component))
    .filter(
      (component) => !["name", "number", "address"].includes(component.id),
    )
    .map((component) => component.id);
  const dynamicColumns = dynamicTableColumns({
    permissions,
    componentsById,
    dynamicColumnIds,
    flattenData: (data = {}) => {
      return {
        ...data,
        ...(data?.formValues || {}),
      };
    },
    filterComponents: (component) => {
      return ![
        FormComponentTypes.SearchAndSelect,
        FormComponentTypes.LabelledContactInputs,
      ].includes(component.component);
    },
  });

  const handleClick = (project: any) => (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setSelectedProject(project);
    navigate(`/projekte/${project?.id}`);
  };

  const fetchNextPage = useCallback(async () => {
    if (
      isSearchFilterEmpty(sidebarFilters) &&
      isEmpty(searchTerm) &&
      pagination?.hasNext
    ) {
      await getAllProjects({
        columnSorting,
        isTableView: true,
        ...getProjectArgs({
          isCollection,
          similarProjectIds,
          collectionProjectIds: selectedCollection?.resourceIds,
        }),
        paginationProps: {
          next: pagination?.next,
          limit: PaginationLimit.projects,
        },
      });
    }
  }, [
    isCollection,
    columnSorting,
    similarProjectIds,
    searchTerm,
    sidebarFilters,
    pagination?.next,
    pagination?.hasNext,
    selectedCollection?.resourceIds,
    getAllProjects,
  ]);

  const handleFilter = async (columnSorting: any) => {
    if (!isEmpty(searchTerm) || !isSearchFilterEmpty(sidebarFilters)) {
      await searchProjects({
        searchTerm,
        isCollection,
        columnSorting,
        isTableView: true,
        filters: transformFilters(sidebarFilters),
        resourceIds: selectedCollection?.resourceIds,
      });
    } else {
      await getAllProjects({
        columnSorting,
        isTableView: true,
        ...getProjectArgs({
          isCollection,
          similarProjectIds,
          collectionProjectIds: selectedCollection?.resourceIds,
        }),
      });
    }
  };

  return (
    <ReactTable
      showSelectorColumn
      isFullWidth
      isConfigurable
      canDownload
      data={projects}
      isResizable={true}
      showDefaultColumn={true}
      hideActionColumn={!openSimilaritySearch}
      isLoading={isFetching || isSearching}
      maxEstimatedRowHeight={40}
      tableId={ReactTableKeys.Projects}
      containerClass="h-[calc(100vh-9rem)] w-full"
      selectedRowIds={selectedProjects}
      TableRowActions={TableProgressIndicator}
      pagination={pagination}
      handleToggleRowSelection={toggleSelectedProjects}
      handleToggleRowsSelection={setSelectedProjects}
      fetchNextPage={fetchNextPage}
      handleFilter={handleFilter}
      columns={[
        ...getStaticColumns({
          permissions,
          componentsById,
          onClick: handleClick,
        }),
        ...dynamicColumns,
      ]}
    />
  );
};
