import { Avatar, IconButton, Tooltip } from "@amenda-components/App";
import { CheckIcon, CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { FC, memo } from "react";
import {
  FormAutoSaveSubmitProps,
  FormAutoSaveWrapperBase,
} from "@amenda-components/PageBuilder";
import {
  useAppStore,
  useCreateTimelineActivity,
  useUpdateTimelineActivity,
  useUsersStore,
} from "@amenda-domains/mutations";

import { AllowedTimelineTypes } from "@amenda-types";
import { TimelineBody } from "./TimelineBody";
import { activityTimelineSchema } from "@amenda-constants";
import clsx from "clsx";
import { getActivityTimelineFormValues } from "./common";
import { getUserName } from "@amenda-components/Contacts/common";
import { isEmpty } from "lodash";
import { sanitizeData } from "@amenda-utils";
import { useTranslation } from "react-i18next";

interface Props {
  resourceId: string;
  type: AllowedTimelineTypes;
  isLastActivity?: boolean;
  textAreaClassName?: string;
  handleCloseEdit?: () => void;
}

export const EditTimelineTextArea: FC<Props> = memo(
  ({
    type,
    textAreaClassName,
    resourceId: timelineResourceId,
    isLastActivity = false,
    handleCloseEdit,
  }) => {
    const { t } = useTranslation();
    const currentUser = useUsersStore((state) => state.currentUser);
    const { createTimelineActivity, loading } = useCreateTimelineActivity();
    const { updateTimelineActivity, loading: updateTimelineLoader } =
      useUpdateTimelineActivity();
    const selectedActivityTimeline = useAppStore(
      (state) => state.selectedActivityTimeline,
    );
    const {
      searchTerm,
      caretPosition,
      cursorPosition,
      showTimelineContactsSearch,
    } = useAppStore((state) => state.timelineEditValues);
    const setTimelineEditValues = useAppStore(
      (state) => state.setTimelineEditValues,
    );

    const setTimelineContactsSearch = (show: boolean) =>
      setTimelineEditValues("showTimelineContactsSearch", show);
    const setCursorPosition = (position: number) =>
      setTimelineEditValues("cursorPosition", position);
    const setCaretPosition = (position: { top: number; left: number }) =>
      setTimelineEditValues("caretPosition", position);
    const setSearchTerm = (searchTerm: string) =>
      setTimelineEditValues("searchTerm", searchTerm);

    const isLoading = loading || updateTimelineLoader;

    const onSubmit = async ({
      dirtyData,
      resourceId,
    }: FormAutoSaveSubmitProps) => {
      let input = sanitizeData({
        ...dirtyData,
        date: new Date().toISOString(),
      });
      if (isEmpty(dirtyData)) {
        return;
      }

      if (resourceId) {
        input = {
          ...input,
          _id: resourceId,
        };

        await updateTimelineActivity({ input });
      } else {
        input = {
          ...input,
          type,
          resourceId: timelineResourceId,
          createdById: currentUser?.id,
        };

        await createTimelineActivity({ input });
      }
    };

    return (
      <div className="flex w-full flex-col items-start gap-px">
        <div className="mr-0 flex w-full flex-col gap-3 md:flex-row">
          <div className="mt-1">
            <Avatar
              src={currentUser?.photoURL}
              className="h-10 min-h-10 w-10 min-w-10"
              iconClassName="h-9 w-9"
              name={getUserName(currentUser)}
            />
          </div>
          <div className="flex grow flex-col gap-1">
            <div className="flex items-center justify-between">
              <div className="text-sm text-gray-600">
                {t("Add an activity")}
              </div>
            </div>
            <FormAutoSaveWrapperBase
              resourceId={selectedActivityTimeline?.id}
              inputSchema={activityTimelineSchema}
              values={getActivityTimelineFormValues(selectedActivityTimeline)}
              onSubmit={onSubmit}
              className="py-0"
            >
              {({ control }) => (
                <>
                  {control && (
                    <TimelineBody
                      control={control}
                      searchTerm={searchTerm}
                      caretPosition={caretPosition}
                      cursorPosition={cursorPosition}
                      textAreaClassName={textAreaClassName}
                      showTimelineContactsSearch={showTimelineContactsSearch}
                      setTimelineContactsSearch={setTimelineContactsSearch}
                      setCursorPosition={setCursorPosition}
                      setCaretPosition={setCaretPosition}
                      setSearchTerm={setSearchTerm}
                    />
                  )}
                </>
              )}
            </FormAutoSaveWrapperBase>
            <div className="flex items-center justify-end space-x-1">
              <Tooltip id="autoSave" message="Your Data is automatically saved">
                <IconButton
                  iconSize={1}
                  Icon={CloudArrowUpIcon}
                  label="Autosave"
                  className={clsx("hover:bg-transparent", {
                    "animate-pulse text-green-600": isLoading,
                  })}
                  variant="base"
                />
              </Tooltip>
              {handleCloseEdit && (
                <Tooltip id="back" message="Save Comment">
                  <IconButton
                    iconSize={1}
                    Icon={CheckIcon}
                    label="Save and Close"
                    className="bg-green-100 hover:bg-green-300"
                    onClick={handleCloseEdit}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        {!isLastActivity && (
          <div className="ml-5 h-6 w-px border-l border-dashed border-gray-300" />
        )}
      </div>
    );
  },
);
