import { AddComponentButton, SortItem } from "./DragAndDropWrapper";
import { GripIcon, PencilIcon } from "lucide-react";

import { FC } from "react";
import { GridWrapper } from "./GridWrapper";
import { IconButtonBase } from "@amenda-components/App";
import { LayoutProps } from "./common";
import clsx from "clsx";
import { isChildrenEmpty } from "@amenda-utils";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export const TwoColumnsFullWidth: FC<LayoutProps> = ({
  children,
  isFormBuilder,
  isPrintView,
  config,
}) => {
  const { t } = useTranslation();
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );

  const { label, description } = config?.properties ?? {};

  const handleEdit = () => {
    updateFormBuilderState("openEditComponentModal", true);
    updateFormBuilderState("selectedFormComponent", {
      config,
    });
  };

  return (
    <SortItem
      className={clsx("group/two-col-full-width relative", {
        "py-5 md:grid md:grid-cols-3 md:gap-6": !isPrintView,
        "border-b border-gray-300 pb-4": isPrintView,
      })}
      config={config}
      isFormBuilder={isFormBuilder}
      showBorder={isChildrenEmpty(children)}
    >
      <div className="md:col-span-1">
        {isFormBuilder && (
          <div
            className={clsx("flex items-center space-x-2 pb-2", {
              "invisible group-hover/two-col-full-width:visible":
                !isChildrenEmpty(children),
            })}
          >
            <IconButtonBase
              size="sm"
              className="cursor-grab px-0.5 py-1"
              variant="clean"
            >
              <GripIcon className="h-4 w-4 stroke-[1.5]" />
            </IconButtonBase>
            <IconButtonBase
              size="sm"
              className="p-1"
              variant="clean"
              onClick={handleEdit}
            >
              <PencilIcon className="h-4 w-4" />
            </IconButtonBase>
            <div>
              <AddComponentButton
                isFormBuilder={isFormBuilder}
                config={config}
              />
            </div>
          </div>
        )}
        {label && (
          <h3
            className={clsx({
              "amenda-form-heading": !isPrintView && !isFormBuilder,
              "text-sm": isFormBuilder,
              "text-lg": isPrintView,
            })}
          >
            {t(label)}
          </h3>
        )}
        {description && (
          <p
            className={clsx("mt-1 text-gray-600", {
              "text-sm": !isFormBuilder,
              "text-xs": isFormBuilder,
            })}
          >
            {t(description)}
          </p>
        )}
      </div>
      <div
        className={clsx({
          "mt-5 md:col-span-2 md:mt-0": !isPrintView,
          "mt-5": isPrintView,
        })}
      >
        <GridWrapper isFormBuilder={isFormBuilder} config={config}>
          {children}
        </GridWrapper>
      </div>
    </SortItem>
  );
};
