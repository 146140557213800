import { FC, useEffect } from "react";

import { PaginationLimit } from "@amenda-constants";
import { ReactTable } from "@amenda-components/Shared";
import { ReactTableKeys } from "@amenda-types";
import { TableCellWrapper } from "@amenda-components/Shared/ReactTableComponents";
import { getRegionalFactorYears } from "../common";
import { useGetAllRegionalFactors } from "@amenda-domains/queries";
import { useProjectStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export const RegionalFactorsTable: FC = () => {
  const { t } = useTranslation();
  const { getAllRegionalFactors, loading } = useGetAllRegionalFactors();
  const regionalFactors = useProjectStore((state) => state.regionalFactors);
  const regionalFactorsPagination = useProjectStore(
    (state) => state.regionalFactorsPagination,
  );

  const regionalFactorYears = getRegionalFactorYears();

  const fetchNextPage = async () => {
    if (regionalFactorsPagination?.hasNext) {
      await getAllRegionalFactors({
        isTableView: true,
        limit: PaginationLimit.regionalFactors,
        next: regionalFactorsPagination?.next,
        selectRegionalFactorFields: {
          city: 1,
          factors: 1,
        },
      });
    }
  };

  const handleFilter = async (columnSorting: any) => {
    await getAllRegionalFactors({
      columnSorting,
      isTableView: true,
      limit: PaginationLimit.regionalFactors,
      selectRegionalFactorFields: {
        city: 1,
        factors: 1,
      },
    });
  };

  useEffect(() => {
    getAllRegionalFactors({
      isTableView: true,
      limit: PaginationLimit.regionalFactors,
      selectRegionalFactorFields: {
        city: 1,
        factors: 1,
      },
    });
  }, [getAllRegionalFactors]);

  return (
    <ReactTable
      data={regionalFactors}
      isLoading={loading}
      isFullWidth
      defaultPinnedColumns={{
        left: ["city"],
      }}
      tableId={ReactTableKeys.RegionalFactors}
      pagination={regionalFactorsPagination}
      containerClass="h-[calc(100vh-15rem)] w-full"
      fetchNextPage={fetchNextPage}
      handleFilter={handleFilter}
      columns={[
        {
          id: "city",
          minSize: 20,
          header: t("City"),
          meta: {
            label: t("City"),
          },
          accessorKey: "city",
          cell: ({ row }: any) => {
            const regionalFactor = row?.original;

            return (
              <TableCellWrapper isText={true}>
                {regionalFactor?.city}
              </TableCellWrapper>
            );
          },
        },
        ...regionalFactorYears.map((year) => ({
          id: String(year),
          minSize: 20,
          header: String(year),
          meta: {
            label: String(year),
            isNumber: true,
          },
          accessorKey: String(year),
          cell: ({ row }: any) => {
            const regionalFactor = row?.original;

            return (
              <TableCellWrapper>
                {regionalFactor?.[String(year)]}
              </TableCellWrapper>
            );
          },
        })),
      ]}
    />
  );
};
