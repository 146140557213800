import { CSSProperties, FC, Fragment, KeyboardEvent, ReactNode } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";

import clsx from "clsx";
import { useTranslation } from "react-i18next";

const sizes = {
  sm: "max-w-[400px]",
  md: "max-w-[800px]",
  lg: "max-w-[1200px] h-full",
  xl: "lg:w-8/12 h-full",
  full: "fixed w-full h-full top-0 left-0",
};

export const ModalBaseTitle: FC<{
  title: string;
  className?: string;
}> = ({ title, className }) => {
  const { t } = useTranslation();

  return (
    <DialogTitle
      as="h3"
      className={clsx("text-lg leading-6 text-gray-900", className)}
    >
      {t(title)}
    </DialogTitle>
  );
};

interface ModalBarebonesProps {
  isOpen: boolean;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  isElevated?: boolean;
  showShadow?: boolean;
  onClose: () => void;
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
}

export const ModalBarebones: FC<ModalBarebonesProps> = ({
  isOpen,
  children,
  className,
  style,
  isElevated = false,
  showShadow = false,
  onClose,
  onKeyDown,
}) => {
  return (
    <Transition show={isOpen}>
      <Dialog
        as="div"
        className="relative"
        onClose={onClose}
        onKeyDown={onKeyDown}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={clsx(
              "fixed inset-0 bg-black/10 backdrop-blur-sm transition",
              {
                "z-50": !isElevated,
                "z-[80]": isElevated,
              },
            )}
          />
        </TransitionChild>
        <div
          className={clsx("fixed inset-0 overflow-y-hidden", {
            "z-[70]": !isElevated,
            "z-[80]": isElevated,
          })}
        >
          <TransitionChild
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className={className}
            style={style}
          >
            <DialogPanel
              className={clsx("h-full w-full border-white bg-white", {
                "shadow-lg": showShadow,
              })}
            >
              {children}
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export const ModalBase: FC<
  ModalBarebonesProps & {
    size?: "md" | "lg" | "sm" | "full" | "xl";
    className?: string;
  }
> = ({ children, className, size = "sm", ...rest }) => {
  const modalSize = sizes[size];

  return (
    <ModalBarebones
      className={clsx(className, {
        [modalSize]: !className,
        "h-screen": size === "full",
        "mx-auto flex min-h-full items-center justify-center": size !== "full",
      })}
      showShadow={size !== "full"}
      {...rest}
    >
      {children}
    </ModalBarebones>
  );
};
