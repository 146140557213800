import { FC, useMemo } from "react";

import { AvailableForms } from "@amenda-constants";
import { ProjectFormPrintView } from "@amenda-components/Projects";
import { useProjectStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export const ProjectPrintView: FC<{ loading: boolean }> = ({ loading }) => {
  const { t } = useTranslation();
  const forms = useProjectStore((state) => state.forms);

  const projectForms = forms?.[AvailableForms.Project];
  const availableSections = useMemo(() => {
    const sections =
      projectForms?.map(({ id, components, name }) => ({
        id,
        components,
        label: name,
      })) || [];

    return sections;
  }, [projectForms]);

  return (
    <div className="w-full">
      {availableSections.map((section) => {
        return (
          <div key={section.id} className="amenda-roman-list">
            <h2 className="w-full pb-2 pt-4 font-sans text-2xl text-gray-900 first-letter:font-heading first-letter:text-3xl">
              {t(section.label)}
            </h2>
            <ProjectFormPrintView
              components={section.components}
              loading={loading}
            />
          </div>
        );
      })}
    </div>
  );
};
