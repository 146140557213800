import { Cell, ColumnDef, Header, Table } from "@tanstack/react-table";
import { isEmpty, isFunction } from "lodash";

import { AllowedContactType } from "@amenda-types";
import { FormComponentTypes } from "@amenda-constants";
import Papa from "papaparse";
import { formatDate } from "@amenda-utils";
import { getUserName } from "@amenda-components/Contacts/common";
import xlsx from "json-as-xlsx";

export enum SpecialColumns {
  SELECT = "select",
  ACTIONS = "userActions",
  GROUPING = "grouping",
}

export const tableDownloadOptions = [
  {
    value: "excel",
    label: "Excel",
  },
  {
    value: "csv",
    label: "CSV",
  },
];

export const isLastHeader = (index: number, headers: any[]) => {
  return index + 1 === headers.length;
};

const getStickyPosition = (
  items: Header<any, unknown>[] | Cell<any, unknown>[],
  i: number,
) => {
  const prevItems = items.slice(0, i);
  const currItem = items[i];
  let left = 0;
  prevItems.forEach((item) => {
    if (item.column.getIsPinned()) {
      left += item.column.getSize();
    }
  });

  if (currItem.column.id === SpecialColumns.ACTIONS) {
    return {
      right: 0,
    };
  }
  return {
    left,
  };
};

export const getHeaderStickyPosition = (
  headers: Header<any, unknown>[],
  index: number,
) => {
  const currHeader = headers[index];
  if (currHeader.column.getIsPinned()) {
    return getStickyPosition(headers, index);
  }
  return {};
};

export const getCellStickyPosition = (
  cells: Cell<any, unknown>[],
  index: number,
) => {
  const currCell = cells[index];
  if (currCell.column.getIsPinned()) {
    return getStickyPosition(cells, index);
  }
  return {};
};

const getPinnedClassName = (
  items: Cell<any, unknown>[] | Header<any, unknown>[],
  i: number,
) => {
  let pinnedItem: Cell<any, unknown> | Header<any, unknown> | undefined;

  items.forEach((item) => {
    if (
      item.column.getIsPinned() &&
      item.column.id !== SpecialColumns.ACTIONS
    ) {
      pinnedItem = item;
    }
  });

  const currItem = items[i];
  if (pinnedItem && pinnedItem.column.id === currItem.column.id) {
    return "border-r";
  } else if (currItem.column.id === SpecialColumns.ACTIONS) {
    return "border-l";
  }
  return "";
};

export const getPinnedCellClassName = (
  cells: Cell<any, unknown>[],
  index: number,
) => {
  return getPinnedClassName(cells, index);
};

export const getPinnedHeaderClassName = (
  headers: Header<any, unknown>[],
  index: number,
) => {
  return getPinnedClassName(headers, index);
};

export const isSelectColumn = (id: string) =>
  [SpecialColumns.SELECT, SpecialColumns.ACTIONS].includes(
    id as SpecialColumns,
  );

export const getPadding = (virtualRows: any[], totalSize: number) => {
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  return {
    paddingBottom,
    paddingTop: virtualRows?.[0]?.start || 0,
  };
};

export const getTableSize = (table: Table<any>, isFullWidth: boolean) => {
  const width = isFullWidth ? "100%" : table.getCenterTotalSize();

  return {
    width,
  };
};

export const getDefaultPinnedColumns = ({
  showSelectorColumn,
  showUserActionColumn,
  defaultPinnedColumns,
  showGroupingColumn,
}: {
  showGroupingColumn: boolean;
  showSelectorColumn: boolean;
  showUserActionColumn: boolean;
  defaultPinnedColumns?: { left?: string[]; right?: string[] };
}) => {
  let left = showGroupingColumn
    ? [SpecialColumns.GROUPING, ...(defaultPinnedColumns?.left || [])]
    : [...(defaultPinnedColumns?.left || [])];
  const right = showUserActionColumn
    ? [SpecialColumns.ACTIONS, ...(defaultPinnedColumns?.right || [])]
    : [...(defaultPinnedColumns?.right || [])];

  if (showSelectorColumn) {
    left = [SpecialColumns.SELECT, ...left];
  }

  return {
    left,
    right,
  };
};

const fileDownloader = (fileName: string, blob: Blob) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");

  a.setAttribute("href", url);
  a.setAttribute("download", fileName);
  a.click();
};

interface DownloadCsvOrExcelArgs {
  data: any[];
  value: "csv" | "excel";
  componentsById: Record<string, any>;
  visibleColumns: Record<string, boolean>;
  availableColumns: ColumnDef<any>[];
  isProject: boolean;
  sheetName?: string;
  getKeywords: (args: any) => Promise<any[]>;
  getUsersById: (userIds: string[]) => Promise<any[]>;
  getContactsById: (contactIds: string[]) => Promise<any[]>;
  getAllRegionalFactors: (args: any) => Promise<any[]>;
  getAllProjects: (args: any) => Promise<any[]>;
}

const getColumnHeaders = ({
  visibleColumns,
  availableColumns,
  isProject,
  componentsById,
}: Pick<
  DownloadCsvOrExcelArgs,
  "availableColumns" | "visibleColumns" | "componentsById" | "isProject"
>) => {
  const columnHeaders: any[] = [];

  const defaultColumn = availableColumns.find((c) => c.id === "name");
  if (defaultColumn) {
    columnHeaders.push({
      id: "name",
      value: "name",
      label: (defaultColumn.meta as any)?.label || defaultColumn.id,
    });
  }

  Object.keys(visibleColumns)
    .filter((key) => key !== "name")
    .forEach((key) => {
      const component = componentsById[key];
      const column = availableColumns.find((ac) => ac.id === key);

      if (column && Boolean((column.meta as any)?.isPhotoUrl)) {
        const id = isProject ? "galleryUrl" : "url";
        columnHeaders.push({
          id,
          value: id,
          label: (column.meta as any)?.label || column.id,
        });
      } else if (component) {
        if (component.component === FormComponentTypes.AddressSearch) {
          columnHeaders.push({
            id: column!.id,
            label: (column?.meta as any)?.label || column?.id || key,
            value: (row: any) => row[key] && row[key].name,
          });
        } else if (component.component === FormComponentTypes.DatePicker) {
          columnHeaders.push({
            id: column!.id,
            label: (column?.meta as any)?.label || column?.id || key,
            value: (row: any) => row[key] && formatDate(row[key]),
          });
        } else if (component.component === FormComponentTypes.DatePickerRange) {
          columnHeaders.push({
            id: column!.id,
            label: (column?.meta as any)?.label || column?.id || key,
            value: (row: any) =>
              row[key] &&
              [row[key]?.to, row[key]?.from]
                ?.filter(Boolean)
                ?.map((date) => formatDate(date))
                .join(" - "),
          });
        } else if (component.component === FormComponentTypes.Switch) {
          columnHeaders.push({
            id: column!.id,
            label: (column?.meta as any)?.label || column?.id || key,
            value: (row: any) => (Boolean(row[key]) ? "Ja" : "Nein"),
          });
        } else if (
          [
            FormComponentTypes.Badges,
            FormComponentTypes.MultiSelect,
            FormComponentTypes.Select,
            FormComponentTypes.ColoredSelect,
            FormComponentTypes.Checkbox,
            FormComponentTypes.RadioButton,
          ].includes(component.component)
        ) {
          columnHeaders.push({
            id: column!.id,
            label: (column?.meta as any)?.label || column?.id || key,
            value: (row: any, isCsv = false) => {
              const options: any[] = component.properties?.options ?? [];
              const resolvedOptions = (
                Array.isArray(row[key]) ? row[key] : [row[key]]
              )
                .map((id: any) => {
                  const option = options.find((o) => o.value === id);

                  return option?.label;
                })
                .filter(Boolean);

              return isCsv ? resolvedOptions : resolvedOptions.join(", ");
            },
          });
        } else {
          columnHeaders.push({
            id: column!.id,
            label: (column?.meta as any)?.label || column?.id || key,
            value: (row: any, isCsv = false) => {
              const val =
                Array.isArray(row[key]) && !isCsv
                  ? row[key].join(", ")
                  : row[key];

              return val;
            },
          });
        }
      } else {
        columnHeaders.push({
          id: column!.id,
          label: (column?.meta as any)?.label || column?.id || key,
          value: (row: any, isCsv = false) => {
            const val =
              Array.isArray(row[key]) && !isCsv
                ? row[key].join(", ")
                : row[key];

            return val;
          },
        });
      }
    });

  return columnHeaders;
};

const enrichAndTransformData = async ({
  data,
  componentsById,
  getAllProjects,
  getAllRegionalFactors,
  getContactsById,
  getUsersById,
  getKeywords,
}: Pick<
  DownloadCsvOrExcelArgs,
  | "data"
  | "getKeywords"
  | "getAllProjects"
  | "getAllRegionalFactors"
  | "getContactsById"
  | "getUsersById"
  | "componentsById"
>) => {
  const projectIds: string[] = [];
  const contactIds: string[] = [];
  const userIds: string[] = [];
  const regionalFactorIds: string[] = [];
  const resolvableFieldIds: string[] = [];

  let processedData = data.map((d) => {
    const fd = { ...d, ...(d?.formValues ?? {}) };

    Object.keys(fd).forEach((key) => {
      const component = componentsById[key];

      if (component) {
        if (
          fd[key] &&
          component.component === FormComponentTypes.SearchAndSelectProjects
        ) {
          Array.isArray(fd[key])
            ? projectIds.push(...fd[key].filter(Boolean))
            : projectIds.push(fd[key]);
          resolvableFieldIds.push(d.id);
        } else if (
          fd[key] &&
          component.component === FormComponentTypes.Keyword
        ) {
          resolvableFieldIds.push(d.id);
        } else if (
          fd[key] &&
          component.component === FormComponentTypes.RegionalSelect
        ) {
          Array.isArray(fd[key])
            ? regionalFactorIds.push(...fd[key].filter(Boolean))
            : regionalFactorIds.push(fd[key]);
          resolvableFieldIds.push(d.id);
        } else if (
          fd[key] &&
          [
            FormComponentTypes.SearchAndSelect,
            FormComponentTypes.LabelledContactInputs,
          ].includes(component.component) &&
          component?.properties?.contactType
        ) {
          component.properties.contactType === AllowedContactType.office
            ? userIds.push(
                ...(Array.isArray(fd[key]) ? fd[key] : [fd[key]])
                  .map((c: any) => c?.contactId)
                  .filter(Boolean),
              )
            : contactIds.push(
                ...(Array.isArray(fd[key]) ? fd[key] : [fd[key]])
                  .map((c: any) => c?.contactId)
                  .filter((id: any) => id && id.length < 25),
              );
          resolvableFieldIds.push(d.id);
        }
      }
    });

    return fd;
  });

  const keywordComponentIds = Object.keys(componentsById).filter(
    (key) => componentsById[key]?.component === FormComponentTypes.Keyword,
  );

  let keywords: any[] = [];
  if (keywordComponentIds.length) {
    keywords =
      (await getKeywords({
        componentIds: keywordComponentIds,
      })) ?? [];
  }

  let regionalFactors: any[] = [];
  if (regionalFactorIds.length) {
    regionalFactors =
      (await getAllRegionalFactors({
        skipStorage: true,
        ids: regionalFactorIds,
      })) ?? [];
  }

  let contacts: any[] = [];
  if (contactIds.length) {
    contacts = (await getContactsById(contactIds)) ?? [];
  }

  let users: any[] = [];
  if (userIds.length) {
    users = (await getUsersById(userIds)) ?? [];
  }

  let projects: any[] = [];
  if (projectIds.length) {
    projects =
      (await getAllProjects({
        ids: projectIds,
        limit: projectIds.length,
      })) ?? [];
  }

  const stack: {
    current: Record<string, any>;
    resolveFields: string[];
  }[] = [];
  const enrichedData: any[] = [];

  processedData
    .filter((d) => resolvableFieldIds.includes(d.id))
    .forEach((d) => {
      const resolveFields: string[] = [];
      Object.keys(d).forEach((key) => {
        const component = componentsById[key];

        if (component) {
          if (
            d[key] &&
            component.component === FormComponentTypes.SearchAndSelectProjects
          ) {
            resolveFields.push(key);
          } else if (
            d[key] &&
            component.component === FormComponentTypes.Keyword
          ) {
            resolveFields.push(key);
          } else if (
            d[key] &&
            component.component === FormComponentTypes.RegionalSelect
          ) {
            resolveFields.push(key);
          } else if (
            d[key] &&
            [
              FormComponentTypes.SearchAndSelect,
              FormComponentTypes.LabelledContactInputs,
            ].includes(component.component) &&
            component?.properties?.contactType
          ) {
            resolveFields.push(key);
          }
        }
      });

      stack.push({
        current: d,
        resolveFields,
      });
    });

  while (stack.length > 0) {
    const { resolveFields, current } = stack.pop()!;

    if (resolveFields.length === 0) {
      enrichedData.push(current);
      continue;
    }

    const [field, ...nextFields] = resolveFields;
    if (current[field] && !isEmpty(current[field])) {
      const fields: any[] = Array.isArray(current[field])
        ? current[field]
        : [current[field]];
      const component = componentsById[field];

      if (component.component === FormComponentTypes.Keyword) {
        stack.push({
          current: {
            ...current,
            [field]: keywords
              .filter((k) => fields.includes(k.id))
              .map((k) => k.name),
          },
          resolveFields: nextFields,
        });
      } else if (component.component === FormComponentTypes.RegionalSelect) {
        stack.push({
          current: {
            ...current,
            [field]: regionalFactors
              .filter((r) => fields.includes(r.id))
              .map((r) => r.city),
          },
          resolveFields: nextFields,
        });
      } else if (
        component.component === FormComponentTypes.SearchAndSelectProjects
      ) {
        stack.push({
          current: {
            ...current,
            [field]: projects
              .filter((p) => fields.includes(p.id))
              .map((r) => r.name),
          },
          resolveFields: nextFields,
        });
      } else {
        const allUsers =
          component?.properties?.contactType === AllowedContactType.office
            ? users
            : contacts;

        stack.push({
          current: {
            ...current,
            [field]: allUsers
              .filter((u) => fields.includes(u?.contactId ?? u))
              .map((u) => getUserName(u)),
          },
          resolveFields: nextFields,
        });
      }
    } else {
      stack.push({
        current,
        resolveFields: nextFields,
      });
    }
  }

  return [
    ...enrichedData,
    ...processedData.filter((d) => !resolvableFieldIds.includes(d.id)),
  ];
};

const getCSVData = (data: any[], columnHeaders: any[]) => {
  const csvData: any[][] = [];

  data.forEach((d) => {
    const row: any[] = [];

    columnHeaders.forEach((c) => {
      const column = isFunction(c.value) ? c.value(d, true) : d[c.value];
      row.push(column);
    });

    csvData.push(row);
  });

  return csvData;
};

const convertToCSV = ({
  sheetName,
  columnHeaders,
  enrichedData,
}: {
  enrichedData: any[];
  sheetName?: string;
  columnHeaders: { value: string; label: string }[];
}) => {
  const date = new Date().toISOString();
  const data = getCSVData(enrichedData, columnHeaders);
  const csv = Papa.unparse({
    data,
    fields: columnHeaders.map((header) => header.label),
  });
  let name = sheetName ?? "Amenda Table";
  name = name + formatDate(date, true, "HH.mm");

  const blob = new Blob([csv], { type: "text/csv" });
  fileDownloader(`${name}.csv`, blob);
};

const convertToExcel = ({
  sheetName,
  columnHeaders,
  enrichedData: content,
}: {
  enrichedData: any[];
  sheetName?: string;
  columnHeaders: { value: string; label: string }[];
}) => {
  const date = new Date().toISOString();
  let name = sheetName ?? "Amenda Table";
  name = name + formatDate(date, true, "HH.mm");
  const settings = {
    fileName: name,
    extraLength: 3, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
    RTL: false,
  };
  const data = [
    {
      content,
      sheet: name,
      columns: columnHeaders,
    },
  ];

  xlsx(data, settings);
};

export const downloadCsvOrExcel = async ({
  data,
  value,
  visibleColumns,
  availableColumns,
  componentsById,
  isProject,
  sheetName,
  ...rest
}: DownloadCsvOrExcelArgs) => {
  const columnHeaders = getColumnHeaders({
    availableColumns,
    visibleColumns,
    isProject,
    componentsById,
  });
  const enrichedData = await enrichAndTransformData({
    data,
    componentsById,
    ...rest,
  });

  const updatedColumnHeaders: any[] = [];
  columnHeaders.forEach((c) => {
    if (
      enrichedData.some(
        (data) => Number.isFinite(data[c.id]) || !isEmpty(data[c.id]),
      )
    ) {
      updatedColumnHeaders.push(c);
    }
  });

  if (value === "csv") {
    convertToCSV({
      sheetName,
      enrichedData,
      columnHeaders: updatedColumnHeaders,
    });
  } else {
    convertToExcel({
      sheetName,
      enrichedData,
      columnHeaders: updatedColumnHeaders,
    });
  }
};
