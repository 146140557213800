import { BackButton, Button } from "@amenda-components/App";
import {
  CreateOrEditRoleForm,
  SettingsLayout,
} from "@amenda-components/Settings";
import { FC, useEffect, useState } from "react";
import {
  useSettingsStore,
  useUpsertSystemRole,
} from "@amenda-domains/mutations";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const formId = "permissionsPickerForm";

export const CreateOrEditRole: FC = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const { upsertSystemRole, loading } = useUpsertSystemRole();
  const clearSelectedSystemRole = useSettingsStore(
    (state) => state.clearSelectedSystemRole,
  );
  const { roleId } = useParams<{ roleId?: string }>();
  const selectedSystemRole = useSettingsStore(
    (state) => state.selectedSystemRole,
  );

  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);

  useEffect(() => {
    return () => {
      clearSelectedSystemRole();
    };
  }, [clearSelectedSystemRole]);

  return (
    <SettingsLayout>
      <div className="sticky top-0 z-10 w-full bg-white">
        <BackButton label="Back to Roles" path="/admin/rollen" />
        <div className="flex items-center justify-between pt-6">
          <div>
            <h1 className="amenda-settings-heading">
              {t(roleId ? "Edit Role" : "Add Role")}
            </h1>
          </div>
          <div className="flex items-center space-x-1">
            {roleId && !Boolean(selectedSystemRole?.isDefaultRole) && (
              <Button
                size="md"
                form={formId}
                loading={loading}
                onClick={handleOpenModal}
              >
                {t("Delete Role")}
              </Button>
            )}
            <Button
              type="submit"
              variant="primary"
              size="md"
              form={formId}
              loading={loading}
            >
              {t("Save")}
            </Button>
          </div>
        </div>
      </div>
      <CreateOrEditRoleForm
        openModal={openModal}
        roleId={roleId}
        formId={formId}
        handleCloseModal={handleCloseModal}
        handleSystemRole={upsertSystemRole}
      />
    </SettingsLayout>
  );
};
