import { FC, useEffect, useState } from "react";
import { PencilIcon, TrashIcon } from "lucide-react";
import {
  useAppStore,
  useProjectStore,
  useUpdateForm,
} from "@amenda-domains/mutations";

import { IconButtonBase } from "@amenda-components/App";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
  onTitleUpdate: (text?: string) => Promise<void>;
}

const FormTitle: FC<Props> = ({ title, onTitleUpdate }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");

  const handleBlur = () => {
    if (value && value !== title) {
      onTitleUpdate(value);
    }
  };

  useEffect(() => {
    setValue(title ?? "");

    return () => setValue("");
  }, [title]);

  return (
    <div className="flex w-64 flex-row space-x-1 text-sm md:w-80">
      <input
        type="text"
        className="w-full border-gray-50 px-2 py-0.5 hover:border-gray-800 focus:border-gray-800 focus:outline-none focus:ring-0"
        placeholder={t("Title")}
        value={value}
        onChange={(e) => {
          setValue(e.target?.value);
        }}
        onBlur={handleBlur}
      />
    </div>
  );
};

export const FormBuilderActionBar: FC = () => {
  const selectedForm = useAppStore(
    (state) => state.formBuilderState?.selectedForm,
  );
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );
  const { updateForm, loading } = useUpdateForm();
  const originalForms = useProjectStore((state) => state.originalForms);

  const isAppSpecificForm =
    selectedForm?.isAppSpecific ||
    (selectedForm?.components ?? []).some((c) => Boolean(c?.isAppSpecific));

  const handleDelete = () => {
    updateFormBuilderState("deleteForm", true);
  };

  const handleEdit = () => {
    updateFormBuilderState("openEditFormModal", true);
  };

  const onTitleUpdate = async (text?: string) => {
    const foundForm =
      selectedForm && originalForms.find((form) => form.id === selectedForm.id);
    if (!foundForm) return;

    const foundComponent = foundForm.components.find(
      (c) => c.parentId === null,
    );
    if (!foundComponent) return;

    await updateForm({
      id: selectedForm.id,
      name: text,
      components: [
        {
          ...foundComponent,
          properties: {
            ...(foundComponent?.properties ?? {}),
            label: text,
          },
        },
      ],
    });
  };

  return (
    <div className="sticky top-0 z-20 flex w-full items-center justify-between bg-white px-1 pb-4 pt-1">
      <FormTitle title={selectedForm?.name} onTitleUpdate={onTitleUpdate} />
      <div className="flex items-center space-x-2">
        {loading ? (
          <span className="text-xs">Änderugen werden gespeichert...</span>
        ) : (
          <span className="text-xs">Änderungen gespeichert</span>
        )}

        <IconButtonBase onClick={handleEdit}>
          <PencilIcon className="h-5 w-5" />
        </IconButtonBase>
        {!isAppSpecificForm && (
          <IconButtonBase variant="danger" onClick={handleDelete}>
            <TrashIcon className="h-5 w-5" />
          </IconButtonBase>
        )}
      </div>
    </div>
  );
};
