import { AppRoutes } from "@amenda-types";
import { FC } from "react";
import { MediaGallery } from "@amenda-components/Gallery";
import { ProjectMediaWrapper } from "./EditProjectMedia";

interface Props {
  projectId: string;
}

export const ReadOnlyProjectMedia: FC<Props> = ({ projectId }) => {
  return (
    <ProjectMediaWrapper projectId={projectId}>
      {({ attachments, searchAttachments }) => (
        <MediaGallery
          canUpload={false}
          label="Media"
          rootRoute={AppRoutes.Attachments}
          attachments={attachments}
          searchAttachments={searchAttachments}
        />
      )}
    </ProjectMediaWrapper>
  );
};
