import {
  ArrowTrendingUpIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { FC, useEffect, useState } from "react";
import { IconButton, IconButtonBase, Modal } from "@amenda-components/App";

import { MiniSearchField } from "@amenda-components/SearchComponents";
import { ReactTable } from "./ReactTable";
import { ReactTableKeys } from "@amenda-types";
import { TableCellWrapper } from "./ReactTableComponents";
import { Transition } from "@headlessui/react";
import { useGetAllConstructionIndices } from "@amenda-domains/queries";
import { useProjectStore } from "@amenda-domains/mutations";

export const BKIModalButton: FC = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [visible, setVisible] = useState(false);
  const [bkiValues, setBkiValues] = useState<any[]>([]);
  const constructionPriceIndices = useProjectStore(
    (state) => state.constructionPriceIndices,
  );
  const { getAllConstructionIndices, loading } = useGetAllConstructionIndices();

  const showModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const onSearchTermChange = (searchTerm: string) => {
    let bkiValues = constructionPriceIndices;

    setSearchTerm(searchTerm);
    if (searchTerm) {
      bkiValues = constructionPriceIndices.filter((constructionPriceIndex) => {
        return constructionPriceIndex?.year?.toString()?.includes(searchTerm);
      });
    }
    setBkiValues(bkiValues);
  };

  const handleShowSearch = () => setShowSearch(true);
  const handleHideSearch = () => {
    setSearchTerm("");
    setShowSearch(false);
    setBkiValues(constructionPriceIndices);
  };

  useEffect(() => {
    if (visible) {
      getAllConstructionIndices();
    }
  }, [getAllConstructionIndices, visible]);

  useEffect(() => {
    setBkiValues(constructionPriceIndices);
  }, [constructionPriceIndices]);

  return (
    <>
      <IconButtonBase
        size="sm"
        className="p-0"
        label="View BKI values"
        onClick={showModal}
      >
        <ArrowTrendingUpIcon className="h-5 w-5" />
      </IconButtonBase>
      <Modal
        size="md"
        isOpen={visible}
        withCancel={false}
        closeModalFromTitle={true}
        isElevated={true}
        className="h-fit w-full md:w-8/12"
        title="Construction Price Indices"
        message="Construction Services on the Building"
        onClose={closeModal}
      >
        <ReactTable
          data={bkiValues}
          isFullWidth={true}
          isLoading={loading}
          containerClass="h-[80vh] w-full"
          tableId={ReactTableKeys.ConstructionPriceIndices}
          columns={[
            {
              id: "year",
              minSize: 150,
              header: () => {
                return (
                  <div className="h-8">
                    <Transition
                      show={showSearch}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <div className="w-full">
                        <MiniSearchField
                          className="h-[2.05rem] w-full border transition duration-300 ease-in-out"
                          placeholder="Filter"
                          value={searchTerm}
                          onChange={onSearchTermChange}
                          onClear={handleHideSearch}
                        />
                      </div>
                    </Transition>
                    <Transition
                      show={!showSearch}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <div>
                        <IconButton
                          iconSize={1}
                          Icon={MagnifyingGlassIcon}
                          label="Open Search"
                          onClick={handleShowSearch}
                        />
                      </div>
                    </Transition>
                  </div>
                );
              },
              accessorKey: "year",
              cell: ({ row }: any) => {
                return (
                  <TableCellWrapper className="pl-2" isText={true}>
                    {row?.original?.year || "_"}
                  </TableCellWrapper>
                );
              },
            },
            {
              id: "q1",
              minSize: 20,
              header: "Q1",
              accessorKey: "q1",
              meta: {
                isNumber: true,
              },
              cell: ({ row }: any) => {
                return (
                  <TableCellWrapper className="pl-2" isText={true}>
                    {row?.original?.q1 || "_"}
                  </TableCellWrapper>
                );
              },
            },
            {
              id: "q2",
              minSize: 20,
              header: "Q2",
              accessorKey: "q2",
              meta: {
                isNumber: true,
              },
              cell: ({ row }: any) => {
                return (
                  <TableCellWrapper className="pl-2" isText={true}>
                    {row?.original?.q2 || "_"}
                  </TableCellWrapper>
                );
              },
            },
            {
              id: "q3",
              minSize: 20,
              header: "Q3",
              accessorKey: "q3",
              meta: {
                isNumber: true,
              },
              cell: ({ row }: any) => {
                return (
                  <TableCellWrapper className="pl-2" isText={true}>
                    {row?.original?.q3 || "_"}
                  </TableCellWrapper>
                );
              },
            },
            {
              id: "q4",
              minSize: 20,
              header: "Q4",
              accessorKey: "q4",
              meta: {
                isNumber: true,
              },
              cell: ({ row }: any) => {
                return (
                  <TableCellWrapper className="pl-2" isText={true}>
                    {row?.original?.q4 || "_"}
                  </TableCellWrapper>
                );
              },
            },
          ]}
        />
      </Modal>
    </>
  );
};
