import { FC, useEffect, useMemo } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  ProjectLayout,
  ProjectSectionSidebar,
  ProjectSectionSidebarMobile,
  ReadOnlyProjectTabs,
  ReadonlyProjectHeader,
} from "@amenda-components/Projects";
import { useProjectStore, useSettingsStore } from "@amenda-domains/mutations";

import { FaceAPIProvider } from "@amenda-context";
import { MainContainer } from "@amenda-components/Shared";
import { MoveAttachmentModal } from "@amenda-components/Gallery";
import { useGetProject } from "@amenda-domains/queries";
import { useParams } from "react-router";

export const ProjectPage: FC = () => {
  const { projectId } = useParams<{ projectId?: string }>();
  const { getProject } = useGetProject();
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const clearProjectValues = useProjectStore(
    (state) => state.clearProjectValues,
  );
  const projectForms = useProjectStore((state) => state.projectForms);
  const availableForms = useMemo(() => projectForms || [], [projectForms]);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const projectsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Projects,
  );
  const resourceId = projectId || selectedProject?.id;

  useEffect(() => {
    if (projectId) {
      getProject({
        id: projectId,
        redirectWhenEmpty: true,
        context: {
          requestPolicy: "cache-and-network",
        },
      });
    }
  }, [projectId, getProject]);

  useEffect(() => {
    return () => {
      clearProjectValues();
    };
  }, [clearProjectValues]);

  return (
    <FaceAPIProvider>
      <ProjectLayout className="px-1 lg:pt-0" title={selectedProject?.name}>
        <MoveAttachmentModal />
        <div className="flex h-full w-full flex-row">
          <ProjectSectionSidebar
            resourceId={resourceId}
            availableForms={availableForms}
            projectsGeneralPermissions={projectsGeneralPermissions}
          />
          <ProjectSectionSidebarMobile
            resourceId={resourceId}
            availableForms={availableForms}
            projectsGeneralPermissions={projectsGeneralPermissions}
          />
          <div className="h-screen w-full overflow-y-auto overscroll-y-contain pr-4">
            <MainContainer className="pt-0">
              <ReadonlyProjectHeader />
              <div className="h-auto w-full">
                <ReadOnlyProjectTabs
                  availableForms={availableForms}
                  projectsGeneralPermissions={projectsGeneralPermissions}
                />
              </div>
            </MainContainer>
          </div>
        </div>
      </ProjectLayout>
    </FaceAPIProvider>
  );
};
