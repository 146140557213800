import { FC, Fragment, ReactNode, memo } from "react";
import {
  transformWithNestedFormContext,
  useNestedFormContext,
} from "./FormBuilderProviders";

import { ComponentTreeRendererProps } from "@amenda-types";
import { FormComponentTypes } from "@amenda-constants";
import { getLayoutComponents } from ".";
import { useMemotizedComponents } from "@amenda-components/Shared/components";

interface FormComponentProps extends ComponentTreeRendererProps {
  buildChildComponents: (
    value: ComponentTreeRendererProps,
  ) => ReactNode[] | undefined;
}

type ComponentWrapperProps = Pick<
  FormComponentProps,
  "globalProps" | "config"
> & {
  Component: FC<any> | null;
};

const ComponentWrapper: FC<ComponentWrapperProps> = ({
  Component,
  ...rest
}) => {
  const context = useNestedFormContext();

  const { global, config } = transformWithNestedFormContext({
    context,
    ...rest,
  });

  if (!Component) {
    return null;
  }
  return <Component config={config} global={global} />;
};

export const FormComponent: FC<FormComponentProps> = memo(
  ({
    buildChildComponents,
    customComponents = {},
    customFunctions,
    customLayoutComponents = {},
    config,
    globalProps,
    readOnly,
    isFormBuilder,
    isPrintView,
    isSimilaritySearch,
  }) => {
    const components = useMemotizedComponents({
      readOnly,
      isPrintView,
      isFormBuilder,
      isSimilaritySearch,
    });

    const layoutComponents: Record<string, FC<any>> = getLayoutComponents(
      customLayoutComponents,
    );
    const allComponents: Record<string, FC<any>> = {
      ...components,
      ...customComponents,
    };
    const LayoutComponent = layoutComponents[config.layout];
    const Component = config?.component
      ? allComponents[config.component]
      : null;

    if (!isFormBuilder && config.component === FormComponentTypes.Hidden) {
      return <Fragment />;
    }
    return (
      <LayoutComponent
        isPrintView={isPrintView}
        isFormBuilder={isFormBuilder}
        config={config}
        readOnly={readOnly}
        globalProps={globalProps}
      >
        <ComponentWrapper
          config={config}
          globalProps={globalProps}
          Component={Component}
        />
        {buildChildComponents({
          config,
          customComponents,
          customFunctions,
          customLayoutComponents,
          globalProps,
          readOnly,
          isFormBuilder,
          isPrintView,
          isSimilaritySearch,
        })}
      </LayoutComponent>
    );
  },
  (prevProps, nextProps) => {
    if (JSON.stringify(prevProps.config) !== JSON.stringify(nextProps.config)) {
      return false;
    } else if (
      JSON.stringify(prevProps.globalProps) !==
      JSON.stringify(nextProps.globalProps)
    ) {
      return false;
    } else if (prevProps.readOnly !== nextProps.readOnly) {
      return false;
    } else if (prevProps.isPrintView !== nextProps.isPrintView) {
      return false;
    } else if (prevProps.isFormBuilder !== nextProps.isFormBuilder) {
      return false;
    } else if (prevProps.isSimilaritySearch !== nextProps.isSimilaritySearch) {
      return false;
    }
    return true;
  },
);
