import { FC, useEffect } from "react";
import { GeneralPermissionKeys, rolesCreationTabs } from "../common";
import {
  useDeleteSystemRole,
  useProjectStore,
  useSettingsStore,
} from "@amenda-domains/mutations";

import { DeleteModal } from "@amenda-components/Shared";
import { GeneralPermissionSettings } from "./GeneralPermissionSettings";
import { RolesFormWrapper } from "./RolesFormWrapper";
import isEmpty from "lodash/isEmpty";
import { useGetSystemRole } from "@amenda-domains/queries";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  openModal: boolean;
  roleId?: string;
  formId: string;
  handleSystemRole: (role: any) => Promise<any>;
  handleCloseModal: () => void;
}

const DeleteRoleModal: FC<Pick<Props, "openModal" | "handleCloseModal">> = ({
  openModal,
  handleCloseModal,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedSystemRole = useSettingsStore(
    (state) => state.selectedSystemRole,
  );
  const { deleteSystemRole, loading } = useDeleteSystemRole();

  const handleDeleteRole = async () => {
    if (selectedSystemRole) {
      await deleteSystemRole(selectedSystemRole.id);
      handleCloseModal();
      navigate("/admin/rollen");
    }
  };

  return (
    <DeleteModal
      title="Delete Role?"
      loading={loading}
      openModal={openModal}
      handleDelete={handleDeleteRole}
      handleCloseModal={handleCloseModal}
    >
      <p className="mb-1 text-sm text-gray-900">
        {t("Are you sure you want to delete this role?")}
      </p>
      <p className="text-sm text-gray-900">
        {t("RoleDeletionModal", {
          totalUsers: selectedSystemRole?.usersIds?.length ?? 0,
          roleName: selectedSystemRole?.name ?? "this role",
        })}
      </p>
    </DeleteModal>
  );
};

export const CreateOrEditRoleForm: FC<Props> = ({
  roleId,
  formId,
  handleSystemRole,
  ...rest
}) => {
  const { t } = useTranslation();
  const forms = useProjectStore((state) => state.forms);
  const { loading, getSystemRole } = useGetSystemRole();

  useEffect(() => {
    if (roleId) {
      getSystemRole({
        id: roleId,
      });
    }
  }, [getSystemRole, roleId]);

  if (isEmpty(forms)) {
    return null;
  }
  return (
    <>
      <DeleteRoleModal {...rest} />
      <RolesFormWrapper
        roleId={roleId}
        formId={formId}
        formsByType={forms as any}
        isLoading={loading}
        handleSystemRole={handleSystemRole}
      >
        {({ setValue, control }) => (
          <div className="grid w-full grid-cols-1 gap-2">
            {rolesCreationTabs.map((tab) => (
              <div
                className="border-1 my-4 min-h-72 w-full flex-col border p-4"
                key={tab.label}
              >
                <span className="amenda-component-label font-heading">
                  {t(tab.label)}
                </span>
                <GeneralPermissionSettings
                  control={control}
                  setValue={setValue}
                  selectedTab={tab.path as GeneralPermissionKeys}
                />
              </div>
            ))}
          </div>
        )}
      </RolesFormWrapper>
    </>
  );
};
