import {
  AddressSearch,
  Badges,
  CalculatorTextField,
  CheckBox,
  DatePicker,
  DatePickerRange,
  KeywordCollection,
  LabelledInput,
  MultiSelect,
  RadioButton,
  ReactTreeSelectBreadCrumb,
  SingleSelect,
  Switch,
  TextArea,
  TextField,
  Title,
} from "@amenda-components/FormComponents";
import {
  AppendSymbol,
  TitleAndDescription,
} from "@amenda-components/PdfBuilder";
import {
  Chip,
  ColoredSelect,
  ReadOnlyColoredSelect,
  Tag,
  Tags,
} from "@amenda-components/App";
import { ComponentTreeRendererProps, FormComponentProps } from "@amenda-types";
import {
  LabelledContactsField,
  SearchContact,
} from "@amenda-components/Contacts";
import {
  MaterialCostInput,
  MaterialPercentageValues,
  MaterialPhoto,
} from "@amenda-components/Materials";
import { ReadOnlyRegionalSelect, RegionalSelect } from "./RegionalSelect";
import {
  ReadOnlySearchAndSelectProject,
  SearchAndSelectProjects,
} from "@amenda-components/Projects";
import { devConsole, formatDate, formatNumbers } from "@amenda-utils";
import { isEmpty, isFinite, isNil, sortBy } from "lodash";

import { Controller } from "react-hook-form";
import { EmptyIndicator } from "./EmptyIndicator";
import { FormBuilderComponent } from "@amenda-components/Settings/FormBuilder/FormBuilderComponent";
import { FormComponentTypes } from "@amenda-constants";
import { ReadOnlyKeywords } from "./ReadOnlyKeywords";
import { ReadOnlySearchAndSelect } from "./ReadOnlySearchAndSelect";
import { SelectCostGroups } from "@amenda-components/CostGroup/SelectCostGroups";
import { SimilaritySearchPopover } from "@amenda-components/Projects/SimilaritySearchPopover";
import { getComponentIcons } from "./common";
import { getOptionsTree } from "@amenda-components/FormComponents/common";
import { useMemo } from "react";

type Props = Pick<
  ComponentTreeRendererProps,
  "readOnly" | "isFormBuilder" | "isSimilaritySearch" | "isPrintView"
>;

const components = ({
  readOnly = true,
  isPrintView = false,
  isFormBuilder = false,
  isSimilaritySearch = false,
}: Props): Record<FormComponentTypes, any> => {
  return {
    AddressSearch: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (readOnly || Boolean(global?.nestedFormReadOnly)) {
        return (
          <TitleAndDescription
            container="column"
            title={properties.label}
            description={global.data?.[id]?.name ?? <EmptyIndicator />}
          />
        );
      }
      return (
        <Controller
          control={global.control}
          name={id}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            const { StartIcon, ...rest } = properties || {};
            return (
              <AddressSearch
                id={id}
                value={value}
                error={error?.message}
                onBlur={global?.onBlur}
                disabled={properties?.readOnly}
                StartIcon={getComponentIcons(StartIcon)}
                onChange={onChange}
                optional={
                  <SimilaritySearchPopover
                    {...props}
                    isSimilaritySearch={isSimilaritySearch}
                  />
                }
                {...rest}
              />
            );
          }}
        />
      );
    },
    Badges: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (
        readOnly ||
        Boolean(properties?.readOnly) ||
        Boolean(global?.nestedFormReadOnly)
      ) {
        let foundOptions = global?.data?.[id] ?? [];
        foundOptions = foundOptions.map((itemId: string) => {
          return properties?.options?.find((opt: any) => opt.value === itemId);
        });
        const options = sortBy(foundOptions, "value");

        return (
          <TitleAndDescription
            container="column"
            title={properties.label}
            className="mb-4"
            description={
              !isEmpty(options) ? (
                <div className="mt-2 flex w-full flex-wrap space-x-2">
                  {options.map((opt: any) => {
                    return (
                      <Chip key={opt.value} label={opt?.label ?? opt?.value} />
                    );
                  })}
                </div>
              ) : (
                <EmptyIndicator />
              )
            }
          />
        );
      }
      return (
        <Controller
          control={global.control}
          name={id}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Badges
                options={properties?.options}
                error={error?.message}
                onChange={onChange}
                selectedOptions={value ?? []}
                onBlur={global?.onBlur}
                {...properties}
              />
            );
          }}
        />
      );
    },
    CalculatorTextField: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (readOnly || Boolean(global?.nestedFormReadOnly)) {
        const value = global.data?.[id];
        return (
          <TitleAndDescription
            container="column"
            title={properties.label}
            description={
              !isFinite(value) ? <EmptyIndicator /> : formatNumbers(value)
            }
          />
        );
      }
      return (
        <Controller
          control={global.control}
          name={id}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <CalculatorTextField
                id={id}
                value={value}
                control={global.control}
                error={error?.message}
                disabled={properties?.readOnly}
                onChange={onChange}
                onBlur={global?.onBlur}
                {...properties}
              />
            );
          }}
        />
      );
    },
    Checkbox: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (readOnly || Boolean(global?.nestedFormReadOnly)) {
        let options = global?.data?.[id] ?? [];
        options = options.map((itemId: any) => {
          return properties.options.find((opt: any) => opt.value === itemId);
        });

        if (isEmpty(options)) {
          return null;
        }
        return options.map((opt: any) => (
          <TitleAndDescription
            key={opt.value}
            container="column"
            title={opt.label}
            description={opt.description}
          />
        ));
      }
      return (
        <Controller
          control={global.control}
          name={id}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <CheckBox
                id={id}
                options={properties?.options}
                error={error?.message}
                selectedOptions={value}
                disabled={properties?.readOnly}
                onChange={onChange}
                onBlur={global?.onBlur}
                {...properties}
              />
            );
          }}
        />
      );
    },
    ColoredSelect: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (readOnly || Boolean(global?.nestedFormReadOnly)) {
        return (
          <TitleAndDescription
            container="column"
            title={properties.label}
            description={
              <ReadOnlyColoredSelect
                value={global.data?.[id]}
                options={properties?.options ?? []}
              />
            }
          />
        );
      }
      return (
        <Controller
          control={global.control}
          name={id}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <ColoredSelect
                id={id}
                error={error?.message}
                value={value}
                disabled={properties?.readOnly}
                hasMenuOverflow={global?.hasMenuOverflow}
                onChange={onChange}
                optional={
                  <SimilaritySearchPopover
                    {...props}
                    isSimilaritySearch={isSimilaritySearch}
                  />
                }
                {...properties}
              />
            );
          }}
        />
      );
    },
    DatePicker: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (readOnly || Boolean(global?.nestedFormReadOnly)) {
        const description = global.data?.[id] ? (
          formatDate(global.data?.[id])
        ) : (
          <EmptyIndicator />
        );
        return (
          <TitleAndDescription
            container="column"
            title={properties.label}
            description={description}
          />
        );
      }
      return (
        <Controller
          control={global.control}
          name={id}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <DatePicker
                id={id}
                value={value}
                error={error?.message}
                disabled={properties.readOnly}
                onChange={onChange}
                onBlur={global?.onBlur}
                withPortal={global?.hasMenuOverflow}
                optional={
                  <SimilaritySearchPopover
                    {...props}
                    isSimilaritySearch={isSimilaritySearch}
                  />
                }
                {...properties}
              />
            );
          }}
        />
      );
    },
    DatePickerRange: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (readOnly || Boolean(global?.nestedFormReadOnly)) {
        const date = global.data?.[id];
        const getDateRangeValue = (date?: string) => {
          return date ? formatDate(date) : "No date";
        };
        const description =
          isNil(date?.from) && isNil(date?.to) ? (
            <EmptyIndicator />
          ) : (
            `${getDateRangeValue(date.from)} - ${getDateRangeValue(date.to)}`
          );

        return (
          <TitleAndDescription
            container="column"
            title={properties.label}
            description={description}
          />
        );
      }
      return (
        <Controller
          control={global.control}
          name={id}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <DatePickerRange
                id={id}
                value={value}
                error={error?.message}
                disabled={properties?.readOnly}
                onChange={onChange}
                onBlur={global?.onBlur}
                withPortal={global?.hasMenuOverflow}
                optional={
                  <SimilaritySearchPopover
                    {...props}
                    isSimilaritySearch={isSimilaritySearch}
                  />
                }
                {...properties}
              />
            );
          }}
        />
      );
    },
    Input: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (readOnly || Boolean(global?.nestedFormReadOnly)) {
        return properties.type === "number" ? (
          <TitleAndDescription
            container="column"
            title={properties.label}
            description={
              isFinite(global.data?.[id]) ? (
                <AppendSymbol
                  symbol={
                    properties.endAddOnText || properties.startAddOnText || ""
                  }
                  value={global.data?.[id]}
                />
              ) : (
                <EmptyIndicator />
              )
            }
          />
        ) : (
          <TitleAndDescription
            container="column"
            title={properties.label}
            description={global.data?.[id] ?? <EmptyIndicator />}
          />
        );
      }
      const { StartIcon, ...rest } = properties || {};
      return (
        <Controller
          control={global.control}
          name={id}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                id={id}
                value={value}
                error={error?.message}
                onChange={onChange}
                disabled={properties?.readOnly}
                onBlur={global?.onBlur}
                optional={
                  <SimilaritySearchPopover
                    {...props}
                    isSimilaritySearch={isSimilaritySearch}
                  />
                }
                StartIcon={getComponentIcons(StartIcon)}
                {...rest}
              />
            );
          }}
        />
      );
    },
    MultiSelect: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (readOnly || Boolean(global?.nestedFormReadOnly)) {
        const options: any[] = properties?.options ?? [];
        const values: any[] = Array.isArray(global?.data?.[id])
          ? global?.data?.[id]
          : [];

        if (properties?.isNested) {
          const optionsTree = getOptionsTree(options);

          return (
            <TitleAndDescription
              container="column"
              title={properties.label}
              description={
                isEmpty(values) ? (
                  <EmptyIndicator />
                ) : (
                  <Tags>
                    {values.map((value) => (
                      <Tag key={value}>
                        <ReactTreeSelectBreadCrumb
                          value={value}
                          optionsTree={optionsTree}
                        />
                      </Tag>
                    ))}
                  </Tags>
                )
              }
            />
          );
        }

        const resolvedValues = values
          .map((v) => options.find((option) => option.value === v))
          .filter(Boolean);
        return (
          <TitleAndDescription
            container="column"
            title={properties.label}
            description={
              isEmpty(resolvedValues) ? (
                <EmptyIndicator />
              ) : (
                <Tags>
                  {resolvedValues.map((opt: any) => (
                    <Tag key={opt.value}>{opt.label}</Tag>
                  ))}
                </Tags>
              )
            }
          />
        );
      }
      return (
        <Controller
          control={global.control}
          name={id}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <MultiSelect
                id={id}
                error={error?.message}
                values={value}
                disabled={properties?.readOnly}
                hasMenuOverflow={global?.hasMenuOverflow}
                onBlur={global?.onBlur}
                onChange={onChange}
                optional={
                  <SimilaritySearchPopover
                    {...props}
                    isSimilaritySearch={isSimilaritySearch}
                  />
                }
                {...properties}
              />
            );
          }}
        />
      );
    },
    MaterialPhoto: (props: FormComponentProps) => {
      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      return (
        <MaterialPhoto
          readOnly={readOnly || Boolean(props?.global?.nestedFormReadOnly)}
          getComponentIcons={getComponentIcons}
          {...props}
        />
      );
    },
    SelectCostGroups: (props: FormComponentProps) => {
      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      return (
        <SelectCostGroups
          readOnly={readOnly || Boolean(props?.global?.nestedFormReadOnly)}
          {...props}
        />
      );
    },
    RadioButton: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (readOnly || Boolean(global?.nestedFormReadOnly)) {
        const foundOption = properties.options.find(
          (opt: any) => opt.value === global.data?.[id],
        );

        return (
          <TitleAndDescription
            container="column"
            title={properties.label}
            description={foundOption?.label || <EmptyIndicator />}
          />
        );
      }
      return (
        <Controller
          control={global.control}
          name={id}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <RadioButton
                id={id}
                options={properties?.options}
                error={error?.message}
                value={value}
                disabled={properties?.readOnly}
                onBlur={global?.onBlur}
                onChange={onChange}
                {...properties}
              />
            );
          }}
        />
      );
    },
    Save: (props: FormComponentProps) => {
      return null;
    },
    Select: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (readOnly || Boolean(global?.nestedFormReadOnly)) {
        const selectedOption = properties?.options?.find(
          (opt: any) => opt.value === global.data?.[id],
        );
        return (
          <TitleAndDescription
            container="column"
            title={properties.label}
            description={
              selectedOption ? (
                <Tag>{selectedOption.label}</Tag>
              ) : (
                <EmptyIndicator />
              )
            }
          />
        );
      }
      return (
        <Controller
          control={global.control}
          name={id}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <SingleSelect
                id={id}
                error={error?.message}
                value={value}
                disabled={properties?.readOnly}
                hasMenuOverflow={global?.hasMenuOverflow}
                onChange={onChange}
                onBlur={global?.onBlur}
                optional={
                  <SimilaritySearchPopover
                    {...props}
                    isSimilaritySearch={isSimilaritySearch}
                  />
                }
                {...properties}
              />
            );
          }}
        />
      );
    },
    SearchAndSelect: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;
      let userIds = global?.data?.[id] ?? [];
      userIds = Array.isArray(userIds) ? userIds : [userIds];

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (!properties.contactType) {
        devConsole?.warn("person contact type is missing");
        return null;
      }
      if (
        readOnly ||
        Boolean(properties?.readOnly) ||
        Boolean(global?.nestedFormReadOnly)
      ) {
        return (
          <ReadOnlySearchAndSelect
            label={properties?.label}
            userIds={userIds}
            roles={properties?.roles ?? []}
            contactType={properties.contactType}
            isPrintView={isPrintView}
          />
        );
      }
      return (
        <SearchContact
          control={global.control}
          contactType={properties.contactType}
          id={id}
          isMulti={properties?.isMulti || false}
          label={properties?.label || ""}
          optional={
            <SimilaritySearchPopover
              {...props}
              isSimilaritySearch={isSimilaritySearch}
            />
          }
        />
      );
    },
    Switch: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (isSimilaritySearch) {
        return (
          <Controller
            control={global.control}
            name={id}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              const selectValue = isNil(value) ? undefined : value.toString();

              return (
                <SingleSelect
                  id={id}
                  value={selectValue}
                  error={error?.message}
                  onBlur={global?.onBlur}
                  onChange={(value) => onChange(value === "true")}
                  options={[
                    {
                      label: "Ja",
                      value: "true",
                    },
                    {
                      label: "Nein",
                      value: "false",
                    },
                  ]}
                  {...properties}
                />
              );
            }}
          />
        );
      }
      if (
        readOnly ||
        Boolean(properties?.readOnly) ||
        Boolean(global?.nestedFormReadOnly)
      ) {
        const isTrue = Boolean(global.data?.[id]);

        return (
          <TitleAndDescription
            container="column"
            title={properties.label}
            description={isTrue ? "Ja" : "Nein"}
          />
        );
      }
      return (
        <Controller
          control={global.control}
          name={id}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Switch
                id={id}
                checked={value}
                error={error?.message}
                onChange={onChange}
                onBlur={global?.onBlur}
                {...properties}
              />
            );
          }}
        />
      );
    },
    Textarea: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (readOnly || Boolean(global?.nestedFormReadOnly)) {
        return (
          <TitleAndDescription
            container="column"
            preserveLineBreaks={true}
            title={properties.label}
            description={global.data?.[id] ?? <EmptyIndicator />}
          />
        );
      }
      return (
        <Controller
          control={global.control}
          name={id}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextArea
                id={id}
                error={error?.message}
                value={value}
                onChange={onChange}
                disabled={properties?.readOnly}
                onBlur={global?.onBlur}
                optional={
                  <SimilaritySearchPopover
                    {...props}
                    isSimilaritySearch={isSimilaritySearch}
                  />
                }
                {...properties}
              />
            );
          }}
        />
      );
    },
    LabelledInput: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;
      const defaultOptions = [
        "Home",
        "Private",
        "Business",
        "Mobile",
        "Other",
      ].map((label) => ({
        label,
        value: label,
      }));

      const updatedConfig = {
        ...config,
        properties: {
          options: defaultOptions,
          ...properties,
        },
      };

      if (isFormBuilder) {
        return (
          <FormBuilderComponent
            config={updatedConfig}
            global={global}
            readOnly={readOnly}
          />
        );
      }
      return (
        <LabelledInput
          id={id}
          isReadOnly={readOnly || Boolean(global?.nestedFormReadOnly)}
          control={global.control}
          type={properties?.type}
          label={properties.label}
          isDisabled={properties?.readOnly}
          values={global.data?.[id]}
          onBlur={global?.onBlur}
          options={defaultOptions}
          {...properties}
        />
      );
    },
    LabelledContactInputs: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (!properties.contactType) {
        devConsole?.warn("contact type is missing");
        return null;
      }
      return (
        <LabelledContactsField
          control={global.control}
          contactType={properties.contactType}
          id={id}
          isPrintView={isPrintView}
          readOnly={readOnly || Boolean(global?.nestedFormReadOnly)}
          values={global.data?.[id]}
          resourceId={global?.keywordResourceId}
          {...properties}
        />
      );
    },
    MaterialCostInput: (props: FormComponentProps) => {
      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      return <MaterialCostInput readOnly={readOnly} {...props} />;
    },
    MaterialPercentageValues: (props: FormComponentProps) => {
      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      return <MaterialPercentageValues readOnly={readOnly} {...props} />;
    },
    RegionalSelect: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (readOnly || Boolean(global?.nestedFormReadOnly)) {
        return (
          <ReadOnlyRegionalSelect
            label={properties?.label}
            regionId={global.data?.[id]}
          />
        );
      }
      return (
        <Controller
          control={global.control}
          name={id}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <RegionalSelect
                id={id}
                regionId={value}
                shouldReturnId={true}
                error={error?.message}
                disabled={properties?.readOnly}
                onBlur={global?.onBlur}
                onChange={onChange}
                optional={
                  <SimilaritySearchPopover
                    {...props}
                    isSimilaritySearch={isSimilaritySearch}
                  />
                }
                {...properties}
              />
            );
          }}
        />
      );
    },
    Title: (props: FormComponentProps) => {
      const { properties } = props.config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      return <Title {...properties} />;
    },
    Keyword: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (readOnly || Boolean(global?.nestedFormReadOnly)) {
        const keywords = global.data?.[id];
        return (
          <ReadOnlyKeywords label={properties.label} keywords={keywords} />
        );
      }
      return (
        <Controller
          control={global.control}
          name={id}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <KeywordCollection
                disabled={properties?.readOnly}
                error={error?.message}
                id={id}
                isCreatable={global?.canCreateKeywords}
                canCreateResourceSpecificKeywords={
                  properties?.canCreateResourceSpecificKeywords
                }
                hasMenuOverflow={global?.hasMenuOverflow}
                onChange={onChange}
                resourceId={global?.keywordResourceId}
                values={value}
                optional={
                  <SimilaritySearchPopover
                    {...props}
                    isSimilaritySearch={isSimilaritySearch}
                  />
                }
                {...properties}
              />
            );
          }}
        />
      );
    },
    SearchAndSelectProjects: (props: FormComponentProps) => {
      const { config, global } = props;
      const { id, properties } = config;

      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      if (readOnly || Boolean(global?.nestedFormReadOnly)) {
        return (
          <ReadOnlySearchAndSelectProject
            label={properties.label}
            projectIds={global?.data?.[id]}
          />
        );
      }
      return (
        <Controller
          control={global.control}
          name={id}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <SearchAndSelectProjects
                id={id}
                projectIds={value}
                error={error?.message}
                disabled={properties?.readOnly}
                onBlur={global?.onBlur}
                onChange={onChange}
                optional={
                  <SimilaritySearchPopover
                    {...props}
                    isSimilaritySearch={isSimilaritySearch}
                  />
                }
                {...properties}
              />
            );
          }}
        />
      );
    },
    Hidden: (props: FormComponentProps) => {
      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      return null;
    },
    Image: (props: FormComponentProps) => {
      if (isFormBuilder) {
        return <FormBuilderComponent {...props} readOnly={readOnly} />;
      }
      return null;
    },
  };
};

export const useMemotizedComponents = (props: Props) => {
  return useMemo(() => components(props), [props]);
};
