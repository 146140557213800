import {
  AllowedAttachmentType,
  AllowedCollectionType,
  AppRoutes,
} from "@amenda-types";
import {
  ConstructionDetails,
  ConstructionDetailsProps,
} from "@amenda-components/ConstructionDetails";
import { FC, ReactNode, useEffect } from "react";
import {
  useAttachmentStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";

import isEmpty from "lodash/isEmpty";
import { transformFilters } from "@amenda-components/Shared/common";
import { useLocation } from "react-router-dom";
import { useRunOnMountOnly } from "@amenda-utils";
import { useSearchAttachments } from "@amenda-domains/queries";

interface Props {
  projectId?: string;
}

type ProjectConstructionDetailsWrapperProps = Props & {
  children: (
    props: Pick<
      ConstructionDetailsProps,
      "attachments" | "collectionType" | "searchAttachments"
    >,
  ) => ReactNode;
};

export const ProjectConstructionDetailsWrapper: FC<
  ProjectConstructionDetailsWrapperProps
> = ({ projectId, children }) => {
  const { searchAttachments } = useSearchAttachments();
  const { pathname, search } = useLocation();
  const attachments = useAttachmentStore((state) => state.attachments);
  const setIsSearching = useAttachmentStore((state) => state.setIsSearching);
  const setAttachments = useAttachmentStore((state) => state.setAttachments);
  const { sidebarFilters, searchTerm } = useSidebarFiltersWithPath();
  const setAttachmentProject = useAttachmentStore(
    (state) => state.setAttachmentProject,
  );

  const handleSearchAttachments = async (searchTerm: string) => {
    if (!isEmpty(searchTerm) && projectId) {
      setIsSearching(true);
      setAttachments({
        attachments: [],
      });
      await searchAttachments({
        searchTerm,
        filters: {
          ...transformFilters(sidebarFilters),
          "formValues.project": projectId,
          type: AllowedAttachmentType.pdf,
        },
      });
      setIsSearching(false);
    }
  };

  useRunOnMountOnly(
    () => {
      setAttachmentProject({
        projectId,
        goBackRoute: pathname + (search ?? ""),
      });
    },
    JSON.stringify([projectId, pathname, search]),
  );

  useEffect(() => {
    const searchAttachmentAsync = async () => {
      if (projectId && isEmpty(searchTerm)) {
        setIsSearching(true);
        setAttachments({
          attachments: [],
        });
        await searchAttachments({
          filters: {
            ...transformFilters(sidebarFilters),
            "formValues.project": projectId,
            type: AllowedAttachmentType.pdf,
          },
        });
        setIsSearching(false);
      }
    };

    searchAttachmentAsync();
  }, [
    projectId,
    sidebarFilters,
    searchTerm,
    setIsSearching,
    setAttachments,
    searchAttachments,
  ]);

  return (
    <div className="flex h-full flex-col">
      {children({
        attachments,
        collectionType: AllowedCollectionType.ConstructionDetails,
        searchAttachments: handleSearchAttachments,
      })}
    </div>
  );
};

export const EditProjectConstructionDetails: FC<Props> = ({ projectId }) => {
  return (
    <ProjectConstructionDetailsWrapper projectId={projectId}>
      {({ attachments, collectionType, searchAttachments }) => (
        <ConstructionDetails
          canUpload={true}
          collectionType={collectionType}
          isCollection={false}
          canAssignAttachments={true}
          canCreateCollection={false}
          rootRoute={AppRoutes.ConstructionDetails}
          attachments={attachments}
          searchAttachments={searchAttachments}
        />
      )}
    </ProjectConstructionDetailsWrapper>
  );
};
