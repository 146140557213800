import { AvailableNotificationTypes, LogicalOperators } from "@amenda-types";
import {
  CollectionProps,
  enrichSearchFilters,
  generateCollectionShare,
} from "./common";
import { FC, useState } from "react";
import { isEmpty, isString, uniq } from "lodash";
import {
  useAppStore,
  useCreateCollection,
  useUsersStore,
} from "@amenda-domains/mutations";

import { Button } from "@amenda-components/App";
import { CollectionsModalWrapper } from "./CollectionsModalWrapper";
import { sanitizeData } from "@amenda-utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props extends CollectionProps {
  isOpen: boolean;
  collectionRoute: string;
  handleClose: () => void;
}

export const CreateCollectionModal: FC<Props> = ({
  isOpen,
  collectionType,
  collectionRoute,
  resourceIds = [],
  handleClose,
  getResourceIds,
  clearSelectedResources,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { createCollection } = useCreateCollection();
  const showNotification = useAppStore((state) => state.showNotification);
  const currentUser = useUsersStore((state) => state.currentUser);

  const goToCollection = (collectionId: string) => {
    if (collectionId) {
      navigate(`${collectionRoute}/${collectionId}`);
    }
  };

  const getKeywordIds = (keyword: Record<string, any> | string) => {
    return isString(keyword) ? keyword : keyword.value;
  };

  const getResources = async (keywords: any[]) => {
    let args: Record<string, string[]> = {};
    keywords.forEach((keyword) => {
      const { value, componentId } = keyword;

      if (args[componentId]) {
        args[componentId].push(value);
      } else {
        args[componentId] = [value];
      }
    });

    let searchArgs: Record<string, any> = {};
    Object.keys(args).forEach((key) => {
      searchArgs[key] = enrichSearchFilters(
        { operation: LogicalOperators.OR },
        args[key],
      );
    });

    if (!isEmpty(args)) {
      return (await getResourceIds(searchArgs)) ?? [];
    }
    return [];
  };

  const handleSubmit = async (
    {
      name,
      description,
      systemRoles,
      addResourcesFromKeywords,
      users = [],
      matchingUsers = [],
      keywords = [],
    }: any,
    reset: () => void,
  ) => {
    setLoading(true);

    let selectedResourceIds: string[] = [];
    if (addResourcesFromKeywords) {
      selectedResourceIds = await getResources(keywords);
    }

    const share = generateCollectionShare({
      users,
      matchingUsers,
      systemRoles,
    });
    let input = {
      name,
      description,
      collectionType,
      share,
      resourceIds: uniq([...resourceIds, ...selectedResourceIds]),
      keywords: keywords.map(getKeywordIds),
    };
    input = sanitizeData(input);

    try {
      if (isEmpty(input.resourceIds)) {
        throw new Error(
          t("CollectionCreationError", {
            resource: t(collectionType),
          }),
        );
      }

      const collection = await createCollection({ input });
      setLoading(false);
      reset();
      clearSelectedResources();
      handleClose();
      goToCollection(collection.id);
    } catch (err: any) {
      setLoading(false);
      showNotification(AvailableNotificationTypes.Error, err.message);
    }
  };

  return (
    <CollectionsModalWrapper
      title="Create new collection"
      isOpen={isOpen}
      isLoading={loading}
      ownerId={currentUser?.id}
      collectionType={collectionType}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      footerChildren={(formId, loading) => {
        return (
          <div className="w-full">
            <Button
              form={formId}
              className="flex w-full justify-center"
              type="submit"
              loading={loading}
              variant="primary"
            >
              {t("Create collection")}
            </Button>
          </div>
        );
      }}
    />
  );
};
