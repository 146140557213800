import {
  AllowedAttachmentType,
  AvailableImageVariants,
  HoverVariants,
  RoundedVariants,
} from "@amenda-types";
import { FC, useState } from "react";
import {
  HoverSelector,
  Image,
  Link,
  PulseLoader,
  SkeletonImage,
} from "@amenda-components/App";
import { formatFileSizeResult, getFileType } from "./common";
import {
  useAttachmentStore,
  useGenerateAttachmentUrl,
  useProjectStore,
  useSetProjectGalleryImage,
  useTemplatesStore,
} from "@amenda-domains/mutations";

import { TrashIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { isAttachmentPdfOfTypeTemplate } from "@amenda-utils";
import { useLocation } from "react-router-dom";

interface ListViewProps {
  files: any[];
  canUpdateGallery?: boolean;
  handleDeleteFile?: (file: any) => void;
}

const isPdf = (file: any) => getFileType(file) === "pdf";

const Thumbnail: FC<{
  canUpdateGallery: boolean;
  file: any;
}> = ({ canUpdateGallery, file }) => {
  const { pathname } = useLocation();
  const selectedProject = useProjectStore((state) => state.selectedProject);

  const getUrl = () => {
    if (isPdf(file)) {
      return file.previewUrl;
    }
    return file.url;
  };
  const getActionText = () => {
    let actionText = "";
    if (pathname.includes("edit")) {
      actionText = isAttachmentPdfOfTypeTemplate(file)
        ? "Edit Template"
        : getFileType(file) === "image"
          ? "Set as Gallery Image"
          : "";
    } else {
      actionText = getFileType(file) === "image" ? "Open Image" : "";
    }
    return actionText;
  };

  const actionText = getActionText();
  return (
    <div className="relative h-full w-full">
      <HoverSelector
        isSelected={file.url === selectedProject?.galleryUrl}
        canSelect={canUpdateGallery}
        hoverMessage={actionText}
        variant={HoverVariants.NoBorder}
      >
        <Image
          url={getUrl()}
          size="pb-10/12"
          magicSize="gallery"
          variant={AvailableImageVariants.contain}
          rounded={RoundedVariants?.none}
        />
      </HoverSelector>
    </div>
  );
};

export const ListView: FC<ListViewProps> = ({
  files,
  handleDeleteFile,
  canUpdateGallery = false,
}) => {
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const updateSelectedProjectUrl = useProjectStore(
    (state) => state.updateSelectedProjectUrl,
  );
  const { generateAttachmentUrl, generateAttachmentUrlLoader } =
    useGenerateAttachmentUrl();
  const { setProjectGallery, projectGalleryLoader } =
    useSetProjectGalleryImage();
  const [selectedFile, setSelectedFile] = useState<any>({});
  const setSelectedAttachment = useAttachmentStore(
    (state) => state.setSelectedAttachment,
  );
  const setIsEditing = useTemplatesStore((state) => state.setIsEditing);
  const setTemplateDesignModal = useTemplatesStore(
    (state) => state.setTemplateDesignModal,
  );
  const dropzoneFiles = useAttachmentStore(
    (state) => state.mediaDropZoneFiles.files,
  );

  const loading = generateAttachmentUrlLoader || projectGalleryLoader;

  const handlePdf = (file: any) => {
    if (isPdf(file)) {
      if (canUpdateGallery && isAttachmentPdfOfTypeTemplate(file)) {
        setSelectedAttachment(file);
        setIsEditing(true);
        setTemplateDesignModal(true);
      } else {
        const { bucketname, filename } = file;
        generateAttachmentUrl({
          input: {
            bucketname,
            filename,
            type: AllowedAttachmentType.pdf,
          },
          callback: (pdfUrl) => window.open(pdfUrl),
        });
      }
    }
  };

  const handleImage = (file: any) => {
    if (!isPdf(file)) {
      if (canUpdateGallery) {
        setProjectGallery({
          input: {
            projectId: selectedProject?.id,
            galleryUrl: file.url,
          },
        }).then(({ data }) => {
          if (data?.galleryImageAdded) {
            updateSelectedProjectUrl(file.url);
          }
        });
      } else {
        setSelectedAttachment(file);
      }
    }
  };

  const handleClick = (file: any) => {
    setSelectedFile(file);
    handlePdf(file);
    handleImage(file);
  };

  return (
    <div className="mt-4">
      <ul className="grid grid-cols-2 gap-x-4 gap-y-12 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-5 xl:gap-x-8">
        {files?.map((file) => (
          <li key={file.id} className="relative flex flex-col justify-center">
            <div
              className="group block cursor-pointer overflow-hidden"
              onClick={() => handleClick(file)}
            >
              <div className="flex items-center justify-center">
                {loading && selectedFile.id === file.id && <PulseLoader />}
                <Thumbnail canUpdateGallery={canUpdateGallery} file={file} />
              </div>
            </div>
            <div className="flex">
              <p className="pointer-events-none hidden flex-auto text-sm text-gray-500 lg:block">
                {file.size && formatFileSizeResult(file.size)}
              </p>
              <div className="flex-end">
                {handleDeleteFile && (
                  <Link variant="danger" onClick={() => handleDeleteFile(file)}>
                    <TrashIcon className="h-4 w-4 text-red-400" />
                  </Link>
                )}
              </div>
            </div>
          </li>
        ))}
        {dropzoneFiles.map((file) => (
          <li
            key={file.id}
            className={clsx("relative flex flex-col justify-center", {
              hidden: file.progress === 100,
            })}
          >
            <SkeletonImage className="h-40 w-full" />
          </li>
        ))}
      </ul>
    </div>
  );
};
