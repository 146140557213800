import {
  FullColumn,
  GridWrapper,
  OneThirdColumn,
  TwoThirdsColumn,
} from "@amenda-components/PageLayouts";
import {
  PrintProjectAttachmentsView,
  ProjectAttachmentSliderView,
} from "./ProjectAttachmentSliderView";

import { AppRoutes } from "@amenda-types";
import { ArrowLeftIcon } from "lucide-react";
import { FC } from "react";
import { Link } from "@amenda-components/App";
import { ProjectActionBar } from "@amenda-components/Projects";
import { ReadOnlyHeaders } from "@amenda-components/PageBuilder";
import { useNavigate } from "react-router-dom";
import { useProjectStore } from "@amenda-domains/mutations";
import { useSettingsStore } from "@amenda-domains/mutations/settings";
import { useTranslation } from "react-i18next";

interface Props {
  showPrintView?: boolean;
}

export const ReadonlyProjectHeader: FC<Props> = ({ showPrintView = false }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const components = useProjectStore((state) => state.projectFormComponents);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  if (showPrintView) {
    return (
      <>
        <h1 className="mb-4 text-3xl text-gray-700">{selectedProject?.name}</h1>
        <PrintProjectAttachmentsView />
      </>
    );
  }
  return (
    <>
      <div className="hidden lg:block">
        <GridWrapper>
          <OneThirdColumn>
            <div className="max-w-[500px]">
              <ProjectAttachmentSliderView />
            </div>
          </OneThirdColumn>
          <TwoThirdsColumn>
            <div className="flex items-center pt-6">
              <div className="mr-auto">
                <Link
                  className="no-underline hover:no-underline"
                  onClick={() => navigate(AppRoutes.Projects)}
                >
                  <div className="flex h-12 flex-row items-center">
                    <ArrowLeftIcon className="h-5 w-5 text-[#2286e0]" />
                    <span className="text-md px-1 pt-1 text-[#2286e0] !no-underline">
                      {t("Back")}
                    </span>
                  </div>
                </Link>
              </div>
              <ProjectActionBar isEditing={false} />
            </div>
            <ReadOnlyHeaders
              components={components}
              permissions={permissions}
              formValues={selectedProject?.formValues}
              className="max-w-[600px]"
            />
          </TwoThirdsColumn>
        </GridWrapper>
      </div>
      <div className="block lg:hidden">
        <GridWrapper>
          <FullColumn>
            <ProjectActionBar isEditing={false} />
            <div className="grid grid-cols-1 overflow-clip">
              <div className="max-w-[350px]">
                <ProjectAttachmentSliderView />
              </div>
              <div className="items-center gap-2 pb-4 pt-9">
                <Link
                  className="no-underline hover:no-underline"
                  onClick={() => navigate(AppRoutes.Projects)}
                >
                  <div className="flex flex-row items-center">
                    <ArrowLeftIcon className="h-5 w-5 text-[#2286e0]" />
                    <span className="text-md px-1 pt-1 text-[#2286e0] !no-underline">
                      {t("Back")}
                    </span>
                  </div>
                </Link>
              </div>
              <ReadOnlyHeaders
                components={components}
                permissions={permissions}
                formValues={selectedProject?.formValues}
              />
            </div>
          </FullColumn>
        </GridWrapper>
      </div>
    </>
  );
};
