import DayJs from "dayjs";
import advancedFormatter from "dayjs/plugin/advancedFormat";
import { cloneDeep } from "lodash";
import relativeTime from "dayjs/plugin/relativeTime";

const dateStringFormat = "DD.MM.YYYY";

export const formatDate = (
  date: string,
  timestamp = false,
  timeFormat = "HH:mm",
) => {
  const formattedDate = DayJs(date).format(dateStringFormat);
  if (timestamp) {
    const formattedTimestamp = DayJs(date).format(timeFormat);
    return `${formattedDate} ${formattedTimestamp}`;
  }
  return formattedDate;
};

export const getRelativeTime = (date: string) => {
  DayJs.extend(relativeTime);
  const inputDate = DayJs(date);
  const dateIsBeforeCurrentWeek = DayJs().isBefore(inputDate, "week");
  if (dateIsBeforeCurrentWeek) {
    return inputDate.format(dateStringFormat);
  }
  return inputDate.fromNow();
};

export const resolveDate = (date = "") => {
  return Number.isNaN(Date.parse(date)) ? undefined : new Date(date);
};

export const formatQuarter = (date: string) => {
  DayJs.extend(advancedFormatter);
  return DayJs(date).format("[Q]Q/YYYY");
};

export const getQuarterDate = (date: string | Date) => {
  const quarter: Record<string, number> = {
    Q1: 2, // Feb
    Q2: 5, // May
    Q3: 8, // Aug
    Q4: 11, // Nov
  };
  const selectedQuarter = DayJs(date).format("[Q]Q");
  const year = DayJs(date).year();
  const quarterDate = DayJs()
    .set("day", 1)
    .set("month", quarter[selectedQuarter])
    .set("year", year);

  return quarterDate.toISOString();
};

export const formatYear = (date: string) => {
  DayJs.extend(advancedFormatter);
  return DayJs(date).format("YYYY");
};

export const formatMonth = (date: string, monthFormat = "MMM") => {
  DayJs.extend(advancedFormatter);
  return DayJs(date).format(monthFormat);
};

const sortDateAscending = (first: string, second: string) =>
  new Date(second).getTime() - new Date(first).getTime();

export const sortDates = (dates: string[]) => dates.sort(sortDateAscending);

export const sortByDates = (data: any[], dateKey: string) => {
  const clonedData = cloneDeep(data);
  return clonedData.sort((first, second) =>
    sortDateAscending(first[dateKey], second[dateKey]),
  );
};
